import React, { useState, useEffect } from 'react';
import { IScheduleMeeting } from 'types';
import MeetingCardComponent from 'components/PreJoinComponents/MeetingCardComponent/MeetingCardComponent';
import useGetSchdeulesMeetingData from 'hooks/useGetSchedulesMeetingsData/useGetSchedulesMeetingsData';
import BuilderGIFComponent from '../BuilderGIFComponent/BuilderGIFComponent';
import classes from './ScheduleMeetingsComponent.module.scss';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import moment from 'moment';
import useDelegateMeetingData from 'hooks/useDelegateMeetingsData/useDelegateMeetingsData';
require('moment/locale/en-gb');

export default function ScheduleMeetingsComponent() {
  const { isLoading, scheduledMeetings } = useGetSchdeulesMeetingData(30);
  const [radioValue, setRadioValue] = useState('today');
  const [filteredMeeting, setFilteredMeeting] = useState<IScheduleMeeting[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const { delegateMeetings } = useDelegateMeetingData();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    const today = moment().startOf('day');
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');
    const meeting = activeTab === 0 ? scheduledMeetings : delegateMeetings;

    switch (radioValue) {
      case 'today':
        const filteredToday = meeting.filter((item) => moment(item.start_date).isSame(today, 'day'));
        setFilteredMeeting(filteredToday);
        break;
      case 'week':
        const filteredThisWeek = meeting.filter((item) =>
          moment(item.start_date).isBetween(startOfWeek, endOfWeek, undefined, '[]')
        );
        setFilteredMeeting(filteredThisWeek);
        break;
      case 'month':
        const filteredThisMonth = meeting.filter((item) =>
          moment(item.start_date).isBetween(startOfMonth, endOfMonth, undefined, '[]')
        );
        setFilteredMeeting(filteredThisMonth);
        break;
    }
  }, [activeTab, delegateMeetings, radioValue, scheduledMeetings]);

  if (isLoading) {
    return <BuilderGIFComponent heigth={20} width={10} />;
  }

  return (
    <div className={`${classes.main}`}>
      <span className={classes.meetingHeading}>Meetings</span>
      <div className={classes.day}>
        <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
          <span
            onClick={() => setActiveTab(0)}
            className={`${classes.tabClass} ${activeTab === 0 && classes.activeTab}`}
            style={{ marginRight: '2em' }}
          >
            My Meetings
          </span>
          <span
            onClick={() => setActiveTab(1)}
            className={`${classes.tabClass} ${activeTab === 1 && classes.activeTab}`}
          >
            Delegated Meetings
          </span>
        </div>
        <div>
          <RadioGroup className={classes.radioGrp} value={radioValue} onChange={handleChange}>
            <FormControlLabel value="today" control={<Radio />} label="Today" />
            <FormControlLabel value="week" control={<Radio />} label="This Week" />
            <FormControlLabel value="month" control={<Radio />} label="This Month" />
          </RadioGroup>
        </div>
      </div>
      {filteredMeeting.length > 0 ? (
        <div className={classes.meetingContainer}>
          <div className={classes.cardContainer}>
            <div className={classes.cardMain}>
              {filteredMeeting.map((meeting: IScheduleMeeting, index: number) => {
                const uniqueKey = `${meeting.id}_${index}`;
                return <MeetingCardComponent key={uniqueKey} meetingDetails={meeting} activeTab={activeTab} />;
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.noMeetings}>No upcoming meetings</div>
      )}
    </div>
  );
}
