import React, { useEffect, useState } from 'react';
import classes from './MobileUpcomingMeetingsComponent.module.scss';
import MeetingFilterIcon from 'icons/MeetingFilterIcon';
import { HomeRoute, UpcomingMeetingRoute } from 'utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileMeetingCard from 'MobileComponents/MobileMeetingCard/MobileMeetingCard';
import useGetSchdeulesMeetingData from 'hooks/useGetSchedulesMeetingsData/useGetSchedulesMeetingsData';
import BuilderGIFComponent from 'components/PreJoinComponents/BuilderGIFComponent/BuilderGIFComponent';
import { Menu, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import moment from 'moment';
import { IScheduleMeeting } from 'types';
import LeftArrow from 'icons/LeftArrow';
import useDelegateMeetingData from 'hooks/useDelegateMeetingsData/useDelegateMeetingsData';
require('moment/locale/en-gb');

interface IMobileUpcomingMeetingsComponentProps {
  isHomeScreen?: boolean;
}

function MobileUpcomingMeetingsComponent({ isHomeScreen }: IMobileUpcomingMeetingsComponentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, scheduledMeetings } = useGetSchdeulesMeetingData(30);
  const { delegateMeetings } = useDelegateMeetingData();
  const [filteredMeeting, setFilteredMeeting] = useState<IScheduleMeeting[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [radioValue, setRadioValue] = useState(location.state?.radioValue ?? 'today');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const today = moment().startOf('day');
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    const meeting = activeTab === 0 ? scheduledMeetings : delegateMeetings;
    switch (radioValue) {
      case 'today':
        const filteredToday = meeting.filter((item) => moment(item.start_date).isSame(today, 'day'));
        setFilteredMeeting(filteredToday);
        break;
      case 'week':
        const filteredThisWeek = meeting.filter((item) =>
          moment(item.start_date).isBetween(startOfWeek, endOfWeek, undefined, '[]')
        );
        setFilteredMeeting(filteredThisWeek);
        break;
      case 'month':
        const filteredThisMonth = meeting.filter((item) =>
          moment(item.start_date).isBetween(startOfMonth, endOfMonth, undefined, '[]')
        );
        setFilteredMeeting(filteredThisMonth);
        break;
    }
  }, [radioValue, scheduledMeetings, activeTab, delegateMeetings]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const onViewClick = () => {
    if (isHomeScreen) {
      navigate(UpcomingMeetingRoute, { state: { radioValue } });
    } else {
      navigate(HomeRoute, { state: { radioValue } });
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{ className: classes.menuList }}
      >
        <RadioGroup className={classes.radioGrp} value={radioValue} onChange={handleChange}>
          <FormControlLabel
            value="today"
            control={
              <Radio className={`${classes.radioColor} ${radioValue === 'today' && classes.radioActiveColor}`} />
            }
            label="Today"
          />
          <FormControlLabel
            value="week"
            control={<Radio className={`${classes.radioColor} ${radioValue === 'week' && classes.radioActiveColor}`} />}
            label="This Week"
          />
          <FormControlLabel
            value="month"
            control={
              <Radio className={`${classes.radioColor} ${radioValue === 'month' && classes.radioActiveColor}`} />
            }
            label="This Month"
          />
        </RadioGroup>
      </Menu>

      <div className={classes.main} style={{ height: isHomeScreen ? '100%' : '84%' }}>
        <div className={classes.header}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              onClick={() => navigate(HomeRoute)}
              style={{ display: isHomeScreen ? 'none' : 'flex', marginLeft: '-0.5em' }}
            >
              <LeftArrow />
            </span>
            <span style={{ display: 'flex', alignItems: 'center', marginLeft: isHomeScreen ? '0em' : '0.5em' }}>
              Upcoming Meetings
            </span>
          </div>
          <span className={classes.filterLabel}>
            <span
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(event.currentTarget);
              }}
              style={{ display: 'flex', marginRight: '0.5em' }}
            >
              <MeetingFilterIcon />
            </span>
            <span onClick={onViewClick} className={classes.viewLessLabel}>
              {isHomeScreen ? 'View All' : 'View Less '}
            </span>
          </span>
        </div>

        {!isHomeScreen && (
          <div className={classes.day}>
            <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
              <span
                onClick={() => setActiveTab(0)}
                className={`${classes.tabClass} ${activeTab === 0 && classes.activeTab}`}
                style={{ marginRight: '2em' }}
              >
                My Meetings
              </span>
              <span
                onClick={() => setActiveTab(1)}
                className={`${classes.tabClass} ${activeTab === 1 && classes.activeTab}`}
              >
                Delegated Meetings
              </span>
            </div>
          </div>
        )}

        {isLoading ? (
          <BuilderGIFComponent heigth={isHomeScreen ? 30 : 20} width={20} />
        ) : (
          <div className={classes.meetingCardContainer}>
            {filteredMeeting.length === 0 ? (
              <div className={classes.noMeeting}>No upcoming meetings</div>
            ) : (
              <>
                {filteredMeeting.map((meeting, index) => {
                  return (
                    <MobileMeetingCard key={meeting.id} index={index} meetingDetails={meeting} activeTab={activeTab} />
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default MobileUpcomingMeetingsComponent;
