import React, { useEffect } from 'react';
import HomeScreenTopBar from 'components/HomeScreenTopBar/HomeScreenTopBar';
import SideBar from 'components/SideBar/SideBar';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';
import usePreferredBrowserWarning from 'hooks/usePreferredBrowserWarning/usePreferredBrowserWarning';
import CustomAlertWrapper from 'shared/CustomAlert/CustomAlertWrapper';
import CustomDialogtWrapper from 'shared/CustomDialog/CustomDialogWrapper';
import useDarkMode from 'hooks/useDarkMode/useDarkMode';
import BuilderGIFComponent from '../BuilderGIFComponent/BuilderGIFComponent';
import classes from './HomePage.module.scss';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import MobileTopBar from 'MobileComponents/MobileTopBar/MobileTopBar';
import MobileBotomBar from 'MobileComponents/MobileBotomBar/MobileBotomBar';
import { useAuth } from 'react-oidc-context';

interface CommonUtilityProps {
  children: React.ReactNode;
  activeTab: activeElement;
  Icon: JSX.Element;
  title: string;
}

export default function HomePage({ children, activeTab, Icon, title }: CommonUtilityProps) {
  const { setActiveTab, isFetchingUser, user } = useAppState();
  const { isMobile } = useDeviceType();
  const auth = useAuth();

  useDarkMode(); // To Set Light/Dark Mode
  usePreferredBrowserWarning();

  useEffect(() => {
    setActiveTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <CustomAlertWrapper />
      <CustomDialogtWrapper />

      {isMobile ? (
        <div data-testid="contents" className={classes.container}>
          <MobileTopBar />
          {isFetchingUser || auth.isLoading ? <BuilderGIFComponent heigth={20} width={30} /> : children}
          {user && <MobileBotomBar />}
        </div>
      ) : (
        <div data-testid="contents" className={classes.container}>
          <SideBar roomState={'disconnected'} />
          <div className={classes.innerContainer}>
            <div data-testid="icons" className={classes.main}>
              <HomeScreenTopBar Icon={Icon} title={title} />
              <div className={classes.content}>
                {isFetchingUser || auth.isLoading ? <BuilderGIFComponent heigth={20} width={10} /> : children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
