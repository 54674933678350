import React, { useEffect, useRef, useState } from 'react';
import classes from './SubmitFeedbackComponent.module.scss';
import { InputLabel, TextField } from '@mui/material';
import CustomButton from 'shared/CustomButton/CustomButton';
import { useAppState } from 'state';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeRoute } from 'utils/constants';
import FeedbackRating from './FeedbackRating';
import ExperianceChips from './ExperianceChips';
import { submitFeedback } from 'service/MeetAPIServiceV1';

export enum activeFieldEnum {
  Name = 'Name',
  Rating = 'Rating',
  Comments = 'Comments',
  MeetingLink = 'MeetingLink',
  Options = 'Options',
}

const ratingArray = [
  {
    value: 0,
    label: 'unbearable',
  },
  {
    value: 25,
    label: 'mildly_annoying',
  },
  {
    value: 30, //For Mobile
    label: 'mildly_annoying',
  },
  {
    value: 50,
    label: 'neutral',
  },
  {
    value: 70, //For Mobile
    label: 'satisfactory',
  },
  {
    value: 75,
    label: 'satisfactory',
  },
  {
    value: 100,
    label: 'delightful',
  },
];

function SubmitFeedbackComponent() {
  const { user, setAlert } = useAppState();
  const [name, setName] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [comments, setComments] = useState('');
  const [isMeetingLinkDisabled, setMeetingLinkDisabled] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [rating, setRating] = useState(50);
  const [activeField, setActiveField] = useState<activeFieldEnum>(activeFieldEnum.Name);
  const [lastSelectedChip, setLastSelectedChip] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const nameRef = useRef<null | HTMLInputElement>(null);
  const meetingRef = useRef<null | HTMLInputElement>(null);
  const commentRef = useRef<null | HTMLInputElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      setMeetingLinkDisabled(true);
      setMeetingLink(location.state.link);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      setActiveField(activeFieldEnum.Rating);
      setName(user.name);
    } else {
      setActiveField(activeFieldEnum.Name);
      setName('');
    }
  }, [user]);

  useEffect(() => {
    if (selectedIssues.includes('Others') && lastSelectedChip === 'Others') {
      setActiveField(activeFieldEnum.Comments);
    }
  }, [lastSelectedChip, selectedIssues]);

  useEffect(() => {
    if (activeField === activeFieldEnum.Comments) {
      commentRef.current?.focus();
    } else if (activeField === activeFieldEnum.MeetingLink) {
      meetingRef.current?.focus();
    } else if (activeField === activeFieldEnum.Name) {
      nameRef.current?.focus();
    }
  }, [activeField]);

  const postFeedback = () => {
    setSubmitButtonDisabled(true);

    const data = {
      name: name,
      meeting_link: meetingLink,
      rating: ratingArray.find((r) => r.value === rating)?.label,
      comments: comments,
      meeting_issues: {
        could_not_join_meeting: selectedIssues.includes('JoinMeeting'),
        could_not_enable_mic: selectedIssues.includes('MicIssue'),
        could_not_enable_camera: selectedIssues.includes('CameraIssue'),
        difficult_ui: selectedIssues.includes('DifficultUI'),
        internet_issue: selectedIssues.includes('InternetIssue'),
        screen_sharing_issues: selectedIssues.includes('ScreenSharingIssue'),
        missing_recordings: selectedIssues.includes('MissingRecordings'),
        could_not_hear_participants: selectedIssues.includes('CouldNotHearParticipants'),
        participants_could_not_hear_me: selectedIssues.includes('ParticipantsCouldNotHearMe'),
        speaker_issues: selectedIssues.includes('SpeakerIssues'),
        no_issues: selectedIssues.includes('NoIssues'),
        other: selectedIssues.includes('Others'),
      },
    };

    submitFeedback(data)
      .then((res) => {
        setAlert({
          message: 'Feedback submitted succesfully',
          variant: 'success',
        });
        navigate(HomeRoute);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitButtonDisabled(false);
      });
  };

  return (
    <div className={classes.main}>
      <span className={classes.heading}>Please share your feedback!</span>
      <div className={classes.form}>
        <div
          onClick={() => {
            !user && setActiveField(activeFieldEnum.Name);
          }}
          className={`${classes.nameField} ${activeField === activeFieldEnum.Name && classes.activeBorder}`}
        >
          <InputLabel className={classes.label}>Name *</InputLabel>
          <TextField
            inputRef={nameRef}
            InputProps={{ className: classes.inputField }}
            disabled={Boolean(user)}
            placeholder="Your Name"
            variant="outlined"
            fullWidth
            size="small"
            value={name}
            inputProps={{ maxLength: 255, autoComplete: 'off' }}
            onChange={(event) => {
              setName(event.target.value);
            }}
            onFocus={() => {
              setActiveField(activeFieldEnum.Name);
            }}
            className={classes.textField}
          />
        </div>

        <div
          onClick={() => setActiveField(activeFieldEnum.Rating)}
          className={`${classes.fieldMargin} ${activeField === activeFieldEnum.Rating && classes.activeBorder}`}
        >
          <InputLabel className={classes.label}>Please rate your meeting experience with Builder Meet *</InputLabel>
          <FeedbackRating setRating={setRating} />
        </div>

        <div
          onClick={() => setActiveField(activeFieldEnum.Options)}
          className={`${classes.fieldMargin} ${activeField === activeFieldEnum.Options && classes.activeBorder}`}
        >
          <InputLabel className={classes.label}>
            Did anything cause a hiccup during the meeting? Choose all that apply *
          </InputLabel>
          <div style={{ marginTop: '0.5em' }}>
            <ExperianceChips
              selectedIssues={selectedIssues}
              setSelectedIssues={setSelectedIssues}
              setActiveField={setActiveField}
              setLastSelectedChip={setLastSelectedChip}
            />
          </div>
        </div>

        <div
          onClick={() => setActiveField(activeFieldEnum.Comments)}
          className={`${classes.fieldMargin} ${activeField === activeFieldEnum.Comments && classes.activeBorder}`}
        >
          <InputLabel className={classes.label}>Additional Comments</InputLabel>
          <TextField
            inputRef={commentRef}
            multiline
            maxRows={2}
            minRows={2}
            InputProps={{ className: classes.inputField }}
            disabled={false}
            placeholder="Write your comments here"
            variant="outlined"
            fullWidth
            size="small"
            value={comments}
            inputProps={{ maxLength: 500, autoComplete: 'off' }}
            onChange={(event) => {
              setComments(event.target.value);
            }}
            onFocus={() => {
              setActiveField(activeFieldEnum.Comments);
            }}
            className={classes.textField}
          />
        </div>

        <div
          onClick={() => {
            !location.state?.link && setActiveField(activeFieldEnum.MeetingLink);
          }}
          className={`${classes.fieldMargin} ${activeField === activeFieldEnum.MeetingLink && classes.activeBorder}`}
        >
          <InputLabel className={classes.label}>{`Meeting Link ${location.state?.link ? '*' : ''}`}</InputLabel>
          <TextField
            inputRef={meetingRef}
            InputProps={{ className: classes.inputField }}
            disabled={isMeetingLinkDisabled}
            placeholder="Enter Meeting Link"
            variant="outlined"
            fullWidth
            size="small"
            value={meetingLink}
            inputProps={{ autoComplete: 'off' }}
            onChange={(event) => {
              setMeetingLink(event.target.value);
            }}
            onFocus={() => {
              setActiveField(activeFieldEnum.MeetingLink);
            }}
            className={classes.textField}
          />
        </div>

        <div className={classes.buttonContainer}>
          <CustomButton btnClass={classes.cancelButton} buttonName="Cancel" onClick={() => navigate(HomeRoute)} />
          <CustomButton
            isDisabled={!name || selectedIssues.length === 0 || submitButtonDisabled}
            btnClass={classes.submitButtton}
            buttonName="Submit Feedback"
            onClick={postFeedback}
          />
        </div>
      </div>
    </div>
  );
}

export default SubmitFeedbackComponent;
