import React, { useMemo, useCallback, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import CustomButton from 'shared/CustomButton/CustomButton';
import { IScheduleMeeting } from 'types';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';
import useCopyLink from 'hooks/useCopyLink/useCopyLink';
import { DIALOG_BOXES } from 'shared/CustomDialog/customDialogConstants';
import useNavigateToMeeting from 'hooks/useNavigateToMeeting/useNavigateToMeeting';
import classes from './MeetingButtonsContainer.module.scss';
import CopyLinkIcon from 'icons/CopyLinkIcon';
import { updateScheduleMeeting } from 'service/MeetAPIServiceV1';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';


interface MeetingButtonsContainerProps {
  meetingDetails: IScheduleMeeting;
  activeTab: number;
}

function MeetingButtonsContainer({ meetingDetails, activeTab }: MeetingButtonsContainerProps) {
  const navigate = useNavigate();
  const { setDialog, setAlert, user } = useAppState();

  const meetingLink = useMemo(() => meetingDetails.meeting_link, [meetingDetails]);
  const copyLink = useCopyLink({ meetingLink });
  const [cancelAnchorEl, setCancelAnchorEl] = useState<null | HTMLElement>(null);
  const [editAnchorEl, setEditAnchorEl] = useState<null | HTMLElement>(null);
  const navigateToMeeting = useNavigateToMeeting();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const handleClose = useCallback(() => {
    setCancelAnchorEl(null);
    setEditAnchorEl(null);
  }, []);

  const onStartClick = useCallback(
    (meetingDetails: IScheduleMeeting) => {
      setDialog(null);
      navigateToMeeting(meetingDetails.meeting_link);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const onEditClick = useCallback(
    (isSeries?: boolean) => {
      setDialog(null);
      const isRecurringMeeting = meetingDetails.frequency === 'recurring';
      let path;
      if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
        path = isRecurringMeeting
          ? `/recurring-meetings/${meetingDetails.meeting_uuid}/edit?occurrence_id=${meetingDetails.occurrence_id}`
          : `/schedule-meetings/${meetingDetails.meeting_uuid}/edit`;
      } else {
        path = isRecurringMeeting
          ? `/recurring-meetings/${meetingDetails.id}/edit`
          : `/schedule-meetings/${meetingDetails.meeting_uuid}/edit`;
      }
      navigate(path, { state: { isEditSeriesClick: isSeries } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const onCancel = useCallback(
    (isEntireSeries: boolean) => {
      const isRecurringMeeting = meetingDetails?.frequency === 'recurring';
      let data;

      if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
        if (!isRecurringMeeting || isEntireSeries) {
          data = {
            meeting_uuid: meetingDetails?.meeting_uuid,
            status: 'cancelled',
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            })
          };
        } else if (meetingDetails?.klass === "EventOccurrence") {
          // New style meetings have this ID, old ones won't
          data = {
            meeting_uuid: meetingDetails?.meeting_uuid,
            occurrence_id: meetingDetails?.occurrence_id,
            status: 'cancelled',
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            })
          };
        } else {
          // Old style recurring meeting
          data = {
            meeting_uuid: meetingDetails?.meeting_uuid,
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            }),
            recurring_meetings_attributes: [
              {
                status: 'cancelled',
                id: meetingDetails?.id,
                ...(meetingDetails?.managed_by !== null && {
                  managed_by: meetingDetails?.managed_by,
                }),
              },
            ],
          }
        }
      } else {
        data =
          !isRecurringMeeting || isEntireSeries
            ? {
                meeting_uuid: meetingDetails?.meeting_uuid,
                status: 'cancelled',
                ...(meetingDetails?.managed_by !== null && {
                  managed_by: meetingDetails?.managed_by,
                }),
              }
            : {
                meeting_uuid: meetingDetails?.meeting_uuid,
                ...(meetingDetails?.managed_by !== null && {
                  managed_by: meetingDetails?.managed_by,
                }),
                recurring_meetings_attributes: [
                  {
                    status: 'cancelled',
                    id: meetingDetails?.id,
                    ...(meetingDetails?.managed_by !== null && {
                      managed_by: meetingDetails?.managed_by,
                    }),
                  },
                ],
              };
      }

      updateScheduleMeeting(data)
        .then((res) => {
          setAlert({
            variant: 'success',
            message: 'Meeting has been cancelled',
          });
          //Refresh Page After Cancellation
          navigate(0);
        })
        .catch((err) => {
          setAlert({
            variant: 'error',
            message: 'Error while cancelling meeting',
          });
          console.log('meeting cancellation error', err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, setAlert]
  );

  const onCancelClick = useCallback(
    (isEntireSeries: boolean) => {
      setDialog({
        dialogBoxName: DIALOG_BOXES.MEETING_CANCELLATION,
        title: 'Meeting Cancel',
        isCancelSeriesClick: isEntireSeries,
        onCancel: () => {
          onCancel(isEntireSeries);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDialog]
  );

  return (
    <>
      <Menu
        anchorEl={cancelAnchorEl}
        open={Boolean(cancelAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: '15px' } }}
      >
        <MenuItem onClick={() => onCancelClick(false)}>Cancel Meeting</MenuItem>
        <MenuItem onClick={() => onCancelClick(true)}>Cancel Series</MenuItem>
      </Menu>

      <Menu
        anchorEl={editAnchorEl}
        open={Boolean(editAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: '15px' } }}
      >
        <MenuItem onClick={() => onEditClick(false)}>Edit This Meeting</MenuItem>
        <MenuItem onClick={() => onEditClick(true)}>Edit This and following Meetings</MenuItem>
      </Menu>

      <div className={classes.buttonContainer}>
        <div onClick={copyLink} className={classes.copyLinkButton}>
          <CopyLinkIcon />
        </div>
        {(meetingDetails.host_name === 'You' || activeTab === 1) && (
          <CustomButton
            buttonName="Edit"
            btnClass={`${classes.btnClass} ${classes.editButton}`}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (meetingDetails.frequency === 'recurring') {
                setEditAnchorEl(event.currentTarget);
              } else {
                onEditClick();
              }
            }}
          />
        )}
        {(activeTab === 0 || (user && meetingDetails.participants_list.includes(user.email))) && (
          <CustomButton
            buttonName={meetingDetails.joinButtonName}
            onClick={() => onStartClick(meetingDetails)}
            btnClass={`${classes.btnClass} ${
              meetingDetails.host_name !== 'You' ? classes.joinButton : classes.startButton
            } ${activeTab === 1 && classes.joinButtonMargin}`}
          />
        )}
        {(meetingDetails.host_name === 'You' || activeTab === 1) && (
          <CustomButton
            buttonName="Cancel"
            btnClass={`${classes.btnClass} ${classes.cancelButton}`}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              if (meetingDetails.frequency === 'recurring') {
                setCancelAnchorEl(event.currentTarget);
              } else {
                onCancelClick(false);
              }
            }}
          />
        )}
      </div>
    </>
  );
}

export default MeetingButtonsContainer;
