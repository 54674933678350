import React, { useState } from 'react';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { Menu, Tooltip, MenuItem } from '@mui/material';
import UserIcon from 'icons/UserIcon';
import { useAppState } from 'state';
import classes from './UserInformationDialog.module.scss';
import DelegateListIcon from 'icons/DelegateListIcon';
import DelegateIcon from 'icons/DelegateIcon';
import RightArrowIcon from 'icons/RightArrowIcon';
import DelegationDialog from 'components/DelegationDialog/DelegationDialog';
import DelegationListDialog from 'components/DelegationListDialog/DelegationListDialog';
import Oidc from 'components/Oidc/OidcComponent';

export default function UserInformationDialog() {
  const { room } = useVideoContext();
  const { user, isDisconnected } = useAppState();

  const [delegateDialogOpen, setDelegateDialogOpen] = useState(false);
  const [delegateListDialogOpen, setDelegateListDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  //Only Need To Open This Dialog When We're In the Join Screen
  //Or If there's a Room Connected Then User should be present
  let open = false;
  if ((!room.sid && !isDisconnected) || user) {
    open = Boolean(anchorEl);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let tooltip = '';
  if (user) {
    tooltip = user.name;
  } else if (!room.sid) {
    tooltip = 'Guest';
  }

  return (
    <>
      <DelegationDialog
        open={delegateDialogOpen}
        onClose={() => {
          setDelegateDialogOpen(false);
        }}
      />
      <DelegationListDialog
        open={delegateListDialogOpen}
        onClose={() => {
          setDelegateListDialogOpen(false);
        }}
      />
      <Tooltip arrow title={tooltip}>
        <span>
          {user?.profile_picture ? (
            <img
              onClick={handleClick}
              className={classes.userIcon}
              height="34px"
              width="34px"
              style={{ borderRadius: '25px' }}
              src={user.profile_picture ?? ''}
              alt="img"
            />
          ) : (
            <span onClick={handleClick} className={classes.userIcon}>
              <UserIcon />
            </span>
          )}
        </span>
      </Tooltip>

      <Menu
        className={!open ? classes.display : ''}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: '15px' } }}
      >
        {user && (
          <div className={classes.main}>
            <div className={classes.menuItem}>
              <div className={classes.initial}>
                {user?.profile_picture ? (
                  <img className={classes.profile_picture} src={user.profile_picture ?? ''} alt="img"></img>
                ) : (
                  <span className={classes.profile_picture}>
                    <UserIcon />
                  </span>
                )}
              </div>
              <div className={classes.participantDetail}>
                <div className={classes.name}>{user.name}</div>
                <div>
                  <span className={classes.email}>{user.email}</span>
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                setDelegateDialogOpen(true);
              }}
              className={classes.delegateMain}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginTop: '0.25em' }}>
                  <DelegateIcon />
                </span>
                <span>Delegate</span>
              </div>
              <div>
                <RightArrowIcon />
              </div>
            </div>

            <div
              onClick={() => {
                setDelegateListDialogOpen(true);
              }}
              className={classes.delegateListMain}
            >
              <span style={{ marginTop: '0.25em' }}>
                {' '}
                <DelegateListIcon />
              </span>
              <span>Delegation List</span>
            </div>
          </div>
        )}
        {!room.sid && (
          <MenuItem className={classes.menuItems}>
            <Oidc />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
