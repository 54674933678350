import { useAppState } from 'state';
import { activeElement, meetingSource } from 'utils/enums';

export default function useResetStates() {
  const {
    setIsHost,
    setHostName,
    setBuilderExpertScreen,
    setIsFetchingConversation,
    setActiveTab,
    setMeetingDetails,
    setIsFetchingToken,
    setPermissionStatus,
    setPreflightReport,
    setPreflightReportError,
    setIsConsentGiven,
    setWaitForHost
  } = useAppState();

  const resetStates = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    setIsFetchingToken(false);
    setIsHost(false);
    setHostName('');
    setBuilderExpertScreen(false);
    setIsFetchingConversation(false);
    setActiveTab(activeElement.Home);
    setPermissionStatus(null);
    setPreflightReportError(null);
    setPreflightReport(null);
    setIsConsentGiven(false);
    setWaitForHost(false);
    setMeetingDetails({
      bMeet_id: null,
      isFeatureFlagEnabled: false,
      isScheduledMeetingLink: false,
      meetingLink: '',
      pstnMeetingCode: null,
      pstnPassCode: null,
      source: meetingSource.Standalone,
      isNatashaPresent: false,
      meetingName: '',
      token: null,
      hostEmail: null,
      meetingTime: new Date(),
      bot_name: '',
      call_type: null,
      isMeetingStarted: false
    });
  };

  return resetStates;
}
