import React from 'react';
import classes from './MobileBottomBar.module.scss';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';
import MobileHomeIcon from 'icons/MobileHomeIcon';
import { useNavigate } from 'react-router-dom';
import { HomeRoute, MobileUserProfile, RecordingRoute } from 'utils/constants';
import MobileRecordingIcon from 'icons/MobileRecordingIcon';
import UserIcon from 'icons/UserIcon';

function MobileBotomBar() {
  const { activeTab, user } = useAppState();
  const navigate = useNavigate();

  return (
    <div className={classes.main}>
      <span
        onClick={() => navigate(HomeRoute)}
        className={`${classes.icon} ${activeTab === activeElement.Home && classes.activeColor}`}
      >
        <MobileHomeIcon />
      </span>
      <span
        onClick={() => navigate(RecordingRoute)}
        className={`${classes.icon} ${activeTab === activeElement.Recording && classes.activeColor}`}
      >
        {' '}
        <MobileRecordingIcon />
      </span>
      <span onClick={() => navigate(MobileUserProfile)} className={classes.icon}>
        {user?.profile_picture ? (
          <img
            className={`${classes.profile_picture} ${
              activeTab === activeElement.MobileProfile && classes.activeBorderImage
            }`}
            src={user?.profile_picture ?? ''}
            alt="img"
          />
        ) : (
          <span
            className={`${classes.profile_picture} ${
              activeTab === activeElement.MobileProfile && classes.activeBorderImage
            }`}
          >
            <UserIcon />
          </span>
        )}
      </span>
    </div>
  );
}

export default MobileBotomBar;
