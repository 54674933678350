import React from 'react';
import { useAppState } from 'state';
import CustomDialog from './CustomDialog';

export default function CustomDialogtWrapper() {
  const { dialog, setDialog } = useAppState();

  return (
    dialog && (
      <CustomDialog
        open={true}
        title={dialog.title}
        onLeaveMeeting={dialog.onLeaveMeeting}
        onCancel={dialog.onCancel}
        onCloseMeeting={dialog.onCloseMeeting}
        onContinueMeeting={dialog.onContinueMeeting}
        onLeave={dialog.onLeave}
        onUnmute={dialog.onUnmute}
        isCancelSeriesClick={dialog.isCancelSeriesClick}
        meetingDetails={dialog.meetingDetails}
        meetingTime={dialog.meetingTime}
        meetingSeriesEnd={dialog.meetingSeriesEnd}
        dialogBoxName={dialog.dialogBoxName}
        onClose={() => {
          dialog.onClose && dialog.onClose();
          setDialog(null);
        }}
        onSubmitFeedback={() => {
          dialog.onSubmitFeedback && dialog.onSubmitFeedback();
          setDialog(null);
        }}
      />
    )
  );
}
