import React, { PropsWithChildren } from 'react';
import { DIALOG_BOXES, isNeedToAddClass, messageDialog } from './customDialogConstants';
import MeetingCardComponent from 'components/PreJoinComponents/MeetingCardComponent/MeetingCardComponent';
import DialogContentText from '@mui/material/DialogContentText';
import { IScheduleMeeting } from 'types';
import classes from './DialogContentComponent.module.scss';

const moment = require('moment');

interface DialogContentComponentProps {
  dialogBoxName: string;
  meetingDetails?: IScheduleMeeting;
  isCancelSeriesClick?: boolean;
  meetingTime?: Date;
  meetingSeriesEnd?: string;
}

export default function DialogContentComponent({
  dialogBoxName,
  meetingDetails,
  isCancelSeriesClick,
  meetingTime,
  meetingSeriesEnd
}: PropsWithChildren<DialogContentComponentProps>) {
  let dateString = '';
  let seriesEndString = '';

  if (meetingTime) {
    const date = moment(meetingTime, 'ddd MMM DD YYYY HH:mm:ss ZZ');
    dateString = date.format('h:mm A [on] ddd D-MMM-YYYY');
  }

  if (meetingSeriesEnd) {
    const date = moment(meetingSeriesEnd, 'DD/MM/YYYY HH:mm A');
    seriesEndString = date.format('MMMM Do');
  }

  return (
    <>
      {dialogBoxName === DIALOG_BOXES.MEETING_DETAILS && meetingDetails ? (
        <MeetingCardComponent activeTab={0} meetingDetails={meetingDetails} isDialog />
      ) : (
        <DialogContentText
          className={`${classes.dialogText} ${isNeedToAddClass(dialogBoxName) && classes.endMeetingText}`}
        >
          {messageDialog(dialogBoxName, dateString, isCancelSeriesClick, seriesEndString)}
          {dialogBoxName === DIALOG_BOXES.BROWSER_SUPPORT_ALERT && (
            <a href="https://www.twilio.com/docs/video/javascript#supported-browsers" target="_blank" rel="noreferrer">
              {' '}
              supported browsers{' '}
            </a>
          )}
        </DialogContentText>
      )}
    </>
  );
}
