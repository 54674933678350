import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          fontFamily: `'Rubik', sans-serif`,
          fontWeight: 'bold',
        },
        arrow: {
          color: 'white',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          color: 'white',
          border: '2px solid #676B7E',
          position: 'relative',
          background: '#2E2E2E',
          overflowY: 'auto',
          borderRadius: '4px',
          maxHeight: '300px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: `white !important`,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#83889E',
        },
        colorPrimary: {
          '&$checked': {
            color: '#7C4DFF',
          },
          '&$disabled': {
            color: 'gray',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#83889E',
          padding: '0px 6px 8px 0px',
          '&.Mui-checked': {
            color: '#7C4DFF !important',
          },
        },
        colorPrimary: {
          '&$checked': {
            color: '#7C4DFF !important',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          color: '#00da6f',
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: '#00da6f',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
          border: '1px solid #676B7E',
          background: `#2E2E2E !important`,
          fontSize: '14px',
          borderRadius: '4px',
          fontFamily: `'Rubik', sans-serif`,
        },
        input: {
          padding: '0.75em',
          '&.Mui-disabled': {
            color: 'gray',
            WebkitTextFillColor: 'gray',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '14px',
          fontFamily: `'Rubik', sans-serif`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: `#676B7E !important`,
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'gray !important',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: `'Rubik', sans-serif`,
          '&.Mui-selected': {
            backgroundColor: '#7c4dff !important',
          },
          '&:hover': {
            backgroundColor: '#7c4dff',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: `'Rubik', sans-serif`,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&$disabled': {
            color: 'white',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$hover': {
            backgroundColor: '#7C4DFF !important',
          },
          '&$selected': {
            backgroundColor: '#7C4DFF',
          },
          '&$selected:hover': {
            backgroundColor: '#7C4DFF',
          },
          '&$button:hover': {
            backgroundColor: '#7C4DFF',
          },
          '&$disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#4caf50 !important',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'gray',
          fontWeight: 'bold',
          fontFamily: `'Rubik', sans-serif`,
          '&.Mui-selected': {
            color: 'white ',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#7C4DFF',
        },
        rail: {
          color: 'white',
        },
        markLabel: {
          color: 'white',
          '&[data-index="0"]': {
            transform: 'translateX(0%)', // Apply custom style for data-index="0"
          },
          '&[data-index="4"]': {
            transform: 'translateX(-100%)', // Apply custom style for data-index="4"
          },
          '@media (max-width: 599px)': {
            fontSize: '8px !important', // Adjust the font size for smaller screens
          },
        },
        thumb: {
          boxShadow: 'none !important',
        },
        mark: {
          position: 'absolute',
          width: '2px',
          height: '20px',
          borderRadius: '1px',
          backgroundColor: '#fff',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(180deg)',
          opacity: '0.8',
        },
      },
    },
  },
});

export default darkTheme;
