import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import Chrome from 'assets/Images/Chrome.png';
import Firefox from 'assets/Images/Firefox.png';
import FirefoxScreenShare from 'assets/Images/Firefox_ScreenShare.png';
import CustomButton from 'shared/CustomButton/CustomButton';
import useDevices from 'hooks/useDevices/useDevices';
import { isFirefoxBrowser, isSafariBrowser } from 'utils/constants';
import useSnackbarContent from 'hooks/useSnackbarContent/useSnackbarContent';
import classes from './CustomErrorDialog.module.scss';

interface CustomErrorDialogProps {
  open: boolean;
  onClose(): void;
  title: string;
  error?: Error;
  isScreenSharingError?: boolean;
}

export default function CustomErrorDialog({
  open,
  onClose,
  title,
  error,
  isScreenSharingError,
}: PropsWithChildren<CustomErrorDialogProps>) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { message } = useSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

  //We only need to show image in case of Chrome Or Firefox browser
  //Also In Chrome there's no Screen-sharing Error
  let imgSrc = Chrome;
  if (isFirefoxBrowser) {
    if (isScreenSharingError) {
      imgSrc = FirefoxScreenShare;
    } else {
      imgSrc = Firefox;
    }
  }

  const safariContent = () => {
    return (
      <>
        <span>Please follow below steps to give required permission</span>
        <br />
        <br />
        <div className={classes.safariText}>
          <span>1. Click on Safari Menu</span>
          <span>2. Click on Settings For This Website</span>
          <span>{`${'3. Allow'} ${
            isScreenSharingError ? 'Screensharing' : 'Camera And Microphone'
          } ${'Permission'}`}</span>
        </div>
      </>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={(_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        reason !== 'backdropClick' && onClose();
      }}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>{title}</div>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <div className={`${classes.dialogText} ${classes.endMeetingText}`}>
          {isScreenSharingError ? 'Looks like ScreenSharing permission is denied' : message}
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            {isSafariBrowser ? (
              safariContent()
            ) : (
              <span>
                Click on the below icon in your browser's address bar
                <br /> to provide required permission
              </span>
            )}
          </>
        </div>
        {!isSafariBrowser && <img src={imgSrc} alt="Permission-AltImage" />}
      </DialogContent>

      <DialogActions className={classes.dialogAction}>
        <CustomButton btnClass={classes.btnClass} buttonName={isScreenSharingError ? 'Close' : 'Join Without Audio Or Video'} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
}
