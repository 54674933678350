import React from 'react';

function CopyLinkIcon(props: { isDesktopRecordingUI?: boolean }) {
  const { isDesktopRecordingUI } = props;
  const color = isDesktopRecordingUI ? 'black' : 'white';

  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12C0 6.34315 0 3.51472 1.75736 1.75736C3.51472 0 6.34315 0 12 0H24C29.6569 0 32.4853 0 34.2426 1.75736C36 3.51472 36 6.34315 36 12V24C36 29.6569 36 32.4853 34.2426 34.2426C32.4853 36 29.6569 36 24 36H12C6.34315 36 3.51472 36 1.75736 34.2426C0 32.4853 0 29.6569 0 24V12Z"
        fill={color}
        fillOpacity="0.07"
      />
      <path
        d="M16.2427 22.3335L15.6214 22.9549C14.4498 24.1264 12.5503 24.1264 11.3787 22.9549C10.2071 21.7833 10.2071 19.8838 11.3787 18.7122L14.5607 15.5295C15.6671 14.4206 17.4404 14.3496 18.632 15.3666C19.8235 16.3835 20.032 18.146 19.1107 19.4129"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8786 13.7114L20.3786 13.2114C21.5502 12.0399 23.4497 12.0399 24.6213 13.2114C25.7929 14.383 25.7929 16.2825 24.6213 17.4541L21.4393 20.6361C20.3327 21.7446 18.5596 21.8151 17.3684 20.7981C16.1771 19.7811 15.9688 18.0189 16.89 16.7521"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CopyLinkIcon;
