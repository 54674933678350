import React from 'react';
import classes from './MobileTopBar.module.scss';
import BuilderIcon from 'icons/BuilderIcon';
import { SignedInFAQ, GuestUserFAQ, FeedbackRoute } from 'utils/constants';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';

function MobileTopBar() {
  const { user } = useAppState();
  const navigate = useNavigate();

  return (
    <div className={classes.main}>
      <div>
        <BuilderIcon />
      </div>
      <div className={classes.text}>
        <a className={classes.text} target="_blank" href={user ? SignedInFAQ : GuestUserFAQ} rel="noreferrer">
          <div style={{ cursor: 'pointer', marginBottom: '-0.5em' }}>
            <span style={{ cursor: 'pointer', marginRight: '0.5em' }}>FAQ</span>
          </div>
        </a>
        <span style={{ marginRight: '0.5em', color: 'gray' }}>|</span>
        <span>
          <div className={`${classes.feedback}`} onClick={() => navigate(FeedbackRoute)}>
            <span>Submit Feedback</span>
          </div>
        </span>
      </div>
    </div>
  );
}

export default MobileTopBar;
