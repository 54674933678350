import React from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

export default function MobileRecordingIcon() {
  const { activeTab } = useAppState();
  const isActive = activeTab === activeElement.Recording;

  return isActive ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5615C11.6244 14.5615 14.5625 11.6234 14.5625 7.99902C14.5625 4.37465 11.6244 1.43652 8 1.43652C4.37563 1.43652 1.4375 4.37465 1.4375 7.99902C1.4375 11.6234 4.37563 14.5615 8 14.5615Z"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10.624C9.44975 10.624 10.625 9.44877 10.625 7.99902C10.625 6.54928 9.44975 5.37402 8 5.37402C6.55025 5.37402 5.375 6.54928 5.375 7.99902C5.375 9.44877 6.55025 10.624 8 10.624Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5615C11.6244 14.5615 14.5625 11.6234 14.5625 7.99902C14.5625 4.37465 11.6244 1.43652 8 1.43652C4.37563 1.43652 1.4375 4.37465 1.4375 7.99902C1.4375 11.6234 4.37563 14.5615 8 14.5615Z"
        stroke="#AFB2C0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10.624C9.44975 10.624 10.625 9.44877 10.625 7.99902C10.625 6.54928 9.44975 5.37402 8 5.37402C6.55025 5.37402 5.375 6.54928 5.375 7.99902C5.375 9.44877 6.55025 10.624 8 10.624Z"
        fill="#AFB2C0"
        stroke="#AFB2C0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
