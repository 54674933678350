import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Button } from '@mui/material';
import classes from './Oidc.module.scss';
import { useAuthActions } from 'hooks/useAuthActions/useAuthActions';

function Oidc() {
  const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated;
  const { authSignIn, authSignOut } = useAuthActions();
  const onBtnClick = () => {
    if (isAuthenticated) {
      authSignOut();
    } else {
      authSignIn();
    }
  };

  return (
    <div className="App">
      <Button variant="contained" onClick={onBtnClick} className={`${classes.button}`}>
        {isAuthenticated ? 'Log out' : 'Sign in'}
      </Button>
    </div>
  );
}

export default Oidc;
