import { useMediaQuery } from '@mui/material';

export default function useDeviceType() {
  const isMobile = useMediaQuery('(max-width: 599px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 959px)') && !isMobile;

  const isDesktop = !isMobile && !isTablet;

  return { isMobile, isTablet, isDesktop };
}
