import React, { PropsWithChildren, useMemo } from 'react';
import {Snackbar, Alert, AlertColor} from '@mui/material';
import { alertMessageAutoHideDuration, preferredBrowserWarningAutoHideDuration } from 'utils/constants';
import classes from './CustomAlert.module.scss';
interface CustomAlertProps {
  variant: AlertColor;
  message: string;
  open: boolean;
  onClose: () => void;
  isTwilioError?: boolean;
  invalidEmailError?: boolean;
  isNeedToDisplayLink?: boolean;
  link?: string;
  isPreferredBrowserWarning?: boolean;
}

export default function CustomAlert({
  open,
  onClose,
  variant,
  message,
  isTwilioError,
  invalidEmailError,
  isNeedToDisplayLink,
  link,
  isPreferredBrowserWarning,
}: PropsWithChildren<CustomAlertProps>) {
  const autoHideDuration = useMemo(() => {
    if (isPreferredBrowserWarning) {
      return preferredBrowserWarningAutoHideDuration;
    } else if (!isTwilioError) {
      return alertMessageAutoHideDuration;
    }
    return null;
  }, [isPreferredBrowserWarning, isTwilioError]);

  const handleAlertClose = () => {
    if (isPreferredBrowserWarning) {
      window.localStorage.setItem('isPreferredBrowserWarningClosed', JSON.stringify(true));
    }
    onClose();
  };

  const handleSnackbarClose = (reason: any) => {
    if (!(invalidEmailError && reason === 'clickaway')) {
      onClose();
    }
  };

  const renderLink = useMemo(() => {
    if (isNeedToDisplayLink) {
      return (
        <>
          <br />
          <span>{`Here's how you can enable it:`} </span>
          <a className={classes.linkColor} href={link} target="blank">
            Click Here
          </a>
        </>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToDisplayLink, link]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={(event: any, reason: any) => {
        handleSnackbarClose(reason);
      }}
    >
      <Alert className={classes.alert} elevation={6} variant="filled" severity={variant} onClose={handleAlertClose}>
        <span>{message}</span>
        {renderLink}
      </Alert>
    </Snackbar>
  );
}
