import React, { useState, useCallback } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { IScheduleMeeting } from 'types';
import classes from './MobileMeetingCard.module.scss';
import { getMeetingTime } from 'utils/constants';
import CustomButton from 'shared/CustomButton/CustomButton';
import { DIALOG_BOXES } from 'shared/CustomDialog/customDialogConstants';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';
import { updateScheduleMeeting } from 'service/MeetAPIServiceV1';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';

interface IThreeDotsMenuProps {
  meetingDetails: IScheduleMeeting;
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  activeTab: number;
}

function ThreeDotsMenu({ meetingDetails, anchorEl, setAnchorEl, activeTab }: IThreeDotsMenuProps) {
  const navigate = useNavigate();
  const { setDialog, setAlert } = useAppState();
  const [cancelAnchorEl, setCancelAnchorEl] = useState<null | HTMLElement>(null);
  const [editAnchorEl, setEditAnchorEl] = useState<null | HTMLElement>(null);
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const handleClose = () => {
    setAnchorEl(null);
    setCancelAnchorEl(null);
    setEditAnchorEl(null);
  };

  const onCancelClick = useCallback(
    (isEntireSeries: boolean) => {
      setDialog({
        dialogBoxName: DIALOG_BOXES.MEETING_CANCELLATION,
        title: 'Meeting Cancel',
        isCancelSeriesClick: isEntireSeries,
        onCancel: () => {
          onCancel(isEntireSeries);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDialog]
  );

  const onCancel = useCallback(
    (isEntireSeries: boolean) => {
      const isRecurringMeeting = meetingDetails?.frequency === 'recurring';
      let data;

      if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
        if (!isRecurringMeeting || isEntireSeries) {
          data = {
            meeting_uuid: meetingDetails?.meeting_uuid,
            series_id: meetingDetails?.series_id,
            status: 'cancelled',
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            })
          };
        } else if (meetingDetails?.klass === "EventOccurrence") {
          // New style meetings have this ID, old ones won't
          data = {
            meeting_uuid: meetingDetails?.meeting_uuid,
            occurrence_id: meetingDetails?.occurrence_id,
            series_id: meetingDetails?.series_id,
            status: 'cancelled',
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            })
          };
        } else {
          // Old style recurring meeting
          data = {
            meeting_uuid: meetingDetails?.meeting_uuid,
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            }),
            recurring_meetings_attributes: [
              {
                status: 'cancelled',
                id: meetingDetails?.id,
                ...(meetingDetails?.managed_by !== null && {
                  managed_by: meetingDetails?.managed_by,
                }),
              },
            ],
          }
        }
      } else {
        data =
          !isRecurringMeeting || isEntireSeries
            ? {
                meeting_uuid: meetingDetails?.meeting_uuid,
                status: 'cancelled',
                ...(meetingDetails?.managed_by !== null && {
                  managed_by: meetingDetails?.managed_by,
                }),
              }
            : {
                meeting_uuid: meetingDetails?.meeting_uuid,
                ...(meetingDetails?.managed_by !== null && {
                  managed_by: meetingDetails?.managed_by,
                }),
                recurring_meetings_attributes: [
                  {
                    status: 'cancelled',
                    id: meetingDetails?.id,
                    ...(meetingDetails?.managed_by !== null && {
                      managed_by: meetingDetails?.managed_by,
                    }),
                  },
                ],
              };
      }

      updateScheduleMeeting(data)
        .then((res) => {
          setAlert({
            variant: 'success',
            message: 'Meeting has been cancelled',
          });
          //Refresh Page After Cancellation
          navigate(0);
        })
        .catch((err) => {
          setAlert({
            variant: 'error',
            message: 'Error while cancelling meeting',
          });
          console.log('meeting cancellation error', err);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, setAlert]
  );

  const onEditClick = useCallback(
    (isSeries?: boolean) => {
      setDialog(null);
      const isRecurringMeeting = meetingDetails.frequency === 'recurring';
      let path;
      if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
        console.log(meetingDetails);
        path = isRecurringMeeting
          ? `/recurring-meetings/${meetingDetails.meeting_uuid}/edit?occurrence_id=${meetingDetails.occurrence_id}`
          : `/schedule-meetings/${meetingDetails.meeting_uuid}/edit`;
      } else {
        path = isRecurringMeeting
          ? `/recurring-meetings/${meetingDetails.id}/edit`
          : `/schedule-meetings/${meetingDetails.meeting_uuid}/edit`;
      }
      navigate(path, { state: { isEditSeriesClick: isSeries } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  return (
    <>
      <Menu
        anchorEl={cancelAnchorEl}
        open={Boolean(cancelAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginTop: '15px' } }}
      >
        <MenuItem onClick={() => onCancelClick(false)}>This Meeting</MenuItem>
        <MenuItem onClick={() => onCancelClick(true)}>This Series</MenuItem>
      </Menu>

      <Menu
        anchorEl={editAnchorEl}
        open={Boolean(editAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: '15px' } }}
      >
        <MenuItem onClick={() => onEditClick(false)}>Edit This Meeting</MenuItem>
        <MenuItem onClick={() => onEditClick(true)}>Edit This and following Meetings</MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{ className: classes.menuList }}
      >
        <div className={classes.menuItems}>
          <div className={classes.fields} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span className={classes.label}>Meeting ID: </span>
              <span className={classes.value}>{meetingDetails.pstn_meeting_code}</span>
            </div>
            <div onClick={handleClose} className={classes.closeIcon}>
              x
            </div>
          </div>

          <div className={classes.fields}>
            <span className={classes.label}>Type: </span>
            <span className={classes.value}>{meetingDetails.frequency}</span>
          </div>

          {/* <div className={classes.fields}>
            <span className={`${classes.label}`}>Date: </span>
            <span className={classes.value}>{getMeetingDate(meetingDetails.start_date)}</span>
          </div> */}

          <div className={classes.fields}>
            <span className={classes.label}>Time: </span>
            <span className={classes.value}>{getMeetingTime(meetingDetails.start_date, meetingDetails.duration)}</span>
          </div>

          {(meetingDetails.host_name === 'You' || activeTab === 1) && (
            <div className={classes.buttonContainer}>
              <CustomButton
                buttonName="Edit"
                btnClass={classes.editBtn}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (meetingDetails.frequency === 'recurring') {
                    setEditAnchorEl(event.currentTarget);
                  } else {
                    onEditClick();
                  }
                }}
              />
              <CustomButton
                buttonName="Cancel Meeting"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (meetingDetails.frequency === 'recurring') {
                    setCancelAnchorEl(event.currentTarget);
                  } else {
                    onCancelClick(false);
                  }
                }}
                btnClass={classes.cancelBtn}
              />
            </div>
          )}
        </div>
      </Menu>
    </>
  );
}

export default ThreeDotsMenu;
