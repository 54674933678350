import React, { useState } from 'react';
import classes from './MobileUserProfile.module.scss';
import { useAppState } from 'state';
import CustomButton from 'shared/CustomButton/CustomButton';
import RightArrowIcon from 'icons/RightArrowIcon';
import DelegateListIcon from 'icons/DelegateListIcon';
import DelegateIcon from 'icons/DelegateIcon';
import DelegationListDialog from 'components/DelegationListDialog/DelegationListDialog';
import DelegationDialog from 'components/DelegationDialog/DelegationDialog';
import UserIcon from 'icons/UserIcon';
import { useAuthActions } from 'hooks/useAuthActions/useAuthActions';

function MobileUserProfileComponent() {
  const { user } = useAppState();
  const { authSignOut } = useAuthActions();
  const [delegateDialogOpen, setDelegateDialogOpen] = useState(false);
  const [delegateListDialogOpen, setDelegateListDialogOpen] = useState(false);

  const handleLogout = () => {
    authSignOut();
  };

  return (
    <>
      <DelegationDialog
        open={delegateDialogOpen}
        onClose={() => {
          setDelegateDialogOpen(false);
        }}
      />
      <DelegationListDialog
        open={delegateListDialogOpen}
        onClose={() => {
          setDelegateListDialogOpen(false);
        }}
      />

      <div className={classes.main}>
        <div className={classes.header}>Profile</div>

        <div className={classes.menuItem}>
          <div className={classes.initial}>
            {user?.profile_picture ? (
              <img className={classes.profile_picture} src={user?.profile_picture ?? ''} alt="img"></img>
            ) : (
              <span className={classes.profile_picture}>
                <UserIcon />
              </span>
            )}
          </div>
          <div className={classes.participantDetail}>
            <div className={classes.name}>{user?.name}</div>
            <div>
              <span className={classes.email}>{user?.email}</span>
            </div>
          </div>
        </div>

        {user && (
          <div>
            <div
              onClick={() => {
                setDelegateDialogOpen(true);
              }}
              className={classes.delegateMain}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginTop: '0.25em' }}>
                  <DelegateIcon />
                </span>
                <span>Delegate</span>
              </div>
              <div>
                <RightArrowIcon />
              </div>
            </div>

            <div
              onClick={() => {
                setDelegateListDialogOpen(true);
              }}
              className={classes.delegateListMain}
            >
              <span style={{ marginTop: '0.25em' }}>
                {' '}
                <DelegateListIcon />
              </span>
              <span>Delegation List</span>
            </div>
          </div>
        )}

        <div className={classes.mainBtn}>
          <CustomButton buttonName="Logout" onClick={handleLogout} btnClass={classes.logOutBtn} />
        </div>
      </div>
    </>
  );
}

export default MobileUserProfileComponent;
