import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { durationArray } from 'utils/constants';

interface ScheduleMeetingDialogDurationComponentProps {
  meeting: any;
  setMeeting: any;
}

function ScheduleMeetingDialogDurationComponent({ meeting, setMeeting }: ScheduleMeetingDialogDurationComponentProps) {
  return (
    <Select
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      disableUnderline
      style={{marginTop: '0.5em', fontSize: '13px'}}
      value={durationArray.filter((x) => x.duration === meeting.duration)[0]?.time}
      onChange={(e) => {
        const selectedDuration = durationArray.filter((x) => x.time === e.target.value)[0];
        setMeeting((prevMeetingData: any) => ({
          ...prevMeetingData,
          duration: selectedDuration?.duration,
        }));
      }}
      fullWidth
      variant="filled"
    >
      {durationArray.map((value) => {
        return (
          <MenuItem key={value.duration} value={value.time}>
            {value.time}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default ScheduleMeetingDialogDurationComponent;
