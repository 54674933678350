import React, { createContext, useContext, useState } from 'react';
import {
  IAlert,
  ICustomDialog,
  IError,
  ILogs,
  IScheduleMeeting,
  User,
  IMeetingDetails,
  IPreflightError,
} from 'types';
import { MeetingPermissionStatus, activeElement, meetingSource } from 'utils/enums';
import useActiveSinkId from 'hooks/useActiveSinkId/useActiveSinkId';
import { PreflightTestReport } from 'twilio-video';
import { FeatureToggles } from 'hooks/useFeatureFlags/useFeatureFlags';
import { UserPreferences } from 'models/UserPreferences';

export interface StateContextType {
  error: IError | null;
  setError(error: IError | null): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  user: User | null;
  setUser: (user: User | null) => void;
  remoteMessage: any;
  setRemoteMessage: React.Dispatch<React.SetStateAction<undefined>>;
  featureFlags: FeatureToggles;
  setFeatureFlags: React.Dispatch<React.SetStateAction<{}>>;
  userPreferences: UserPreferences;
  setUserPreferences: React.Dispatch<React.SetStateAction<{}>>;
  isHost: boolean;
  setIsHost(isHost: boolean): void;
  hostName: string;
  setHostName(hostName: string): void;
  logs: ILogs;
  setLogs: React.Dispatch<React.SetStateAction<{}>>;
  builderExpertScreen: boolean;
  setBuilderExpertScreen: (builderExpertScreen: boolean) => void;
  isFetchingConversation: boolean;
  setIsFetchingConversation: (isFetchingConversation: boolean) => void;
  isDarkTheme: boolean;
  setIsDarkTheme: (isDarkTheme: boolean) => void;
  activeTab: activeElement;
  setActiveTab: (activeTab: activeElement) => void;
  scheduledMeetings: IScheduleMeeting[];
  setScheduledMeetings: (scheduledMeetings: IScheduleMeeting[]) => void;
  alert: IAlert | null;
  setAlert: (alert: IAlert | null) => void;
  isDisconnected: boolean;
  setIsDisconnected: (isDisconnect: boolean) => void;
  isRemovedByHost: boolean;
  setIsRemovedByHost: (isRemovedByHost: boolean) => void;
  isBuilderUser: boolean;
  setIsBuilderUser: (isBuilderUser: boolean) => void;
  isGuestUser: boolean;
  setIsGuestUser: (isGuestUser: boolean) => void;
  isOTPVerified: boolean;
  setIsOTPVerified: (isOTPVerified: boolean) => void;
  is360: boolean;
  setIs360: (is360: boolean) => void;
  dialog: ICustomDialog | null;
  setDialog: (dialog: ICustomDialog | null) => void;
  isNeedToShowBlankScreen: boolean;
  setIsNeedToShowBlankScreen: (isNeedToShowBlankScreen: boolean) => void;
  isOTPSent: boolean;
  setIsOTPSent: (isOTPSent: boolean) => void;
  isKrispInstalled: boolean;
  setIsKrispInstalled: React.Dispatch<React.SetStateAction<boolean>>;
  meetingDetails: IMeetingDetails;
  setMeetingDetails: React.Dispatch<React.SetStateAction<IMeetingDetails>>;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  guestEmail: string;
  setGuestEmail: React.Dispatch<React.SetStateAction<string>>;
  deviceSettingsOpen: boolean;
  setDeviceSettingsOpen: (deviceSettingsOpen: boolean) => void;
  isFetchingToken: boolean;
  setIsFetchingToken: (isFetchingToken: boolean) => void;
  isFetchingUser: boolean;
  setIsFetchingUser: (isFetchingUser: boolean) => void;  
  permissionStatus: MeetingPermissionStatus | null;
  setPermissionStatus: React.Dispatch<React.SetStateAction<MeetingPermissionStatus | null>>;
  isSaveButtonEnabled: boolean;
  setIsSaveButtonEnabled: (isSaveButtonEnabled: boolean) => void;
  preflightReport: PreflightTestReport | null;
  setPreflightReport: (preflightReport: PreflightTestReport | null) => void;
  preflightReportError: IPreflightError | null;
  setPreflightReportError: (preflightReportError: IPreflightError | null) => void;
  isConsentGiven: boolean;
  setIsConsentGiven: React.Dispatch<React.SetStateAction<boolean>>;
  waitForHost: boolean;
  setWaitForHost: (builderExpertScreen: boolean) => void;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [meetingDetails, setMeetingDetails] = useState<IMeetingDetails>({
    bMeet_id: null,
    isFeatureFlagEnabled: false,
    isScheduledMeetingLink: false,
    meetingLink: '',
    pstnMeetingCode: null,
    pstnPassCode: null,
    source: meetingSource.Standalone,
    isNatashaPresent: false,
    meetingName: '',
    token: null,
    hostEmail: null,
    meetingTime: new Date(),
    bot_name: '',
    call_type: null,
    isMeetingStarted: false
  });

  const [featureFlags, setFeatureFlags] = useState({});
  const [userPreferences, setUserPreferences] = useState({});

  const [isFetchingToken, setIsFetchingToken] = useState(false);
  const [userName, setUserName] = useState<string>('');
  const [guestEmail, setGuestEmail] = useState<string>('');
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [remoteMessage, setRemoteMessage] = useState();
  const [isHost, setIsHost] = useState(false);
  const [hostName, setHostName] = useState('');
  const [builderExpertScreen, setBuilderExpertScreen] = useState(false);
  const [waitForHost, setWaitForHost] = useState(false);
  const [isFetchingConversation, setIsFetchingConversation] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true); //window.matchMedia('(prefers-color-scheme: dark)').matches
  const [activeTab, setActiveTab] = useState<activeElement>(activeElement.Home);
  const [user, setUser] = useState<User | null>(null);
  const [logs, setLogs] = useState<ILogs>();
  const [scheduledMeetings, setScheduledMeetings] = useState<IScheduleMeeting[]>([]);
  const [alert, setAlert] = useState<IAlert | null>(null);
  const [dialog, setDialog] = useState<ICustomDialog | null>(null);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [isRemovedByHost, setIsRemovedByHost] = useState(false);
  const [isBuilderUser, setIsBuilderUser] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [is360, setIs360] = useState(false);
  const [isNeedToShowBlankScreen, setIsNeedToShowBlankScreen] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState<MeetingPermissionStatus | null>(null);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [preflightReport, setPreflightReport] = useState<PreflightTestReport | null>(null);
  const [preflightReportError, setPreflightReportError] = useState<IPreflightError | null>(null);
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  let contextValue = {
    userName,
    setUserName,
    error,
    setError,
    activeSinkId,
    setActiveSinkId,
    user,
    setUser,
    remoteMessage,
    setRemoteMessage,
    setFeatureFlags,
    featureFlags,
    setUserPreferences,
    userPreferences,
    isHost,
    setIsHost,
    hostName,
    setHostName,
    logs,
    setLogs,
    builderExpertScreen,
    setBuilderExpertScreen,
    waitForHost,
    setWaitForHost,
    isFetchingConversation,
    setIsFetchingConversation,
    isDarkTheme,
    setIsDarkTheme,
    activeTab,
    setActiveTab,
    scheduledMeetings,
    setScheduledMeetings,
    alert,
    setAlert,
    isDisconnected,
    setIsDisconnected,
    isRemovedByHost,
    setIsRemovedByHost,
    isBuilderUser,
    setIsBuilderUser,
    isGuestUser,
    setIsGuestUser,
    isOTPVerified,
    setIsOTPVerified,
    is360,
    setIs360,
    dialog,
    setDialog,
    isNeedToShowBlankScreen,
    setIsNeedToShowBlankScreen,
    isOTPSent,
    setIsOTPSent,
    isKrispInstalled,
    setIsKrispInstalled,
    meetingDetails,
    setMeetingDetails,
    deviceSettingsOpen,
    setDeviceSettingsOpen,
    isFetchingToken,
    setIsFetchingToken,
    isFetchingUser,
    setIsFetchingUser,
    permissionStatus,
    setPermissionStatus,
    isSaveButtonEnabled,
    setIsSaveButtonEnabled,
    preflightReport,
    setPreflightReport,
    preflightReportError,
    setPreflightReportError,
    isConsentGiven,
    setIsConsentGiven,
    guestEmail,
    setGuestEmail
  } as StateContextType;

  return <StateContext.Provider value={{ ...contextValue }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
