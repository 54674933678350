import React from 'react';
import BuilderIcon from 'icons/BuilderIcon';
import useRoomState, { RoomStateType } from 'hooks/useRoomState/useRoomState';
import UserInformationDialog from './UserInformationDialog';
import HomeIcon from 'icons/HomeIcon';
import ScheduleIcon from 'icons/ScheduleIcon';
import StartMeetingIcon from 'icons/StartMeetingIcon';
import PlusIcon from 'icons/PlusIcon';
import CalendarIcon from 'icons/CalendarIcon';
import { Tooltip } from '@mui/material';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';
import { useNavigate } from 'react-router-dom';
import RecordingSideBarIcon from 'icons/RecordingSideBarIcon';
import {
  HomeRoute,
  UpcomingMeetingRoute,
  JoinMeetingRoute,
  ScheduleMeetingRoute,
  RecordingRoute,
  // UserProfileRoute,
} from 'utils/constants';
import useStartMeeting from 'hooks/useStartMeeting/useStartMeeting';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import classes from './SideBar.module.scss';
// import SettingsIcon from 'icons/SettingsIcon';

export default function SideBar(props: { roomState: RoomStateType, mobileClasses?: any }) {
  const navigate = useNavigate();
  const roomState = useRoomState();
  const startMeeting = useStartMeeting();
  const {
    activeTab,
    setActiveTab,
    isDisconnected,
    isBuilderUser,
    isGuestUser,
    isOTPVerified,
    isDarkTheme,
    isFetchingToken,
    //setIsDarkTheme,
  } = useAppState();


  const onClick = (activeTab: activeElement, redirectPath: string) => {
    setActiveTab(activeTab);

    if (activeTab === activeElement.Video) {
      startMeeting();
    } else {
      navigate(redirectPath);
    }
  };

  return (
    <div id="sideBar" className={`${classes.sideBar} ${props.mobileClasses}`}>
      <div className={classes.icons}>
        <div className={classes.builderIcon}>
          <BuilderIcon />
        </div>
        {roomState === 'disconnected' && !isDisconnected && !isFetchingToken && (
          <>
            <Tooltip title={'Home'} arrow>
              <div
                onClick={() => onClick(activeElement.Home, HomeRoute)}
                className={`${classes.icon} ${activeTab === activeElement.Home && classes.activeIcon}`}
              >
                <HomeIcon />
              </div>
            </Tooltip>
            {(isBuilderUser || (isGuestUser && isOTPVerified)) && (
              <Tooltip title={'View Upcoming Meetings'} arrow>
                <div
                  onClick={() => onClick(activeElement.Upcoming, UpcomingMeetingRoute)}
                  className={`${classes.icon} ${activeTab === activeElement.Upcoming && classes.activeIcon}`}
                >
                  <ScheduleIcon />
                </div>
              </Tooltip>
            )}
            {isBuilderUser && (
              <Tooltip title={'Start New Meeting'} arrow>
                <div
                  onClick={() => onClick(activeElement.Video, '/')}
                  className={`${classes.icon} ${activeTab === activeElement.Video && classes.activeIcon}`}
                >
                  <StartMeetingIcon isDarkTheme={isDarkTheme} activeTab={activeTab} />
                </div>
              </Tooltip>
            )}
            <Tooltip title={'Join Meeting'} arrow>
              <div
                onClick={() => onClick(activeElement.Plus, JoinMeetingRoute)}
                className={`${classes.icon} ${activeTab === activeElement.Plus && classes.activeIcon}`}
              >
                <PlusIcon />
              </div>
            </Tooltip>
            {isBuilderUser && (
              <Tooltip title={'Schedule Meeting'} arrow>
                <div
                  onClick={() => onClick(activeElement.Calender, ScheduleMeetingRoute)}
                  className={`${classes.icon} ${activeTab === activeElement.Calender && classes.activeIcon}`}
                >
                  <CalendarIcon />
                </div>
              </Tooltip>
            )}
            {isBuilderUser && (
              <Tooltip title={'Recordings'} arrow>
                <div
                  onClick={() => onClick(activeElement.Recording, RecordingRoute)}
                  className={`${classes.icon} ${activeTab === activeElement.Recording && classes.activeIcon}`}
                >
                  <RecordingSideBarIcon />
                </div>
              </Tooltip>
            )}
            {/* {isBuilderUser && (
              <Tooltip title={'User Profile'} arrow>
                <div
                  onClick={() => onClick(activeElement.UserProfile, UserProfileRoute)}
                  className={`${classes.icon} ${activeTab === activeElement.UserProfile && classes.activeIcon}`}
                >
                  <SettingsIcon />
                </div>
              </Tooltip>
            )} */}
          </>
        )}
      </div>
      <div className={classes.icons}>
        {/* <Tooltip title={`Switch to ${isDarkTheme ? 'light' : 'dark'} mode`} arrow placement="top">
          <Switch size="small" onClick={() => setIsDarkTheme(!isDarkTheme)} checked={isDarkTheme} />
        </Tooltip> */}
        {/* <Tooltip title={'Submit Feedback'} arrow placement="top">
          <a target="_blank" href={FEEDBACK_FORM} rel="noreferrer">
            <div style={{ cursor: 'pointer', marginBottom: '-0.5em' }}>
              <FeedbackIcon />
            </div>
          </a>
        </Tooltip> */}
        {/* <div style={{ margin: '0em 0em 1em' }}>
          <DivIcon isSideBar />
        </div> */}
        <UserInformationDialog />
      </div>
    </div>
  );
}
