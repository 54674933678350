import { ConnectOptions } from 'twilio-video';

export default function useConnectionOptions() {
  // See: https://sdk.twilio.com/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions: ConnectOptions = {
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        dominantSpeakerPriority: 'standard',
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'auto',
        trackSwitchOffMode: 'detected',
      },
    },
    video: { height: 480, frameRate: 24, width: 640 },
    audio: true,
    dominantSpeaker: true,
    networkQuality: {
      local: 3,
      remote: 3,
    },
    maxAudioBitrate: 16000,
    preferredVideoCodecs: 'auto',
  };

  return connectionOptions;
}
