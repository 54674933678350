import React from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

export default function PlusIcon() {
  const { isDarkTheme, activeTab } = useAppState();
  const isActive = activeTab === activeElement.Plus;

  return isDarkTheme ? (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_454)">
        <path
          d="M7 4.08334V9.91668"
          stroke={isActive ? '#F8F9FA' : '#AFB2C0'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M9.91659 7H4.08325"
          stroke={isActive ? '#F8F9FA' : '#AFB2C0'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M6.99992 13.4167C10.5437 13.4167 13.4166 10.5438 13.4166 7.00001C13.4166 3.45618 10.5437 0.583344 6.99992 0.583344C3.45609 0.583344 0.583252 3.45618 0.583252 7.00001C0.583252 10.5438 3.45609 13.4167 6.99992 13.4167Z"
          stroke={isActive ? '#F8F9FA' : '#AFB2C0'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_454">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_75)">
        <path
          d="M7 4.08334V9.91668"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M9.91671 7H4.08337"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.00004 13.4167C10.5439 13.4167 13.4167 10.5438 13.4167 7.00001C13.4167 3.45618 10.5439 0.583344 7.00004 0.583344C3.45621 0.583344 0.583374 3.45618 0.583374 7.00001C0.583374 10.5438 3.45621 13.4167 7.00004 13.4167Z"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_75">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
