import { IRecordingMetaData } from 'types';
import { create } from 'zustand';

type RecordingMetaData = {
  recordingMetaData: IRecordingMetaData | null;
  setRecordingMetaData: (data: IRecordingMetaData | null) => void;
};

type ResetAction = {
  resetAll: () => void;
};

type RecordingStore = RecordingMetaData & ResetAction;

const initialState = {
  recordingMetaData: null,
};

export const useRecordingStore = create<RecordingStore>((set) => ({
  ...initialState,

  setRecordingMetaData: (data: IRecordingMetaData | null) => {
    set({ recordingMetaData: data });
  },
  resetAll: () => set({ ...initialState }),
}));
