import React from 'react';
import { Tooltip } from '@mui/material';
import useTruncateText from 'hooks/useTruncateText/useTruncateText';
import { getMeetingTime, getMobileDate, MAX_MEETING_NAME_IN_UI } from 'utils/constants';
import { IScheduleMeeting } from 'types';
import classes from './MeetingCardContent.module.scss';
import { meetingDays } from 'utils/enums';
import MeetingButtonsContainer from './MeetingButtonsContainer';

interface MeetingCardContentProps {
  meetingDetails: IScheduleMeeting;
  activeTab: number
}

function MeetingCardContent({ meetingDetails, activeTab }: MeetingCardContentProps) {
  const [meetingName, isNeedToAddTooltip] = useTruncateText(meetingDetails.name, MAX_MEETING_NAME_IN_UI);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip arrow title={isNeedToAddTooltip ? meetingDetails.name : ''}>
          <span className={classes.title}>{meetingName}</span>
        </Tooltip>
        <span>
          {' '}
          <div className={classes.meetingTime}>
            <span className={classes.meetingTimeMargin}>
              {meetingDetails.meetingDay === meetingDays.Today ? 'Today' : getMobileDate(meetingDetails.start_date)}
            </span>
            <span className={classes.meetingTimeMargin}>|</span>
            <span>{getMeetingTime(meetingDetails.start_date, meetingDetails.duration)}</span>
          </div>
        </span>
      </div>
      <div className={classes.fields}>
        <span className={`${classes.blueColor}`}>Host: </span>
        <span className={classes.data}>{meetingDetails.host_name}</span>
      </div>
      <div className={classes.typeField}>
        <div>
          <div className={classes.fields}>
            <span className={`${classes.blueColor}`}>Meeting ID: </span>
            <span className={classes.data}>{meetingDetails.pstn_meeting_code}</span>
          </div>
          <div className={classes.fields}>
            <span className={`${classes.blueColor}`}>Type: </span>
            <span className={`${classes.data} ${classes.frequency}`}>{meetingDetails.frequency}</span>
          </div>
        </div>
        <MeetingButtonsContainer meetingDetails={meetingDetails} activeTab={activeTab} />
      </div>
    </>
  );
}

export default MeetingCardContent;
