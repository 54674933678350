import React from 'react';
import { activeElement } from 'utils/enums';

export default function StartMeetingIcon(props: { isDarkTheme: boolean; activeTab: activeElement }) {
  const isActive = props.activeTab === activeElement.Video;
  const colorForDarkTheme = isActive ? '#F8F9FA' : '#AFB2C0';
  const colorForLightTheme = isActive ? '#651FFF' : '#83889E';
  const strokeColor = props.isDarkTheme ? colorForDarkTheme : colorForLightTheme;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_449)">
        <path
          d="M9.33325 5.83334L13.4166 4.08334V9.91668L9.33325 8.16668"
          stroke={strokeColor}
          strokeWidth="1.25"
          strokeMiterlimit="10"
        />
        <path
          d="M8.16659 2.33334H1.74992C1.10559 2.33334 0.583252 2.85568 0.583252 3.50001V10.5C0.583252 11.1443 1.10559 11.6667 1.74992 11.6667H8.16659C8.81092 11.6667 9.33325 11.1443 9.33325 10.5V3.50001C9.33325 2.85568 8.81092 2.33334 8.16659 2.33334Z"
          stroke={strokeColor}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_449">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
