import { useAppState } from 'state';
import useNavigateToMeeting from 'hooks/useNavigateToMeeting/useNavigateToMeeting';
import { connectToPSTN } from 'service/MeetAPIServiceV1';

export default function useJoinPSTNMeeting(meetingId: string, passcode: string, userName: string, userEmail: string) {
  const { setAlert } = useAppState();
  const navigateToMeeting = useNavigateToMeeting();
  const data = {
    pstn_meeting_code: meetingId,
    pstn_pass_code: passcode,
  };

  const onJoin = () => {
    connectToPSTN(data)
      .then((res) => {
        navigateToMeeting(res.data.meeting_link, userName, userEmail);
      })
      .catch((err) => {
        setAlert({
          variant: 'error',
          message: 'Please enter valid details',
        });
        console.log(err);
      });
  };

  return onJoin;
}
