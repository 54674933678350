import React from 'react';
import classes from './AMPMButtonGroup.module.scss';

interface AMPMButtonGroupProps {
  meeting: any;
  setMeeting: any;
}

function AMPMButtonGroup({ meeting, setMeeting }: AMPMButtonGroupProps) {
  return (
    <div
      className={classes.endButtons}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {['AM', 'PM'].map((label, index) => (
        <div
          key={index}
          className={`${classes.endButtonStyle} ${meeting.isAMSelected === (index === 0) && classes.buttonBackground}`}
          onClick={() =>
            setMeeting((prevMeetingData: any) => ({
              ...prevMeetingData,
              isAMSelected: index === 0,
            }))
          }
          style={{ marginLeft: index === 1 ? '0.1em' : 0 }}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

export default AMPMButtonGroup;
