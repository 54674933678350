import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useCallback } from 'react';
import { exportLogs } from 'service/MeetAPIServiceV1';
import { useAppState } from 'state';
import { ILogs } from 'types';

export const useLogs = () => {
  const { logs, setLogs } = useAppState();
  const { room } = useVideoContext();

  const saveLogs = useCallback(
    (logError: string, type: string, isNeedYoSaveLogsInDB?: boolean) => {
      const ErrorLog: ILogs = {
        roomSID: room?.sid,
        participantSID: room?.localParticipant?.sid,
        participantName: room?.localParticipant?.identity,
        errors: logs?.errors?.concat({
          type: type,
          error: logError,
          time: new Date().toUTCString(),
        }) ?? [
          {
            type: type,
            error: logError,
            time: new Date().toUTCString(),
          },
        ],
      };

      setLogs(ErrorLog);
      
      if (isNeedYoSaveLogsInDB) {
        exportLogs(ErrorLog);
      }
    },
    [room, logs, setLogs]
  );

  return saveLogs;
};
