import React, { useState, useEffect } from 'react';
import classes from './MobileScheduleMeetingComponent.module.scss';
import {
  HomeRoute,
  ScheduleMeetingRoute,
  convertDateIntoUTC,
  convertStringIntoDate,
  durationArray,
  frequencyArray,
} from 'utils/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LeftArrow from 'icons/LeftArrow';
import ScheduleMeetingHostDelegateDropDown from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingHostDelegateDropDown';
import {
  IFutureEvent,
  IMeetingType,
} from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingDialogContent';
import { IScheduleMeeting } from 'types';
import moment from 'moment';
import { useAppState } from 'state';
import useMeetingData from 'hooks/useMeetingData/useMeetingData';
import DatePicker from 'react-datepicker';
import ScheduleMeetingStartTImeComponent from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingStartTImeComponent';
import ScheduleMeetingDialogDurationComponent from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingDialogDurationComponent';
import ScheduleMeetingAutoCompleteComponent from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingAutoCompleteComponent';
import { InputLabel, TextField } from '@mui/material';
import CkEditorComponent from 'shared/CKEditorComponent/CkEditorComponent';
import ScheduleMeetingRadioGroup from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingRadioGroup';
import ScheduleMeetingFrequencyComponent from 'components/PreJoinComponents/ScheduleMeetingDialogComponent/ScheduleMeetingFrequencyComponent';
import MultiEmailComponent from 'shared/MultiEmailComponent/MultiEmailComponent';
import useScheduleMeetingAPI from 'hooks/useScheduleMeetingAPI/useScheduleMeetingAPI';
import CustomButton from 'shared/CustomButton/CustomButton';
import useRecurringDateTime from 'hooks/useRecurringDateTime/useRecurringDateTime';
import useTimeValidation from 'hooks/useTimeValidation/useTimeValidation';
import useGoogle from 'hooks/useGoogle/useGoogle';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';

function MobileScheduleMeetingComponent() {
  const {signIn, tokenVerified} = useGoogle();
  const navigate = useNavigate();
  const { user, setAlert } = useAppState();
  const location = useLocation();
  const { id } = useParams();
  const { fetchMeetingData } = useMeetingData();
  const { createScheduleMeeting, updateMeeting, btnDisabled } = useScheduleMeetingAPI();
  const { setRecurringDateTimes } = useRecurringDateTime();
  const timeValidation = useTimeValidation();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const [meetingDetails, setMeetingDetails] = useState<IScheduleMeeting | null>(null);
  const [isEditSeriesClick, setIsEditSeriesClick] = useState<Boolean | undefined>(undefined);
  const [isFetchingMeeting, setIsFetchingMeeting] = useState(false);
  const [invalidEmailExist, setInvalidEmailExist] = useState(false);
  const [meeting, setMeeting] = useState<IMeetingType>({
    subject: '',
    description: '',
    meetingType: 'single',
    emails: [],
    startDate: new Date(),
    endDate: null,
    frequency: frequencyArray[0],
    startTime: moment(new Date()).format('hh:mm'),
    selectedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isAMSelected: moment(new Date()).format('A') === 'AM',
    duration: durationArray[3].duration,
    delegator_detail: {
      name: 'You',
      email: '',
      id: -1,
    },
  });

  useEffect(() => {
    if (meetingDetails) {
      setMeeting({
        subject: meetingDetails.name,
        description: meetingDetails.description,
        meetingType: meetingDetails.frequency,
        emails: meetingDetails.participants_list.filter((p) => p),
        startDate: meetingDetails ? meetingDetails.start_date : new Date(),
        endDate: null,
        frequency: frequencyArray[0],
        startTime: moment(meetingDetails.start_date).format('hh:mm'),
        selectedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isAMSelected: moment(meetingDetails.start_date).format('A') === 'AM',
        duration: durationArray.filter((x) => x.duration === meetingDetails.duration)[0]?.duration,
        delegator_detail: {
          name: meetingDetails.user.email === user?.email ? 'You' : meetingDetails.user.name,
          email: meetingDetails.user.email === user?.email ? '' : meetingDetails.user.email,
          id: meetingDetails.user.email === user?.email ? -1 : meetingDetails.user.id,
        },
      });
    }
  }, [meetingDetails, user?.email]);

  useEffect(() => {
    //We need to trigger API call when user is available it takes 2-3 seconds
    //Also we don't need to call this API if route is ScheduleMeetingRoute & state is passed
    async function fetchData() {
      if (user && location.pathname !== ScheduleMeetingRoute) {
        try {
          setIsFetchingMeeting(true);
          let meetingData;
          if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
            meetingData = await fetchMeetingData(false, id, user.email);
          } else {
            const isRecurringMeeting = location.pathname.includes('recurring-meetings');
            meetingData = await fetchMeetingData(isRecurringMeeting, id, user.email);
          }

          //We'll get future_events in case of recurring meeting which we need to set Date
          if (meetingData && meetingData.frequency === 'recurring' && meetingData.future_events) {
            meetingData.future_events.forEach((meeting) => {
              meeting.start_date = convertStringIntoDate(meeting.start_time);
            });
          }

          setMeetingDetails(meetingData);
          if (location.state) {
            const passedData = location.state;
            if (passedData) {
              setIsEditSeriesClick(passedData.isEditSeriesClick);
            }
          }
        } catch (err) {
          console.log('error', err);
        } finally {
          setIsFetchingMeeting(false);
        }
      }
    }

    fetchData();
  }, [fetchMeetingData, id, location, navigate, user]);

  if (!meetingDetails && user && location.pathname !== ScheduleMeetingRoute && !location.state) {
    return null;
  }

  const onSchedule = () => {
    const isStartTimeValid = timeValidation(meeting.startTime);
    if (!isStartTimeValid) {
      return;
    }

    setTime();

    const start = moment(meeting.startDate).parseZone().tz(meeting.selectedTimeZone, true).toDate();
    const utcTime = convertDateIntoUTC(start);

    if (start < new Date()) {
      setAlert({
        variant: 'error',
        message: 'Start time should be greater then current time',
      });
      return;
    }

    if (!meeting.subject) {
      setAlert({
        variant: 'error',
        message: 'Please enter subject of the meeting!',
      });
      return;
    }

    if (meetingDetails) {
      editScheduleMeeting(utcTime);
      return;
    }

    if (meeting.meetingType.toLocaleLowerCase() === 'recurring' && !meeting.endDate) {
      setAlert({
        variant: 'error',
        message: 'Please enter end date of the meeting!',
      });
      return;
    }

    let data = {
      email: meeting.delegator_detail.id === -1 ? user?.email : meeting.delegator_detail.email,
      name: meeting.subject,
      description: meeting.description,
      duration: meeting.duration,
      requested_participants: meeting.emails,
      type: meeting.meetingType.toLowerCase(),
      source: 'standalone',
      ...(meeting.delegator_detail.id !== -1 && {
        managed_by: meeting.delegator_detail.email === user?.email ? null : user?.id,
      })
    };

    if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
      Object.assign(data, {
        start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
        start_time_tz: meeting.selectedTimeZone,
        recurrence_end_time: meeting.endDate,
        end_time_tz: meeting.selectedTimeZone,
        recurrence: meeting.meetingType === 'recurring' ? meeting.frequency : null,
      })
    } else {
      const recurringDateTimes =
        meeting.meetingType === 'recurring'
          ? setRecurringDateTimes(
              meeting.startDate,
              meeting.endDate ?? new Date(),
              meeting.selectedTimeZone,
              meeting.frequency
            )
          : [];

      Object.assign(data, {
        scheduled_start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
        recurring_datetime: recurringDateTimes,
      });
    }

    createScheduleMeeting(data);
  };

  const editScheduleMeeting = (utcTime: Date) => {
    let futureEvents: IFutureEvent[] = [];

    if (isEditSeriesClick && meetingDetails && !isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
      futureEvents = setDateAndTimeForFutureInstance();
      futureEvents.push({ id: meetingDetails.id, occurance_datetime: moment(utcTime).format('DD-MM-YYYY hh:mm A') });
    }

    let data;
    if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
      // The occurrence ID for this meeting is its timestamp
      const occurrenceId = meetingDetails?.occurrence_id;
      data =
        meeting.meetingType === 'recurring'
          ? {
            meeting_uuid: meetingDetails?.meeting_uuid,
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            }),
            name: meeting.subject,
            description: meeting.description,
            start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
            duration: meeting.duration,
            series_id: meetingDetails?.series_id,
            participants_list: meeting.emails,
            ...(isEditSeriesClick
              // Editing all future events
              ? {
                occurrence_from: occurrenceId
              }
              // Editing a single occurrence, so add in the ID
              : {
                occurrence_id: occurrenceId
              }
            )
          }
          : {
              meeting_uuid: meetingDetails?.meeting_uuid,
              name: meeting.subject,
              description: meeting.description,
              start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
              duration: meeting.duration,
              status: 'scheduled',
              participants_list: meeting.emails,
              ...(meetingDetails?.managed_by !== null && {
                managed_by: meetingDetails?.managed_by,
              }),
            }
    } else {
      data =
        meeting.meetingType === 'recurring'
          ? {
              meeting_uuid: meetingDetails?.meeting_uuid,
              ...(meetingDetails?.managed_by !== null && {
                managed_by: meetingDetails?.managed_by,
              }),
              recurring_meetings_attributes: !isEditSeriesClick
                ? [
                    {
                      id: meetingDetails?.id,
                      name: meeting.subject,
                      description: meeting.description,
                      occurance_datetime: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
                      status: 'scheduled',
                      participants_list: meeting.emails,
                      duration: meeting.duration,
                      publish_to_google: true,
                      ...(meetingDetails?.managed_by !== null && {
                        managed_by: meetingDetails?.managed_by,
                      }),
                    },
                  ]
                : futureEvents.map((event) => ({
                    id: event.id,
                    name: meeting.subject,
                    description: meeting.description,
                    occurance_datetime: event.occurance_datetime,
                    status: 'scheduled',
                    participants_list: meeting.emails,
                    duration: meeting.duration,
                    publish_to_google: true,
                    ...(meetingDetails?.managed_by !== null && {
                      managed_by: meetingDetails?.managed_by,
                    }),
                  })),
            }
          : {
              meeting_uuid: meetingDetails?.meeting_uuid,
              name: meeting.subject,
              description: meeting.description,
              start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
              duration: meeting.duration,
              status: 'scheduled',
              participants_list: meeting.emails,
              ...(meetingDetails?.managed_by !== null && {
                managed_by: meetingDetails?.managed_by,
              }),
            };
    }

    updateMeeting(data);
  };

  // It is used to set Hours & Minute in Start Date
  const setTime = () => {
    const timeMoment = moment(meeting.startTime, 'hh:mm A').tz(moment.tz.guess());
    const hour = timeMoment.hour();
    const minute = timeMoment.minute();
    const hours = meeting.isAMSelected ? (hour === 12 ? 0 : hour) : hour !== 12 ? hour + 12 : hour;

    meeting.startDate.setHours(hours, minute, 0);
  };

  const setDateAndTimeForFutureInstance = () => {
    const futureEvents: IFutureEvent[] = [];

    if (meetingDetails?.future_events) {
      meetingDetails?.future_events.forEach((x) => {
        const timeMoment = moment(meeting.startTime, 'hh:mm A').tz(moment.tz.guess());
        const hour = timeMoment.hour();
        const minute = timeMoment.minute();
        const hours = meeting.isAMSelected ? (hour === 12 ? 0 : hour) : hour !== 12 ? hour + 12 : hour;
        if (x.start_date) {
          x.start_date?.setHours(hours, minute, 0);
          const start = moment(x.start_date).parseZone().tz(meeting.selectedTimeZone, true).toDate();
          const utcTime = convertDateIntoUTC(start);
          const momentTIme = moment(utcTime).format('DD-MM-YYYY hh:mm A');
          const array = { id: x.id, occurance_datetime: momentTIme };
          futureEvents.push(array);
        }
      });
    }
    return futureEvents;
  };

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span onClick={() => navigate(HomeRoute)} style={{ display: 'flex', marginLeft: '-0.5em' }}>
            <LeftArrow />
          </span>
          <span style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}>Schedule Meeting</span>
        </div>
      </div>

      {!isFetchingMeeting && (
        <>
          <div style={{ padding: '0em 1em 1em', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            
            <div>
              <TextField
                InputProps={{ className: classes.inputField }}
                disabled={false}
                placeholder="Purpose"
                variant="outlined"
                fullWidth
                size="small"
                value={meeting.subject}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  if (!meeting.subject) {
                    const value = event.target.value.trim();
                    setMeeting((prevMeetingData: any) => ({
                      ...prevMeetingData,
                      subject: value,
                    }));
                  } else {
                    setMeeting((prevMeetingData: any) => ({
                      ...prevMeetingData,
                      subject: event.target.value,
                    }));
                  }
                }}
                className={classes.textField}
              />
            </div>

            <div className={classes.fieldMargin}>
              <InputLabel className={classes.label}>Start Date *</InputLabel>
              <DatePicker
                disabled={isEditSeriesClick === true}
                dateFormat="dd-MMM-yyyy"
                selected={meeting.startDate}
                onChange={(date: Date) => {
                  setMeeting((prevMeetingData) => ({
                    ...prevMeetingData,
                    startDate: date,
                    endDate: meeting.endDate && meeting.endDate < date ? null : meeting.endDate,
                  }));
                }}
                minDate={new Date()}
                maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                className={`${classes.date} ${classes.textField} ${classes.startDate}`}
              />
            </div>

            <div className={classes.fieldMargin}>
              <InputLabel className={classes.label}>Start Time *</InputLabel>
              <ScheduleMeetingStartTImeComponent meeting={meeting} setMeeting={setMeeting} />
            </div>

            <div className={classes.fieldMargin}>
              <InputLabel className={classes.label}>Duration (in minutes)</InputLabel>
              <ScheduleMeetingDialogDurationComponent meeting={meeting} setMeeting={setMeeting} />
            </div>

            <div className={classes.fieldMargin}>
              <InputLabel className={classes.label}>Timezone *</InputLabel>
              <ScheduleMeetingAutoCompleteComponent meeting={meeting} setMeeting={setMeeting} />
            </div>

            <div className={classes.fieldMargin}>
              <InputLabel className={classes.label}></InputLabel>
              <MultiEmailComponent
                meeting={meeting}
                setMeeting={setMeeting}
                setInvalidEmailExist={setInvalidEmailExist}
              />
            </div>

            {!meetingDetails && (
              <div className={`${classes.fieldMargin} ${classes.frequencyField}`}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <InputLabel className={classes.label}></InputLabel>
                  <div className={classes.frequency}>
                    <ScheduleMeetingRadioGroup
                      meeting={meeting}
                      setMeeting={setMeeting}
                      isFieldDisabled={Boolean(meetingDetails)}
                    />
                  </div>
                </div>

                {meeting.meetingType === 'recurring' && (
                  <>
                    <div className={classes.frequencyStyle}>
                      <ScheduleMeetingFrequencyComponent
                        isFieldDisabled={Boolean(meetingDetails)}
                        meeting={meeting}
                        setMeeting={setMeeting}
                      />
                    </div>

                    <div>
                      <InputLabel className={classes.label} style={{ margin: '1.5em 0em 0.5em 0em' }}>
                      </InputLabel>
                      <DatePicker
                        placeholderText="End Date"
                        disabled={Boolean(meetingDetails)}
                        dateFormat="dd-MMM-yyyy"
                        selected={meeting.endDate}
                        onChange={(date: Date) => {
                          setMeeting((prevMeetingData: any) => ({
                            ...prevMeetingData,
                            endDate: date ? new Date(date.setHours(23, 59, 59)) : null,
                          }));
                        }}
                        minDate={meeting.startDate}
                        className={`${classes.date} ${classes.endDate}`}
                      />
                    </div>
                  </>
                )}
              </div>
            )}

           <div className={classes.fieldMargin}>
              <InputLabel className={classes.label} style={{ marginBottom: '0.5em' }}>
                Description (optional)
              </InputLabel>
              <CkEditorComponent meeting={meeting} setMeeting={setMeeting} />
            </div>

            <div className={classes.fieldMargin}>
              <InputLabel className={classes.label} style={{ marginBottom: '0.5em' }}>
                Creating Meeting For *
              </InputLabel>
              <ScheduleMeetingHostDelegateDropDown
                meeting={meeting}
                setMeeting={setMeeting}
                meetingDetails={meetingDetails}
              />
            </div>

          </div>
          <div className={classes.actionDialog}>
            {!tokenVerified && (
              <CustomButton buttonName="Authorize Calendar Access" onClick={signIn} btnClass={classes.cancelButton} />
            )}

            <div className={classes.meetingButtons}>
              <CustomButton
                buttonName="Cancel"
                onClick={() => {
                  navigate(HomeRoute);
                }}
                btnClass={classes.cancelButton}
              />
              <CustomButton
                buttonName={meetingDetails ? 'Update Meeting' : 'Schedule'}
                onClick={onSchedule}
                btnClass={classes.joinButton}
                isDisabled={btnDisabled || invalidEmailExist || !tokenVerified}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MobileScheduleMeetingComponent;
