import React, { useEffect, useState } from 'react';
import classes from './ScheduleMeetingHostDelegateDropDown.module.scss';
import { MenuItem, Select, TextField } from '@mui/material';
import { getInitials } from 'utils/constants';
import { useAppState } from 'state';
import { IDelegate } from 'components/DelegationDialog/DelegationDialog';
import { IScheduleMeeting } from 'types';
import { fetchOwners } from 'service/MeetAPIServiceV1';

interface ScheduleMeetingHostDelegateDropDownProps {
  meeting: any;
  setMeeting: any;
  meetingDetails: IScheduleMeeting | null;
}

function ScheduleMeetingHostDelegateDropDown({
  meeting,
  setMeeting,
  meetingDetails,
}: ScheduleMeetingHostDelegateDropDownProps) {
  const { user } = useAppState();
  const [ownerList, setOwnerList] = useState<IDelegate[]>([]);

  useEffect(() => {
    if (user) {
      getOwners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getOwners = () => {
    fetchOwners(user?.email ?? '')
      .then((res) => {
        const localUser = { name: 'You', email: user?.email ?? '', id: -1 };
        const userList = res.data.users;
        userList.splice(0, 0, localUser);
        setOwnerList(userList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {ownerList.length > 1 ? (
        <Select
          disabled={Boolean(meetingDetails)}
          fullWidth
          disableUnderline
          className={classes.input}
          variant="filled"
          value={meeting.delegator_detail.name}
          onChange={(e) => {
            const selectedDelegator = ownerList.find((x) => x.name === e.target.value);
            setMeeting((prevMeetingData: any) => ({
              ...prevMeetingData,
              delegator_detail: selectedDelegator,
            }));
          }}
          renderValue={(selected) => selected} // Only render the selected value
        >
          {ownerList.map((delegator) => (
            <MenuItem className={classes.menu} value={delegator.name} key={delegator.name}>
              <div className={classes.menuItem}>
                <div className={classes.initial}>{getInitials(delegator.name)}</div>
                <div className={classes.participantDetail}>
                  <div className={classes.name}>{delegator.name}</div>
                  <div>
                    <span className={classes.email}>{delegator.email}</span>
                  </div>
                </div>
              </div>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'white',
            },
          }}
          InputProps={{ className: classes.inputField }}
          autoCapitalize="false"
          variant="outlined"
          fullWidth
          size="small"
          value={'Self'}
          inputProps={{ maxLength: 25 }}
          className={classes.textField}
        />
      )}
    </>
  );
}

export default ScheduleMeetingHostDelegateDropDown;
