import useMeetingTimeVerification from 'hooks/useMeetingTimeVerification/useMeetingTimeVerification';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

const useNavigateToMeeting = () => {
  const { setActiveTab, setDeviceSettingsOpen } = useAppState();
  const navigate = useNavigate();

  const navigateToMeeting = useCallback(
    (meetingUrl: string, userName?: string, userEmail?: string) => {
      const url = new URL(meetingUrl);
      const pathWithQuery = url.pathname + url.search;
      navigate(pathWithQuery, { state: { name: userName, email: userEmail } });

      setDeviceSettingsOpen(true);
      setActiveTab(activeElement.Home);
    },
    [navigate, setActiveTab, setDeviceSettingsOpen]
  );

  return navigateToMeeting;
};

export default useNavigateToMeeting;
