import React from 'react';
import classes from './HomeScreenTopBar.module.scss';
import { SignedInFAQ, GuestUserFAQ, FeedbackRoute } from 'utils/constants';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';

interface HomeScreenTopBarProps {
  Icon: JSX.Element;
  title: string;
}

export default function HomeScreenTopBar({ Icon, title }: HomeScreenTopBarProps) {
  const { user } = useAppState();
  const navigate = useNavigate();

  return (
    <div className={classes.main}>
      <div className={classes.topBar}>
        <span className={classes.homeIcon}>{Icon}</span>
        <span style={{ fontWeight: 'bold' }}>{title}</span>
      </div>

      <div className={classes.text}>
        <a className={classes.text} target="_blank" href={user ? SignedInFAQ : GuestUserFAQ} rel="noreferrer">
          <div style={{ cursor: 'pointer' }}>
            <span style={{ cursor: 'pointer', marginRight: '0.5em' }}>FAQ</span>
          </div>
        </a>
        <span style={{ marginRight: '0.5em', color: 'gray' }}>|</span>
        <span>
          <div className={`${classes.feedback}`} onClick={() => navigate(FeedbackRoute)}>
            <span>Submit Feedback</span>
          </div>
        </span>
      </div>
    </div>
  );
}
