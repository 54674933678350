import { useAuth } from 'react-oidc-context';
import { useCallback } from 'react';

export function useAuthActions() {
  const auth = useAuth();

  const authSignIn = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    auth.signinRedirect()
    localStorage.setItem('signedInEvent', 'true');
  }, [auth]);

  const authSignOut = useCallback(() => {
    auth.removeUser();
    auth.signoutRedirect({
      extraQueryParams: {
        redirect_uri: process.env.REACT_APP_POST_LOGOUT_URL!,
      },
    });
    localStorage.removeItem('signedInEvent');

  }, [auth]);

  return { authSignIn, authSignOut };
}
