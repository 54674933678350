import { useEffect } from 'react';
import { Room, TwilioError } from 'twilio-video';
import { ErrorCallback } from 'types';
import { useAppState } from 'state';
import useResetStates from 'hooks/useResetStates/useResetStates';
import { useWhiteboardStore } from 'store/whiteboardStore';
import useEnterpriseIntegration from 'hooks/useEnterpriseIntegration/useEnterpriseIntegration';
import { exportLogs } from 'service/MeetAPIServiceV1';

export default function useHandleOnDisconnect(
  room: Room | null,
  onError: ErrorCallback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  const { logs, setIsDisconnected } = useAppState();
  const {enterpriseDisconect} = useEnterpriseIntegration();
  const resetStates = useResetStates();
  const resetAll = useWhiteboardStore((state) => state.resetAll);

  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          console.log('DisconnectionError', error);
          onError(error, 'disconnect');
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }

        enterpriseDisconect();
        if (logs) {
          exportLogs(logs);
        }

        setIsDisconnected(true);
        resetStates();
        resetAll();
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare, logs]);
}
