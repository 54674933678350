import moment from 'moment';
import { LocalParticipant, Participant } from 'twilio-video';
import { IScheduleMeeting } from 'types';
import { meetingDays } from './enums';

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 640,
  height: 480,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'BuilderMeet-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'BuilderMeet-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'BuilderMeet-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'BuilderMeet-selectedBackgroundSettings';

export const copySuccessfulMessage = 'Yes! You’ve copied this meeting link';
export const copyRecordingSuccessfulMessage = 'Yes! You’ve copied this recording link';
export const copyUnSuccessfulMessage = 'Sorry, link isn’t copying. Can you try again later?';
export const inviteSuccessfulMessage = 'Successfully sent your invite!';
export const inviteUnSuccessfulMessage =
  'Sorry, there’s a problem sending this email. Can you copy the meeting link and email it to them?';

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((text) => text[0])
    .join('')
    .toUpperCase();
};

export const dialingInNumbersArray =
  process.env.REACT_APP_PSTN_NUMBERS === 'staging'
    ? ['IN: 000 80004-03028']
    : ['US: +1 844-981-4698', 'UK: +44 8081-694144', 'IN: 000 80004-03712']

export const dialingOutNumbersArray =
  process.env.REACT_APP_PSTN_NUMBERS === 'staging'
    ? [
      { label: 'IN: +91 80004-03028', value: '+918000403028' },
      { label: 'US: +1 202-931-7156', value: '+12029317156' },
    ]
    : [
        { label: 'IN: +91 80004-03712', value: '+918000403712' },
        { label: 'US: +1 844-981-4698', value: '+18449814698' },
        { label: 'UK: +44 8081-694144', value: '+448081694144' },
      ];

export const isChromeBrowser = navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc');
// @ts-ignore
export const isFirefoxBrowser = typeof InstallTrigger !== 'undefined';
// @ts-ignore
export const isSafariBrowser = /constructor/i.test(window.HTMLElement) || (function (p) {return p.toString() === '[object SafariRemoteNotification]'})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

export const chromeSupportLink = 'https://support.google.com/chrome/answer/2693767';
export const firefoxSupportLink =
  'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
export const safariSupportLink = 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac';

//To Convert String into Date. (MeetingTime comes from the back-end)
export const convertStringIntoDate = (meetingTime: string) => {
  if (meetingTime) {
    return moment.utc(meetingTime, 'DD/MM/YYYY hh:mm A').toDate();
  } else {
    return new Date();
  }
};

export const convertDateIntoTime = (date: Date) => {
  return moment(date).format('hh:mm A');
};

export const getMobileDate = (date: Date) => {
  return moment(date).format('DD-MMM');
};

export const convertDateIntoUTC = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const setStartDateAndMeetingDay = (meetingDetails: IScheduleMeeting) => {
  meetingDetails.start_date = convertStringIntoDate(meetingDetails.start_time);
  meetingDetails.start_time = convertDateIntoTime(meetingDetails.start_date)
  setMeetingDay(meetingDetails);
};

export const setMeetingDay = (meetingDetails: IScheduleMeeting) => {
  //Set Meeting Day, either Today/Tomorrow or Upcoming
  const todaysDate = new Date();
  const startDate = meetingDetails.start_date;

  const todayMoment = moment([todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate()]);
  const startMoment = moment([startDate.getFullYear(), startDate.getMonth(), startDate.getDate()]);

  const difference = startMoment.diff(todayMoment, 'days');
  const day = difference === 1 ? meetingDays.Tomorrow : meetingDays.Upcoming;
  meetingDetails.meetingDay = difference === 0 ? meetingDays.Today : day;
};

export const getMeetingDate = (date: Date) => {
  return moment(date).format('DD-MMM-yyyy');
};

export const getMeetingTime = (meetingDate: Date, duration: number) => {
  const date = new Date(meetingDate);
  const startTime = convertDateIntoTime(date);
  const endTime = getEndTime(date, duration);
  return `${startTime} to ${endTime}`;
};

export const getEndTime = (date: Date, duration: number) => {
  const newDate = new Date(date);
  newDate.setSeconds(newDate.getSeconds() + duration); //Add Duration in Date
  return convertDateIntoTime(newDate);
};

export const convertSecondsIntoHourAndMinute = (seconds: number) => {
  const minute = Math.floor(seconds / 60);
  if (minute > 90) {
    const hours = minute / 60;
    const remainingHours = Math.floor(hours);
    const minutes = (hours - remainingHours) * 60;
    const remainingMinutes = Math.round(minutes);
    return `${remainingHours} Hour(s) ${remainingMinutes} Minute(s)`;
  } else {
    return `${minute} Mins`;
  }
};

export const convertSecondsIntoHourAndMinuteForRecordingDuration = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secs = duration.seconds();

  if (hours > 0) {
    return `${hours} Hour(s) ${minutes} Minute(s)`;
  } else if (minutes > 0) {
    return `${minutes} Mins ${secs} Secs`;
  } else {
    return `${secs} Secs`;
  }
};

//Arrays

export const frequencyArray = ['Daily', 'WorkDay', 'Weekly', 'Monthly', 'Fortnightly'];
export const timeArray = [
  '12:00',
  '12:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
];

//Numbers

export const zero = 0;
export const Six = 6;
export const Ten = 10;
export const twelve = 12;
export const fiftyNine = 59;
export const sixty = 60;
export const thousand = 1000;
export const threeHundred = 300;
export const threeHundredTwenty = 320;

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

//We need to split identity because we're getting like Test<test@gmail.com>
export const splitIdentity = (identity: string | undefined) => {
  if (identity) {
    const cleanedIdentity = identity.replace(/<[^>]+>/g, '').trim();
    return cleanedIdentity;
  }
  
  return '';
};

export const sortByZone = (a: any, b: any) => {
  let [ahh, amm] = a.split('GMT')[1].split(')')[0].split(':');
  let [bhh, bmm] = b.split('GMT')[1].split(')')[0].split(':');
  return +ahh * 60 + amm - (+bhh * 60 + bmm);
};

export const tooltipMessagfeForScreenShare = (
  isScreenShareSupported: any,
  localParticipant: LocalParticipant,
  screenShareParticipant: Participant | undefined,
  disableScreenShareButton: boolean,
  isSharingScreen: boolean
) => {
  if (!isScreenShareSupported) {
    return SHARE_NOT_SUPPORTED_TEXT;
  } else if (localParticipant.sid !== screenShareParticipant?.sid && disableScreenShareButton) {
    return SHARE_IN_PROGRESS_TEXT;
  } else if (isSharingScreen) {
    return STOP_SCREEN_SHARE_TEXT;
  } else {
    return SCREEN_SHARE_TEXT;
  }
};

export const tooltipMessagfeForAudio = (hasAudioDevice: boolean, isAudioEnabled: boolean | undefined) => {
  if (!hasAudioDevice) {
    return 'No Audio';
  } else if (isAudioEnabled) {
    return 'Mute';
  } else {
    return 'Unmute';
  }
};

export const tooltipMessagfeForVideo = (hasVideoDevice: boolean, isEnabled: boolean | undefined) => {
  if (!hasVideoDevice) {
    return 'No Video';
  } else if (isEnabled) {
    return 'Stop Video';
  } else {
    return 'Start Video';
  }
};

export const ifParticipantNatashaOrPSTN = (isNatashaPresent: boolean, participant: Participant, botName: string) => {
  if (isNatashaPresent) {
    return splitIdentity(participant.identity).toLowerCase() === botName;
  } else {
    return splitIdentity(participant.identity).substring(0, 4).toLowerCase() === 'phn-';
  }
};

export const ifParticipantNatasha = (isNatashaPresent: boolean, participant: Participant, botName: string) => {
  if (isNatashaPresent) {
    return splitIdentity(participant.identity).toLowerCase() === botName;
  } else {
    return false
  }
};

export const getClonedTrack = (mediaStreamTrack: MediaStreamTrack, isIOS: boolean) => {
  return isIOS ? mediaStreamTrack.clone() : mediaStreamTrack;
};

export const ifNeedToChangeIcon = (
  isBottomMenu: boolean | undefined,
  isMicrophonePermissionDenied: boolean,
  isLocalParticipant: boolean | undefined
) => {
  let isNeedToChangeIcon = false;
  if (isBottomMenu) {
    isNeedToChangeIcon = isMicrophonePermissionDenied;
  } else if (isMicrophonePermissionDenied) {
    isNeedToChangeIcon = isLocalParticipant ? true : false;
  }
  return isNeedToChangeIcon;
};

//Routes

export const HomeRoute = '/home';
export const UpcomingMeetingRoute = '/upcoming-meetings';
export const JoinMeetingRoute = '/join-meeting';
export const RecordingRoute = '/recordings';
export const ScheduleMeetingRoute = '/schedule-meeting/new';
export const UpdateScheduleMeetingRoute = '/schedule-meetings/:id/edit';
export const UpdateRecurringMeetingRoute = '/recurring-meetings/:id/edit';
export const UserProfileRoute = '/user-profile';
export const FeedbackRoute = '/feedback';
export const MobileUserProfile = '/profile';

//BuilderMeet Constants

export const preferredBrowserWarningAutoHideDuration = 10000 
export const alertMessageAutoHideDuration = 5000

//BuilderMeet Local Storage Constants

export const VERIFIED_EMAILS = 'VERIFIED_EMAILS'
export const FEATURE_FLAGS = 'FEATURE_FLAGS'


// Other

export const MAX_MEETING_NAME_IN_UI = 30
export const MAX_MEETING_ID = 10
export const MIN_MEETING_PASSCODE = 6
export const MAX_MEETING_PASSCODE = 12
export const MAX_ROOM_NAME_AT_TOP = 25
export const FEEDBACK_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSfDEm_v8GOoZE8olm29ajgPrv44c_qMD9NZjcDUNaGyzqRc0A/viewform';
export const SignedInFAQ = 'https://docs.google.com/document/d/1Ynz0HqZsQ4-BV2x5_2hByYCkzty3AWpo/edit?usp=sharing&ouid=111517722727436595072&rtpof=true&sd=true';
export const GuestUserFAQ = 'https://docs.google.com/document/d/1d6MOwyflWyL7UbbNH3GoHr3TOcrvO07k/edit?usp=sharing&ouid=111517722727436595072&rtpof=true&sd=true';

export const durationArray = [
  {
    time: '15',
    duration: 900,
  },
  {
    time: '30',
    duration: 1800,
  },
  {
    time: '45',
    duration: 2700,
  },
  {
    time: '60',
    duration: 3600,
  },
  {
    time: '90',
    duration: 5400,
  },
  {
    time: '120',
    duration: 7200,
  },
  {
    time: '150',
    duration: 9000,
  },
  {
    time: '180',
    duration: 10800,
  },
  {
    time: '210',
    duration: 12600,
  },
  {
    time: '240',
    duration: 14400,
  },
];

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, (matchedChar: string) => matchedChar.toUpperCase());
}

//Messages

export const UserSyncToggleSuccess = 'User preference saved successfully';

export const SourceStandAloneOrCalendar = (callType: string | null) => {
  return !callType; //If call type is null then it will be either from standalone or from calendar
}

export const extractValuesFromURL = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(https?:\/\/[^\/]+\/|)[^\/?@]+(?:@([^\/?@]+))?(?:@(\d+))?.*passcode=([^&]+)/;
  const match = url.match(regex);

  if (!match) {
      return null; // URL format doesn't match
  }

  const [, , meetingId, occurrenceId] = match;
  const roomName = url.split('/')?.pop()?.split('?')[0].split('@')[0]; // Fix room name extraction
  const output = {
      "room_name": roomName,
      "meeting_id": meetingId ? meetingId.split('@')[0] : null,
      "occurance_id": occurrenceId ? parseInt(occurrenceId) : null,
  };

  return output;
}

export const CALENDAR_SCOPE = 'https://www.googleapis.com/auth/calendar';

export const capitalizedString = (name: string) => {
  return name?.split(' ').map(word => word.charAt(0)?.toUpperCase() + word.slice(1))?.join(' ');
}
export const ENTERPRISE_PARAMS = 'is_source_enterprise_surface';
export const SOURCE = 'source';
export const USERNAME = 'username';
export const EMAIL = 'email';
