import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_AUDIO_OUTPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from 'utils/constants';
import { getDeviceInfo, isPermissionDenied } from 'utils';
import { useCallback, useState } from 'react';
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
  CreateLocalAudioTrackOptions,
  NoiseCancellationOptions,
} from 'twilio-video';
import { useAppState } from 'state';
import { MeetingPermissionStatus } from 'utils/enums';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';

const noiseCancellationOptions: NoiseCancellationOptions = {
  sdkAssetsPath: '/noisecancellation',
  vendor: 'krisp',
};

export default function useLocalTracks() {
  const { setIsKrispInstalled, setPermissionStatus } = useAppState();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const getLocalAudioTrack = useCallback(async () => {
    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const { audioInputDevices } = await getDeviceInfo();

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );

    const options: CreateLocalAudioTrackOptions = {
      noiseCancellationOptions,
      ...(hasSelectedAudioDevice && { deviceId: { exact: selectedAudioDeviceId! } }),
    };

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    const options: CreateLocalTrackOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
    };

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      setVideoTrack(newTrack);
      return newTrack;
    });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const setAudioAndVideoTracks = useCallback(
    async (newVideoTrack: Video.LocalVideoTrack, newAudioTrack: Video.LocalAudioTrack) => {
      const { audioInputDevices, audioOutputDevices } = await getDeviceInfo();

      if (newVideoTrack) {
        setVideoTrack(newVideoTrack);
        // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
        // in cases where the user's video is disabled.
        window.localStorage.setItem(
          SELECTED_VIDEO_INPUT_KEY,
          newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ''
        );
      }

      if (newAudioTrack) {
        setAudioTrack(newAudioTrack);
        if (newAudioTrack.noiseCancellation) {
          newAudioTrack.noiseCancellation.enable()
          setIsKrispInstalled(true);
        }

        const selectedAudioInput = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
        const selectedAudioOutput = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);

        if (!selectedAudioInput && audioInputDevices) {
          window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, audioInputDevices[0]?.deviceId ?? '');
        }

        if (!selectedAudioOutput && audioOutputDevices) {
          window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, audioOutputDevices[0]?.deviceId ?? '');
        }
      }
    },
    [setVideoTrack, setAudioTrack, setIsKrispInstalled]
  );

  const getAudioAndVideoTracks = useCallback(async () => {
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
    if (isAcquiringLocalTracks) return Promise.resolve();

    setIsAcquiringLocalTracks(true);

    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied;
    const shouldAcquireAudio = hasAudioInputDevices && !isMicrophonePermissionDenied;

      const audioOptions = isFeatureFlagEnabled("auto_switch_audio_device_86bw35u13") ? 
      { defaultDeviceCaptureMode: 'auto', deviceId: { exact: selectedAudioDeviceId! } } : 
      { deviceId: { exact: selectedAudioDeviceId! } };

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
      },
      audio: shouldAcquireAudio && {
        noiseCancellationOptions,
        ...(hasSelectedAudioDevice && audioOptions),
      },
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === 'video') as LocalVideoTrack;
        const newAudioTrack = tracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
        setAudioAndVideoTracks(newVideoTrack, newAudioTrack);
        setErrors(isCameraPermissionDenied, isMicrophonePermissionDenied);
      })
      .catch((err) => {
        const { message } = err;
        if (
          message === MeetingPermissionStatus.MicrophoneAndCameraDenied ||
          message === MeetingPermissionStatus.MicrophoneDenied ||
          message === MeetingPermissionStatus.CameraDenied
        ) {
          handlePermissionError(message);
        } else {
          handlePermissionError(MeetingPermissionStatus.MicrophoneAndCameraDenied);
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAcquiringLocalTracks, setAudioAndVideoTracks]);

  const setErrors = (isCameraPermissionDenied: boolean, isMicrophonePermissionDenied: boolean) => {
    if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
      handlePermissionError(MeetingPermissionStatus.MicrophoneAndCameraDenied);
    } else if (isCameraPermissionDenied) {
      handlePermissionError(MeetingPermissionStatus.CameraDenied);
    } else if (isMicrophonePermissionDenied) {
      handlePermissionError(MeetingPermissionStatus.MicrophoneDenied);
    }
  };

  const handlePermissionError = (message: MeetingPermissionStatus) => {
    setPermissionStatus(message);
    throw new Error(message);
  };

  const localTracks = [audioTrack, videoTrack].filter((track) => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return {
    localTracks,
    getLocalVideoTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    getLocalAudioTrack,
  };
}
