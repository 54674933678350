import useNavigateToMeeting from 'hooks/useNavigateToMeeting/useNavigateToMeeting';
import { useCallback } from 'react';
import { startInstantMeeting } from 'service/MeetAPIServiceV1';
import { useAppState } from 'state';

const useStartMeeting = () => {
  const { user, setAlert } = useAppState();
  const navigateToMeeting = useNavigateToMeeting();

  const startMeeting = useCallback(() => {
    startInstantMeeting({
      email: user?.email,
    })
      .then((res) => {
        navigateToMeeting(res.data.meeting_link);
      })
      .catch((err) => {
        setAlert({
          message: 'Error while starting the meeting',
          variant: 'error',
        });
        console.log(err)
        //handle error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, navigateToMeeting]);

  return startMeeting;
};

export default useStartMeeting;
