import React from 'react';

function DelegateIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.625 16C21.625 15.6548 21.3452 15.375 21 15.375H11C10.6548 15.375 10.375 15.6548 10.375 16"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 22.875C10.9581 22.875 11.9375 21.8956 11.9375 20.6875C11.9375 19.4794 10.9581 18.5 9.75 18.5C8.54188 18.5 7.5625 19.4794 7.5625 20.6875C7.5625 21.8956 8.54188 22.875 9.75 22.875Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.875 25.375C12.2595 24.2209 11.058 23.5 9.75 23.5C8.44202 23.5 7.24052 24.2209 6.625 25.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.25 22.875C23.4581 22.875 24.4375 21.8956 24.4375 20.6875C24.4375 19.4794 23.4581 18.5 22.25 18.5C21.0419 18.5 20.0625 19.4794 20.0625 20.6875C20.0625 21.8956 21.0419 22.875 22.25 22.875Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.375 25.375C24.7595 24.2209 23.558 23.5 22.25 23.5C20.942 23.5 19.7405 24.2209 19.125 25.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 11C17.2081 11 18.1875 10.0206 18.1875 8.8125C18.1875 7.60438 17.2081 6.625 16 6.625C14.7919 6.625 13.8125 7.60438 13.8125 8.8125C13.8125 10.0206 14.7919 11 16 11Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7074 12.8758C18.0315 12.0822 17.0415 11.625 15.9991 11.625C14.9567 11.625 13.9667 12.0822 13.2908 12.8758"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DelegateIcon;
