import React, { PropsWithChildren } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor-custom-build-react';

const editorConfig = {
  link: {
    defaultProtocol: 'https://',
  },
};

interface CkEditorComponentProps {
  meeting: any;
  setMeeting: any;
}

export default function CkEditorComponent({ meeting, setMeeting }: PropsWithChildren<CkEditorComponentProps>) {
  return (
    <CKEditor
      config={editorConfig}
      type=""
      name={'Description'}
      editor={Editor}
      data={meeting.description}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        setMeeting((prevMeetingData: any) => ({
          ...prevMeetingData,
          description: data, //We only need timezone... e.g Asia/Kolkata
        }));
      }}
    />
  );
}