import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig = {
  authority: process.env.REACT_APP_IDP_HOST!,
  client_id: process.env.REACT_APP_IDP_CLIENT_ID!,
  client_secret: process.env.REACT_APP_IDP_CLIENT_SECRET!,
  scope: 'oauth2_uids openid',
  content_type: 'application/x-www-form-urlencoded',
  grant_type: 'code',
  redirect_uri: window.location.hostname.includes("engineer") ? 
  process.env.REACT_APP_IDP_REDIRECT_URI!.replace("builder", "engineer") :
  process.env.REACT_APP_IDP_REDIRECT_URI!,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  loadUserInfo: false,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
  metadata: {
    backchannel_logout_supported: true,
    backchannel_logout_session_supported: true,
    issuer: process.env.REACT_APP_ISSUER!,
    authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT!,
    end_session_endpoint: process.env.REACT_APP_END_SESSION_ENDPOINT!,
    token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT!,
    userinfo_endpoint: process.env.REACT_APP_USERINFO_ENDPOINT!,
  },
  onSigninCallback() {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
