import { useEffect } from 'react';
import { useAppState } from 'state';
import { isChromeBrowser } from 'utils/constants';

const usePreferredBrowserWarning = () => {
  const { setAlert } = useAppState();
  
  useEffect(() => {
    const localStorageValue = window.localStorage.getItem('isPreferredBrowserWarningClosed');
    let isPreferredBrowserWarningClosed = false;
    if (localStorageValue) {
      isPreferredBrowserWarningClosed = JSON.parse(localStorageValue);
    }

    if (!isChromeBrowser && !isPreferredBrowserWarningClosed) {
      setAlert({
        message:
          'Google Chrome is the recommended browser for Builder Meet. Please use Chrome for the best user experience.',
        variant: 'info',
        isPreferredBrowserWarning: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePreferredBrowserWarning;
