import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'components/App/App';
import AppStateProvider from 'state';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'types';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import 'themes/lightBg.scss';
import 'themes/darkBg.scss';
import 'themes/resolution.css';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from 'oidcCofig';

const Initialize = () => {
  return (
    <div id="initialize">
      <BrowserRouter>
          <AuthProvider {...oidcConfig}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT as string}>
              <AppStateProvider>
                <App />
              </AppStateProvider>
            </GoogleOAuthProvider>
          </AuthProvider>
      </BrowserRouter>
    </div>
  );
};

const rootNode = document.getElementById('root');
ReactDOM.render(<Initialize />, rootNode);
