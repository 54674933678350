import React from 'react';
import { Button } from '@mui/material';

interface CustomButtonProps {
  buttonName: string;
  isDisabled?: boolean;
  onClick(event?: React.MouseEvent<HTMLElement>): void;
  testid?: string;
  btnClass?: string;
}

export default function CustomButton({
  buttonName,
  isDisabled,
  onClick,
  testid,
  btnClass,
}: CustomButtonProps) {
  return (
    <Button
      style={{fontSize: '15px'}}
      data-testid={testid}
      variant="contained"
      onClick={onClick}
      disabled={isDisabled}
      className={btnClass}
    >
      {buttonName}
    </Button>
  );
}
