import { useCallback } from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';
import { googleLogout } from '@react-oauth/google';

const useSignOut = () => {
  const {
    setUser,
    setActiveTab,
    setScheduledMeetings,
    setIsBuilderUser,
    setIsGuestUser,
    setIsOTPVerified,
    setUserName,
    setGuestEmail,
  } = useAppState();

  const signOut = useCallback(() => {
    window.localStorage.removeItem('user-email');
    window.localStorage.removeItem('profilePicture');
    googleLogout();
    setUser(null);
    setActiveTab(activeElement.Home);
    setScheduledMeetings([]);
    setIsBuilderUser(false);
    setIsGuestUser(false);
    setIsOTPVerified(false);
    setGuestEmail('');
    setUserName('');
  }, [
    setUser,
    setActiveTab,
    setScheduledMeetings,
    setIsBuilderUser,
    setIsGuestUser,
    setIsOTPVerified,
    setGuestEmail,
    setUserName,
  ]);

  return signOut;
};

export default useSignOut;
