import { useGoogleLogin } from '@react-oauth/google';
import { useAppState } from 'state';
import useSignOut from './useSignOut';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import { CALENDAR_SCOPE } from 'utils/constants';
import { getAccessTokenForGoogleAuthentication, verifyGoogleToken } from 'service/MeetAPIServiceV1';
import { createCalendarProvider } from 'service/MeetAPIServiceV2';
import { useEffect, useState } from 'react';

export default function useGoogle() {
  const { user, setUser, setAlert } = useAppState();
  const { setIsFetchingUser } = useAppState();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const signOut = useSignOut();
  const [tokenVerified, setTokenVerified] = useState(false);

  useEffect(() => {
    if(user){
      verifyGoogleAuthToken();
    }
  }, [user?.email]);

  const signIn = useGoogleLogin({
    onSuccess: (res) => onSuccess(res),
    onError: (err) => onFailure(err),
    flow: 'auth-code',
    scope: CALENDAR_SCOPE,
  });

  const onFailure = (err: any) => {
    signOut();
    console.log('LoginError', err);
  };

  const hasCalendarScope = (scope: string) => {
    return scope.includes('https://www.googleapis.com/auth/calendar');
  };

  const verifyGoogleAuthToken = async () => {
    try {
      await verifyGoogleToken(user?.email);
      setTokenVerified(true);
    } catch (err: any) {
      switch (err.response.status) {
        case 404:
          setAlert({
            message: `This email address ${user?.email}, does not seem to be found`,
            variant: 'error',
          });
          setTokenVerified(false);
          break;

        case 400:
        case 500:
          setAlert({
            message: 'An error has occured while verifing Google token',
            variant: 'error',
          });
          setTokenVerified(false);
          break;

        case 403:
          setAlert({
            message: 'Please renew your Google Calendar access.',
            variant: 'error',
          });
          setTokenVerified(false);
          break;
      }
    }
  };

  const updatePicture = (profile_picture: string) => {
    if (profile_picture && user) {
      window.localStorage.setItem('profilePicture', profile_picture);
      //set profile picture in existing object
      setUser({
        ...user,
        profile_picture,
      });
    }
  };

  const onSuccess = async (response: any) => {
    if (hasCalendarScope(response.scope)) {
      setIsFetchingUser(true);

      if (isFeatureFlagEnabled('v2_calendar_api_86bwgxewd')) {
        let email = null;

        if (!isFeatureFlagEnabled('full_calendar_integration_85ztm5z7h')) {
          email = user?.email;
        }
        await createCalendarProvider(email, response.code, 'google')
          .then((res) => {
            updatePicture(res.data.data.links.provider_picture);
          })
          .catch(console.log)
          .finally(() => setIsFetchingUser(false));
      } else {
        await getAccessTokenForGoogleAuthentication(response.code)
          .then((res) => {
            updatePicture(res.data.picture);
          })
          .catch(console.log)
          .finally(() => setIsFetchingUser(false));
      }
    } else {
      setAlert({
        message: 'Please renew your Google Calendar access.',
        variant: 'error',
      });
    }
  };

  return { signIn, tokenVerified };
}
