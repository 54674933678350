import React, { useCallback } from 'react';
import { meetingType, activeElement } from 'utils/enums';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';
import useStartMeeting from 'hooks/useStartMeeting/useStartMeeting';
import classes from './MobileWelcomeScreenComponent.module.scss';
import BigMeetingIcon from 'icons/BigMeetingIcon';
import BigPlusIcon from 'icons/BigPlusIcon';
import BigCalendarIcon from 'icons/BigCalendarIcon';
import BigRecordingIcon from 'icons/BigRecordingIcon';
import { JoinMeetingRoute, RecordingRoute, ScheduleMeetingRoute } from 'utils/constants';
import MobileUpcomingMeetingsComponent from 'MobileComponents/MobileUpcomingMeetingsComponent/MobileUpcomingMeetingsComponent';
import { useAuthActions } from 'hooks/useAuthActions/useAuthActions';

const types = Object.values(meetingType);

const getIcon = (type: meetingType) => {
  switch (type) {
    case meetingType.New:
      return <BigMeetingIcon isHovered={true} />;
    case meetingType.Join:
      return <BigPlusIcon isHovered={true} />;
    case meetingType.Schedule:
      return <BigCalendarIcon isHovered={true} />;
    case meetingType.Recording:
      return <BigRecordingIcon isHovered={true} />;
    default:
      return null;
  }
};

const getMeetingName = (type: meetingType, isUserPresent: boolean) => {
  switch (type) {
    case meetingType.New:
      return 'New Meeting';
    case meetingType.Join:
      return isUserPresent ? 'Join Meeting' : 'Join as Guest';
    case meetingType.Schedule:
      return 'Schedule Meeting';
    case meetingType.Recording:
      return 'View Recordings';
    default:
      return '';
  }
};

export default function MobileWelcomeScreenComponent() {
  const { setActiveTab, user } = useAppState();
  const startMeeting = useStartMeeting();
  const navigate = useNavigate();
  const { authSignIn } = useAuthActions();

  const buttonClick = useCallback(
    (type: meetingType) => {
      switch (type) {
        case meetingType.New:
          startMeeting();
          setActiveTab(activeElement.Video);
          break;
        case meetingType.Join:
          navigate(JoinMeetingRoute);
          setActiveTab(activeElement.Plus);
          break;
        case meetingType.Schedule:
          navigate(ScheduleMeetingRoute);
          setActiveTab(activeElement.Calender);
          break;
        case meetingType.Recording:
          navigate(RecordingRoute);
          setActiveTab(activeElement.Recording);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startMeeting, navigate, setActiveTab]
  );

  const visibleTypes = user ? types : [meetingType.Join];

  return (
    <div
      className={classes.container}
      style={{
        marginTop: user ? '1em' : '2em',
      }}
    >
      <div className={classes.main}>
        <div className={classes.cardMain}>
          {visibleTypes.map((type, index) =>
            index % 2 === 0 ? (
              <div className={classes.cardContainer} key={`container-${index}`}>
                <div className={classes.card} key={type}>
                  <span onClick={() => buttonClick(type)} className={classes.icon}>
                    {getIcon(type)}
                  </span>
                  <span className={classes.mobileText}>{getMeetingName(type, Boolean(user))}</span>
                </div>
                {visibleTypes[index + 1] && (
                  <div className={classes.card} key={visibleTypes[index + 1]} style={{ marginRight: '0em' }}>
                    <span onClick={() => buttonClick(visibleTypes[index + 1])} className={classes.icon}>
                      {getIcon(visibleTypes[index + 1])}
                    </span>
                    <span className={classes.mobileText}>{getMeetingName(visibleTypes[index + 1], Boolean(user))}</span>
                  </div>
                )}
              </div>
            ) : null
          )}
        </div>
        {!user && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span className={classes.label}>
              Are you a{' '}
              <a target="_blank" rel="noreferrer" className={classes.link} href={'https://www.builder.ai'}>
                Builder.ai
              </a>{' '}
              employee?
            </span>{' '}
            <span
              onClick={() => {
                authSignIn();
              }}
              className={classes.signIn}
            >
              Sign In
            </span>
          </div>
        )}{' '}
      </div>
      {user && (
        <div className={classes.upcomingMeetingSection}>
          <MobileUpcomingMeetingsComponent isHomeScreen />
        </div>
      )}
    </div>
  );
}
