import React from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

export default function RecordingSideBarIcon() {
  const {activeTab} = useAppState()
  const isActive = activeTab === activeElement.Recording;
  const color = isActive ? '#F8F9FA' : '#AFB2C0';
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill={color} fillOpacity="0.1" stroke={color} />
      <rect x="3" y="3" width="8" height="8" rx="4" fill={color} />
    </svg>
  );
}
