import React from 'react';
import { Slider } from '@mui/material';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';

interface IFeedbackRatingProps {
  setRating: any;
}

function FeedbackRating({ setRating }: IFeedbackRatingProps) {
  const { isMobile } = useDeviceType();

  const marks = [
    {
      value: 0,
      label: 'Unbearable',
    },
    {
      value: isMobile ? 30 : 25,
      label: 'Mildly Annoying',
    },
    {
      value: 50,
      label: 'Neutral',
    },
    {
      value: isMobile ? 70 : 75,
      label: 'Satisfactory',
    },
    {
      value: 100,
      label: 'Delightful',
    },
  ];

  return (
    <Slider
      aria-label="Restricted values"
      defaultValue={50}
      onChange={(e: any) => {
        setRating(e.target?.value);
      }}
      step={null}
      marks={marks}
    />
  );
}

export default FeedbackRating;
