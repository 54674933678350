import React from 'react';
import Video from 'twilio-video';
import CustomDialog from 'shared/CustomDialog/CustomDialog';
import { DIALOG_BOXES } from 'shared/CustomDialog/customDialogConstants';
import classes from './UnsupportedBrowserWarning.module.scss';

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  if (!Video.isSupported) {
    return (
      <div className={classes.dialogContainer}>
        <CustomDialog
          open={true}
          title={'Browser Not Supported'}
          onClose={() => {}}
          dialogBoxName={DIALOG_BOXES.BROWSER_SUPPORT_ALERT}
        />
      </div>
    );
  }

  return children;
}
