import React, { useMemo, useCallback, useState } from 'react';
import classes from './MobileMeetingCard.module.scss';
import CustomButton from 'shared/CustomButton/CustomButton';
import VerticalDotsIcon from 'icons/VerticalDotsIcon';
import CopyLinkIcon from 'icons/CopyLinkIcon';
import { Tooltip } from '@mui/material';
import { IScheduleMeeting } from 'types';
import useTruncateText from 'hooks/useTruncateText/useTruncateText';
import { MAX_MEETING_NAME_IN_UI, convertDateIntoTime, getMobileDate } from 'utils/constants';
import { meetingDays } from 'utils/enums';
import useCopyLink from 'hooks/useCopyLink/useCopyLink';
import useNavigateToMeeting from 'hooks/useNavigateToMeeting/useNavigateToMeeting';
import { useNavigate } from 'react-router-dom';
import { useAppState } from 'state';
import ThreeDotsMenu from './ThreeDotsMenu';
interface IMobileMeetingCardProps {
  meetingDetails: IScheduleMeeting;
  index: number;
  activeTab: number;
}

function MobileMeetingCard({ meetingDetails, index, activeTab }: IMobileMeetingCardProps) {
  const navigate = useNavigate();
  const navigateToMeeting = useNavigateToMeeting();
  const [meetingName, isNeedToAddTooltip] = useTruncateText(meetingDetails.name ?? '', MAX_MEETING_NAME_IN_UI);

  const { setDialog, user } = useAppState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const meetingLink = useMemo(() => meetingDetails.meeting_link, [meetingDetails]);
  const copyLink = useCopyLink({ meetingLink });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onStartClick = useCallback(
    (meetingDetails: IScheduleMeeting) => {
      setDialog(null);
      navigateToMeeting(meetingDetails.meeting_link);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  return (
    <>
      <ThreeDotsMenu
        meetingDetails={meetingDetails}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        activeTab={activeTab}
      />

      <div className={classes.card} style={{ marginTop: index === 0 ? '0em' : '0.5em' }}>
        <div className={classes.meetingNameMain}>
          <Tooltip arrow title={isNeedToAddTooltip ? meetingDetails.name : ''}>
            <span className={classes.title}>{meetingName}</span>
          </Tooltip>
          <span onClick={handleClick} className={classes.dots}>
            <VerticalDotsIcon />
          </span>
        </div>

        <div className={classes.meetingTime}>
          <span className={classes.meetingTimeMargin}>
            {meetingDetails.meetingDay === meetingDays.Today ? 'Today' : getMobileDate(meetingDetails.start_date)}
          </span>
          <span className={classes.meetingTimeMargin}>|</span>
          <span>{convertDateIntoTime(meetingDetails.start_date)}</span>
        </div>

        <div className={classes.hostContainer}>
          <div style={{ fontSize: '12px' }}>
            <span className={classes.hostlabel}>Host: </span>
            <span>{meetingDetails.host_name}</span>
          </div>

          <div className={classes.hostContainer}>
            <span style={{ display: 'flex', cursor: 'pointer' }} onClick={copyLink}>
              <CopyLinkIcon />
            </span>
            {(activeTab === 0 || (user && meetingDetails.participants_list.includes(user.email))) && (
              <CustomButton
                buttonName={meetingDetails.joinButtonName}
                onClick={() => onStartClick(meetingDetails)}
                btnClass={`${classes.btnClass} ${meetingDetails.host_name !== 'You' && classes.joinButton}`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileMeetingCard;
