import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppState } from 'state';

interface ReCAPTCHAComponentProps {
  setIsCaptchaVerified: (isCaptchaVerified: boolean) => void;
  reCaptchaClass: string;
}

export default function ReCAPTCHAComponent({ setIsCaptchaVerified, reCaptchaClass }: ReCAPTCHAComponentProps) {
  const { isDarkTheme } = useAppState();

  const onChange = (value: string | null) => {
    setIsCaptchaVerified(!!value);
  };

  const onExpired = () => {
    setIsCaptchaVerified(false);
  };

  return (
    <div className={reCaptchaClass}>
      <ReCAPTCHA
        theme={isDarkTheme ? 'dark' : 'light'}
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
        onChange={onChange}
        onExpired={onExpired}
        size="compact"
      />
    </div>
  );
}
