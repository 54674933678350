import { AxiosRequestHeaders } from 'axios';
import MeetAPIServiceBase from './MeetAPIServiceBase';

export default class MeetAPIServiceV2 extends MeetAPIServiceBase {
  API_VERSION: string = 'v2';
  MEET_BACKEND_URL: string = `${process.env.REACT_APP_MEET_BACKEND_URL}/${this.API_VERSION}`;

  axiosHeader = {
    'Content-Type': 'application/vnd.api+json',
  };

  private requestHeaders(email: string | null | undefined): AxiosRequestHeaders {
    return {
      Authorization: `Bearer ${this.apiToken(email)}`,
      ...this.axiosHeader
    };
  }

  public createCalendarProvider = async (email: string | null | undefined, authorization_code: string, provider: string) => {
    const URL = `${this.MEET_BACKEND_URL}/calendar_providers`;
    const data = {
      data: {
        type: 'calendar_providers',
        attributes: {
          provider,
          authorization_code
        }
      }
    };
    return this.axiosInstance.post(URL, data, { headers: this.requestHeaders(email) });
  };

  public fetchUserPreferences = async (email: string | null | undefined) => {
    const URL = `${this.MEET_BACKEND_URL}/user`;
    return this.axiosInstance.get(URL, { headers: this.requestHeaders(email) });
  };

  public saveUserPreferences = async (email: string | null | undefined, userPreferences: any) => {
    const URL = `${this.MEET_BACKEND_URL}/user`;
    return this.axiosInstance.put(URL, userPreferences, { headers: this.requestHeaders(email) });
  };

  public getUserUploadedBackgroundImage = async (email: string | null | undefined) => {
    const URL = `${this.MEET_BACKEND_URL}/background_images`;
    return this.axiosInstance.get(URL, { headers: this.requestHeaders(email) });
  };

  public setUserUploadedBackgroundImage = async (data: any, email: string | null | undefined) => {
    const URL = `${this.MEET_BACKEND_URL}/background_images`;
    return this.axiosInstance.post(URL, data, { headers: this.requestHeaders(email) });
  };
}

export const {
  createCalendarProvider,
  fetchUserPreferences,
  saveUserPreferences,
  getUserUploadedBackgroundImage,
  setUserUploadedBackgroundImage,
} = new MeetAPIServiceV2();
