import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DIALOG_BOXES } from 'shared/CustomDialog/customDialogConstants';
import { useAppState } from 'state';
import { FeedbackRoute } from 'utils/constants';

const useMeetingEnded = () => {
  const navigate = useNavigate();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const {
    setDialog,
    isDisconnected,
    isRemovedByHost,
    setIsNeedToShowBlankScreen,
    setIsDisconnected,
    setIsRemovedByHost,
  } = useAppState();

  useEffect(() => {
    // This will be called when user disconnects from the meeting
    if (isRemovedByHost || isDisconnected) {
      if (isFeatureFlagEnabled('leave_meeting_86bw7y4gy')) {
        setIsDisconnected(false);
        setIsRemovedByHost(false);
        setIsNeedToShowBlankScreen(false);
        navigate(FeedbackRoute, { state: { link: window.location.href } });
      } else {
        setIsNeedToShowBlankScreen(true);
        setDialog({
          dialogBoxName: isRemovedByHost ? DIALOG_BOXES.REMOVED_BY_HOST : DIALOG_BOXES.MEETING_DISCONNECT_TEXT,
          title: 'Thanks for using Builder Meet',
          onClose: () => {
            setIsDisconnected(false);
            setIsRemovedByHost(false);
            setIsNeedToShowBlankScreen(false);
            navigate('/');
          },
          onSubmitFeedback: () => {
            setIsDisconnected(false);
            setIsRemovedByHost(false);
            setIsNeedToShowBlankScreen(false);
            navigate(FeedbackRoute, { state: { link: window.location.href } });
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected, isRemovedByHost]);
};

export default useMeetingEnded;
