import { useCallback } from 'react';
import { useAppState } from 'state';
import { copyRecordingSuccessfulMessage, copySuccessfulMessage, copyUnSuccessfulMessage } from 'utils/constants';

export default function useCopyLink(props?: { meetingLink: string; isRecordingLink?: boolean }) {
  const { meetingDetails, setAlert } = useAppState();

  //When user tries to copy meeting link from dashboard we need to take meeting link from props
  const meetingLinkToBeCopy = props ? props.meetingLink : meetingDetails?.meetingLink;

  const copyLink = useCallback(() => {
    navigator.clipboard
      .writeText(meetingLinkToBeCopy)
      .then(() =>
        setAlert({
          variant: 'success',
          message: props?.isRecordingLink ? copyRecordingSuccessfulMessage : copySuccessfulMessage,
        })
      )
      .catch(() =>
        setAlert({
          variant: 'error',
          message: copyUnSuccessfulMessage,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingLinkToBeCopy]);

  return copyLink;
}
