import React from 'react';

function RecordingWhiteboardIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.06667 4.53333C5.06667 4.82789 4.82789 5.06667 4.53333 5.06667C4.23878 5.06667 4 4.82789 4 4.53333C4 4.23878 4.23878 4 4.53333 4C4.82789 4 5.06667 4.23878 5.06667 4.53333Z"
        fill="white"
      />
      <path
        d="M8.53333 4.53333C8.53333 4.82789 8.29455 5.06667 8 5.06667C7.70545 5.06667 7.46667 4.82789 7.46667 4.53333C7.46667 4.23878 7.70545 4 8 4C8.29455 4 8.53333 4.23878 8.53333 4.53333Z"
        fill="white"
      />
      <path
        d="M12 4.53333C12 4.82789 11.7612 5.06667 11.4667 5.06667C11.1721 5.06667 10.9333 4.82789 10.9333 4.53333C10.9333 4.23878 11.1721 4 11.4667 4C11.7612 4 12 4.23878 12 4.53333Z"
        fill="white"
      />
      <path
        d="M5.06667 8C5.06667 8.29455 4.82789 8.53333 4.53333 8.53333C4.23878 8.53333 4 8.29455 4 8C4 7.70545 4.23878 7.46667 4.53333 7.46667C4.82789 7.46667 5.06667 7.70545 5.06667 8Z"
        fill="white"
      />
      <path
        d="M8.53333 8C8.53333 8.29455 8.29455 8.53333 8 8.53333C7.70545 8.53333 7.46667 8.29455 7.46667 8C7.46667 7.70545 7.70545 7.46667 8 7.46667C8.29455 7.46667 8.53333 7.70545 8.53333 8Z"
        fill="white"
      />
      <path
        d="M12 8C12 8.29455 11.7612 8.53333 11.4667 8.53333C11.1721 8.53333 10.9333 8.29455 10.9333 8C10.9333 7.70545 11.1721 7.46667 11.4667 7.46667C11.7612 7.46667 12 7.70545 12 8Z"
        fill="white"
      />
      <path
        d="M5.06667 11.4667C5.06667 11.7612 4.82789 12 4.53333 12C4.23878 12 4 11.7612 4 11.4667C4 11.1721 4.23878 10.9333 4.53333 10.9333C4.82789 10.9333 5.06667 11.1721 5.06667 11.4667Z"
        fill="white"
      />
      <path
        d="M8.53333 11.4667C8.53333 11.7612 8.29455 12 8 12C7.70545 12 7.46667 11.7612 7.46667 11.4667C7.46667 11.1721 7.70545 10.9333 8 10.9333C8.29455 10.9333 8.53333 11.1721 8.53333 11.4667Z"
        fill="white"
      />
      <path
        d="M12 11.4667C12 11.7612 11.7612 12 11.4667 12C11.1721 12 10.9333 11.7612 10.9333 11.4667C10.9333 11.1721 11.1721 10.9333 11.4667 10.9333C11.7612 10.9333 12 11.1721 12 11.4667Z"
        fill="white"
      />
      <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="3.25" stroke="white" strokeWidth="1.5" />
    </svg>
  );
}

export default RecordingWhiteboardIcon;
