import React from 'react';

function LinkIcon() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.24268 12.3335L6.62135 12.9549C5.44977 14.1264 3.55026 14.1264 2.37868 12.9549C1.2071 11.7833 1.2071 9.88378 2.37868 8.7122L5.56068 5.52953C6.6671 4.42061 8.44044 4.34963 9.63195 5.36655C10.8235 6.38348 11.032 8.14596 10.1107 9.41286"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8789 3.71072L11.3789 3.21072C12.5505 2.03914 14.45 2.03914 15.6216 3.21072C16.7931 4.3823 16.7931 6.2818 15.6216 7.45338L12.4396 10.6354C11.3329 11.7438 9.55981 11.8144 8.3686 10.7974C7.17739 9.78038 6.96903 8.01814 7.89022 6.75138"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LinkIcon;
