import React, { PropsWithChildren, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitle, Dialog } from '@mui/material';
import classes from './DelegationListDialog.module.scss';
import { useAppState } from 'state';
import { getInitials } from 'utils/constants';
import { IDelegate } from 'components/DelegationDialog/DelegationDialog';
import CloseIcon from '@mui/icons-material/Close';
import { fetchOwners } from 'service/MeetAPIServiceV1';

interface DelegationListDialogProps {
  onClose(): void;
  open: boolean;
}

export default function DelegationListDialog({ onClose, open }: PropsWithChildren<DelegationListDialogProps>) {
  const { isDarkTheme } = useAppState();
  const { user } = useAppState();
  const [ownerList, setOwnerList] = useState<IDelegate[]>([]);

  useEffect(() => {
    if (open) {
      getOwners();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getOwners = () => {
    fetchOwners(user?.email ?? '')
      .then((res) => {
        const userList = res.data.users;
        setOwnerList(userList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown={true}
        open={open}
        onClose={(_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
          reason !== 'backdropClick' && onClose();
        }}
        fullWidth={true}
        maxWidth="xs"
        PaperProps={{ className: classes.paper }}
        id={isDarkTheme ? 'darkTheme' : 'lightTheme'}
      >
        <DialogTitle style={{ padding: '16px 24px 5px 24px' }}>
          <div className={classes.dialogTitle}>
            <div style={{ visibility: 'collapse' }}></div>
            <div className={classes.text}>
              <span className={classes.title}>You have delegation rights for</span>
            </div>
            <div className={classes.close} onClick={onClose}>
              <CloseIcon sx={{ fontSize: '15px' }} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {ownerList.length > 0 ? (
            ownerList.map((delegates) => {
              return (
                <div
                  key={delegates.email}
                  style={{
                    display: 'flex',
                    width: '50%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '1em',
                  }}
                >
                  <div className={classes.menuItem}>
                    <div className={classes.initial}>{getInitials(delegates.name)}</div>
                    <div className={classes.participantDetail}>
                      <div className={classes.name}>{delegates.name}</div>
                      <div>
                        <span className={classes.email}>{delegates.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1em' }}>
              {' '}
              You have not yet assigned any delegate to your account
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
