import React, { useState } from 'react';
import classes from './RecordingAttachments.module.scss';
import useCopyLink from 'hooks/useCopyLink/useCopyLink';
import { IRecordingsData } from 'types';
import { useAppState } from 'state';
import VerticalDotsIcon from 'icons/VerticalDotsIcon';
import { Menu, MenuItem } from '@mui/material';
import LinkIcon from 'icons/LinkIcon';
import OpenReordingIcon from 'icons/OpenReordingIcon';
import RecordingWhiteboardIcon from 'icons/RecordingWhiteboardIcon';
import { useRecordingStore } from 'store/recordingStore';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';

interface RecordingAttachmentsProps {
  recordingData: IRecordingsData;
}

function RecordingAttachments({ recordingData }: RecordingAttachmentsProps) {
  const { isMobile } = useDeviceType();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setRecordingMetaData } = useRecordingStore((state) => ({
    setRecordingMetaData: state.setRecordingMetaData,
  }));

  const { user } = useAppState();
  const copyLink = useCopyLink({ meetingLink: recordingData.media_uri, isRecordingLink: true });

  const onWhiteBoardClick = (isRecordingClick: boolean) => {
    setRecordingMetaData({
      name: recordingData.name || `${user?.name}'s meeting`,
      time: recordingData.start_date,
      duration: recordingData.duration,
      whiteboard_link: `${recordingData.whiteboard_url}?email=${user?.email}&url=${window.location.href}`,
      recording_link: recordingData.media_uri,
      isRecordingClick: isRecordingClick,
    });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: '15px' } }}
      >
        <MenuItem
          onClick={() => {
            copyLink();
          }}
        >
          <span className={classes.icons}>
            <LinkIcon />
          </span>
          Copy Recording Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            onWhiteBoardClick(true);
          }}
        >
          <span className={classes.icons}>
            {' '}
            <OpenReordingIcon />
          </span>
          Open Recording
        </MenuItem>
        {!isMobile && recordingData.whiteboard_url && (
          <MenuItem
            onClick={() => {
              onWhiteBoardClick(false);
            }}
          >
            <span className={classes.icons} style={{ marginLeft: '0.1em' }}>
              <RecordingWhiteboardIcon />
            </span>
            Open Whiteboard
          </MenuItem>
        )}
      </Menu>

      <div
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <span className={classes.threeDota}>
          {' '}
          <VerticalDotsIcon isRecordingUI />
        </span>
      </div>
    </>
  );
}

export default RecordingAttachments;
