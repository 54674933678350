import React, { useEffect, useState } from 'react';
import classes from './MobileRecordingComponent.module.scss';
import { useNavigate } from 'react-router-dom';
import BuilderGIFComponent from 'components/PreJoinComponents/BuilderGIFComponent/BuilderGIFComponent';
import LeftArrow from 'icons/LeftArrow';
import moment from 'moment';
import { useAppState } from 'state';
import { HomeRoute, convertStringIntoDate } from 'utils/constants';
import { IRecordingsData } from 'types';
import MobileRecordingCard from './MobileRecordingCard';
import MobileRecordingSearchContainer from './MobileRecordingSearchContainer';
import CustomButton from 'shared/CustomButton/CustomButton';
import { useRecordingStore } from 'store/recordingStore';
import RecordingMetaDataView from 'components/PreJoinComponents/RecordingLinksComponent/RecordingMetaDataView';
import { fetchRecordings } from 'service/MeetAPIServiceV1';

function MobileRecordingComponent() {
  const navigate = useNavigate();
  const { user, setAlert } = useAppState();
  const [recordingsData, setRecordingsData] = useState<IRecordingsData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [searchDate, setSearchDate] = useState<Date | null>(null);
  const [isFetchingRecording, setIsFetchingRecording] = useState(false);
  const { recordingMetaData, setRecordingMetaData } = useRecordingStore((state) => ({
    setRecordingMetaData: state.setRecordingMetaData,
    recordingMetaData: state.recordingMetaData,
  }));

  useEffect(() => {
    if (user) {
      getRecordings(user.email, 1, null, null); //On Initial Fetch call search value would be null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!searchValue && !searchDate) {
      if (user) {
        getRecordings(user.email, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchDate, user]);

  const showMore = () => {
    if (totalPages !== currentPage) {
      setCurrentPage(currentPage + 1);
      const date = searchDate && moment(searchDate).format('DD-MM-yyyy');
      if (user) {
        getRecordings(user.email, currentPage + 1, searchValue, date);
      }
    }
  };

  const getRecordings = (email: string, page: number, meetingName?: string | null, date?: string | null) => {
    setIsFetchingRecording(true);
    fetchRecordings(email, page, meetingName, date)
      .then((res) => {
        const fetchedData = res.data.recordings as IRecordingsData[];
        const allRecordingsData = page === 1 ? fetchedData : [...recordingsData, ...fetchedData];
        allRecordingsData.forEach((data) => {
          data.start_date = convertStringIntoDate(data.scheduled_time ? data.scheduled_time : data.actual_time);
        });
        setRecordingsData(allRecordingsData);
        setTotalPages(res.data.meta.total_pages);
      })
      .catch(() => {
        setAlert({
          message: 'Error while getting recordings data',
          variant: 'error',
          isTwilioError: true,
        });
      })
      .finally(() => {
        setIsFetchingRecording(false);
      });
  };

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{ display: 'flex', marginRight: '0.5em', marginLeft: '-0.5em' }}
            onClick={() => {
              if (recordingMetaData) {
                setRecordingMetaData(null);
              } else {
                navigate(HomeRoute);
              }
            }}
          >
            <LeftArrow />
          </span>
          <span>Recordings</span>
        </div>
      </div>

      {recordingMetaData ? (
        <RecordingMetaDataView />
      ) : (
        <>
          <MobileRecordingSearchContainer
            getRecordings={getRecordings}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchDate={searchDate}
            setSearchDate={setSearchDate}
            setCurrentPage={setCurrentPage}
            setRecordingsData={setRecordingsData}
          />

          <div className={classes.meetingCardContainer}>
            {recordingsData.length === 0 ? (
              <div className={classes.noMeeting}>No recordings available</div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  paddingBottom: '0em',
                }}
              >
                <div className={`${classes.cardParent} ${isFetchingRecording && classes.recordingClass}`}>
                  {isFetchingRecording ? (
                    <BuilderGIFComponent heigth={20} width={20} />
                  ) : (
                    recordingsData.map((recording, index) => {
                      return <MobileRecordingCard key={recording.id} index={index} recordingData={recording} />;
                    })
                  )}
                </div>
                <CustomButton
                  btnClass={classes.btnClass}
                  onClick={showMore}
                  buttonName="Show More"
                  isDisabled={currentPage === totalPages}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MobileRecordingComponent;
