import React from 'react';

function VerticalDotsIcon(props: { isRecordingUI?: boolean }) {
  const { isRecordingUI } = props;
  const color = isRecordingUI ? 'black' : 'white';

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26769 11.3567C6.95117 11.3567 7.50523 10.8026 7.50523 10.1191C7.50523 9.43566 6.95117 8.88159 6.26769 8.88159C5.58422 8.88159 5.03015 9.43566 5.03015 10.1191C5.03015 10.8026 5.58422 11.3567 6.26769 11.3567Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26781 3.22435C6.95129 3.22435 7.50535 2.67028 7.50535 1.98681C7.50535 1.30333 6.95129 0.749268 6.26781 0.749268C5.58434 0.749268 5.03027 1.30333 5.03027 1.98681C5.03027 2.67028 5.58434 3.22435 6.26781 3.22435Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26769 7.29051C6.95117 7.29051 7.50523 6.73644 7.50523 6.05297C7.50523 5.3695 6.95117 4.81543 6.26769 4.81543C5.58422 4.81543 5.03015 5.3695 5.03015 6.05297C5.03015 6.73644 5.58422 7.29051 6.26769 7.29051Z"
        fill={color}
      />
    </svg>
  );
}

export default VerticalDotsIcon;
