import React, { useState } from 'react';
import CustomButton from 'shared/CustomButton/CustomButton';
import ReCAPTCHAComponent from 'shared/ReCAPTCHAComponent/ReCAPTCHAComponent';
import { useAppState } from 'state';
import OtpInput from 'react-otp-input';
import { Six, VERIFIED_EMAILS } from 'utils/constants';
import classes from './OTPComponent.module.scss';
import { validateGuestUser } from 'service/MeetAPIServiceV1';

export default function OTPComponent() {
  const { setAlert, setIsOTPVerified, user, isOTPSent, setIsOTPSent } = useAppState();

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [otpResponse, setOTPResponse] = useState('');
  const [otp, setOtp] = useState('');
  const [remainingSeconds, setRemainingSeconds] = useState(150);

  const sendOTP = () => {
    //API Call for OTP
    if (user) {
      validateGuestUser(user.email)
        .then((res) => {
          const encodedOTP = res.data.otp;
          const decodedOTP = window.atob(encodedOTP);
          setOTPResponse(decodedOTP);
          setIsOTPSent(true);
        })
        .catch(() => {
          setAlert({
            variant: 'error',
            message: 'Error while sending OTP',
          });
        });
      setIsOTPSent(true);
      setRemainingSeconds(150);
      setOTPResponse('654321');
      startTimer();
    }
  };

  const verifyOTP = () => {
    if (otp === otpResponse) {
      setEmailInLocalStorage();
      setIsOTPVerified(true);
      setAlert({
        variant: 'success',
        message: 'OTP has been verified',
      });
    } else {
      setAlert({
        variant: 'error',
        message: 'Please enter valid OTP',
      });
    }
  };

  const startTimer = () => {
    let seconds = 150;
    //Need to enable resend button after 2 Min & 30 Sec
    const timer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(timer);
      } else {
        seconds -= 1;
        setRemainingSeconds(seconds);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  };

  //Need to have verified users list so that they cannot see this screen 2nd time
  const setEmailInLocalStorage = () => {
    if (user) {
      const verifiedList = window.localStorage.getItem(VERIFIED_EMAILS);
      if (verifiedList) {
        const emails = JSON.parse(verifiedList);
        emails.push(user.email);
        window.localStorage.setItem(VERIFIED_EMAILS, JSON.stringify(emails));
      } else {
        window.localStorage.setItem(VERIFIED_EMAILS, JSON.stringify([user.email]));
      }
    }
  };

  return (
    <div className={classes.main}>
      <span data-testid="title">Authorize your email to view your meetings</span>

      <div className={classes.container}>
        {!isOTPSent ? (
          <div className={classes.captcha}>
            <ReCAPTCHAComponent reCaptchaClass={classes.reCaptcha} setIsCaptchaVerified={setIsCaptchaVerified} />
            <CustomButton
              testid="send-otp"
              btnClass={`${classes.btnClass} ${classes.button}`}
              buttonName="Send OTP to E-Mail"
              onClick={sendOTP}
              isDisabled={!isCaptchaVerified}
            />
          </div>
        ) : (
          <div className={classes.otpField}>
            <OtpInput
              data-testid="otp-field"
              value={otp}
              onChange={(otp: any) => setOtp(otp)}
              numInputs={6}
              renderSeparator={<span>-</span>}
              inputType="number"
              shouldAutoFocus
              inputStyle={classes.optField}
              renderInput={(props) => <input {...props} />}
            />
            <div className={classes.buttons}>
              <CustomButton
                testid="verifyOTP"
                btnClass={`${classes.btnClass}`}
                buttonName="Verify OTP"
                onClick={verifyOTP}
                isDisabled={otp.length !== Six}
              />
              {remainingSeconds === 0 ? (
                <CustomButton
                  btnClass={`${classes.btnClass} ${classes.resendButton}`}
                  buttonName="Resend OTP"
                  onClick={sendOTP}
                />
              ) : (
                <span className={classes.seconds}>{`${'Resend OTP in'} ${remainingSeconds} Seconds`}</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
