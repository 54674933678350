import React, { createContext, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Conversation, Client, Message, State } from '@twilio/conversations';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useAppState } from 'state';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider = (props: {
  children: boolean | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
  const { room, onError } = useVideoContext();
  const { setIsFetchingConversation, isDisconnected } = useAppState();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();
  const [isChatClientInitialized, setIsChatClientInitialized] = useState(false);

  const connect = useCallback(
    (token: string) => {
      try {
        const client = new Client(token);
        setChatClient(client);
      } catch (err) {
        setIsFetchingConversation(false);
        console.log('conversation service error', err);
        isChatWindowOpen &&
          onError(new Error("There was a problem connecting to Twilio's conversation service."), 'ConversationError');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onError, isChatWindowOpen]
  );

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) => setMessages((oldMessages) => [...oldMessages, message]);
      conversation.getMessages().then((newMessages: any) => setMessages(newMessages.items));
      conversation.on('messageAdded', handleMessageAdded);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
      };
    }
  }, [conversation]);

  useEffect(() => {
    if (chatClient) {
      const onClientInitialized = (state: State) => {
        setIsChatClientInitialized(state === 'initialized');
      };

      chatClient.on('stateChanged', (state: State) => {
        onClientInitialized(state);
      });

      return () => {
        chatClient.off('stateChanged', (state: State) => {
          onClientInitialized(state);
        });
      };
    }
  }, [chatClient]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (room.sid && chatClient && isChatClientInitialized && !isDisconnected) {
      chatClient
        .getConversationByUniqueName(room.sid)
        .then((newConversation: Conversation) => {
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch((err: any) => {
          console.log('conversation error', err);
          setChatClient(undefined);
          isChatWindowOpen &&
            onError(
              new Error('There was a problem getting the Conversation associated with this room.'),
              'ConversationError'
            );
        })
        .finally(() => {
          setIsFetchingConversation(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, chatClient, isDisconnected, isChatWindowOpen, isChatClientInitialized]);

  const contextValue = useMemo(() => {
    return {
      isChatWindowOpen,
      setIsChatWindowOpen,
      connect,
      hasUnreadMessages,
      messages,
      conversation,
    };
  }, [isChatWindowOpen, setIsChatWindowOpen, connect, hasUnreadMessages, messages, conversation]);

  return <ChatContext.Provider value={contextValue}>{props.children}</ChatContext.Provider>;
};
