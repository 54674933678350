import React from 'react';
import { TextField } from '@mui/material';
import { useAppState } from 'state';
import CustomButton from 'shared/CustomButton/CustomButton';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classes from './RecordingSearchContainer.module.scss';
import { IRecordingsData } from 'types';

interface RecordingSearchContainerProps {
  getRecordings: (email: string, page: number, meetingName?: string | null, date?: string | null) => void;
  searchValue: string | null;
  setSearchValue: (searchValue: string | null) => void;
  searchDate: Date | null;
  setSearchDate: (searchDate: Date | null) => void;
  setCurrentPage: (currentPage: number) => void;
  setRecordingsData: (recordingsData: IRecordingsData[]) => void;
}

function RecordingSearchContainer({
  getRecordings,
  searchValue,
  setSearchValue,
  searchDate,
  setSearchDate,
  setCurrentPage,
  setRecordingsData,
}: RecordingSearchContainerProps) {
  const { user } = useAppState();

  const searchRecordings = () => {
    const date = searchDate && moment(searchDate).format('DD-MM-yyyy');
    setCurrentPage(1); //Reset Current Page
    setRecordingsData([]); // Reset Recordings Data
    if (user) {
      getRecordings(user?.email, 1, searchValue, date);
    }
  };

  return (
    <>
      <div className={classes.searchContainer}>
        <div style={{ display: 'flex' }}>
          <span className={classes.meetingTitle}>Search By Name: </span>
          <TextField
            InputProps={{ className: classes.inputField }}
            placeholder={`${user?.name}'s Meeting`}
            autoCapitalize="false"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            inputProps={{ maxLength: 25, autoComplete: 'off' }}
          />

          <span className={classes.meetingTime}>Search By Date: </span>
          <DatePicker
            className={`${classes.date}`}
            dateFormat="dd-MMM-yyyy"
            placeholderText="01-Jan-2023"
            selected={searchDate}
            maxDate={new Date()}
            onChange={(date: Date) => {
              setSearchDate(date);
            }}
          />
        </div>
        <CustomButton
          btnClass={classes.btnClass}
          isDisabled={!(searchDate || searchValue)}
          buttonName="Search"
          onClick={searchRecordings}
        />
      </div>
    </>
  );
}

export default RecordingSearchContainer;
