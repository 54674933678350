import React, { PropsWithChildren, useState, useCallback, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomButton from 'shared/CustomButton/CustomButton';
import { TextField } from '@mui/material';
import { useAppState } from 'state';
import { MAX_MEETING_PASSCODE, MAX_MEETING_ID, MIN_MEETING_PASSCODE } from 'utils/constants';
import useJoinPSTNMeeting from 'hooks/useJoinPSTNMeeting/useJoinPSTNMeeting';
import classes from './JoinMeetingDialogContent.module.scss';
import { validateEmail } from 'utils';

interface JoinScreenDialogContentProps {
  onClose(): void;
}

export default function JoinScreenDialogContent({ onClose }: PropsWithChildren<JoinScreenDialogContentProps>) {
  const { user, isBuilderUser, setAlert } = useAppState();
  const [meetingId, setMeetingId] = useState('');
  const [passcode, setPasscode] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setUserEmail(user.email);
    }
  }, [user]);

  const onJoin = useJoinPSTNMeeting(meetingId, passcode, userName, userEmail);

  const onJoinClick = () => {
    if (validateEmail(userEmail)) {
      onJoin();
    } else {
      setAlert({
        message: 'Please enter valid email',
        variant: 'error',
      });
    }
  };

  const formatMeetingIDOrPassCode = useCallback((value: any, trimmedIndex: number) => {
    if (!value) return value;
    const digitsOnly = value.replace(/[^\d]/g, '');
    return digitsOnly.slice(0, trimmedIndex);
  }, []);

  const onMeetingIDChange = useCallback(
    (event: any) => {
      const formattedID = formatMeetingIDOrPassCode(event.target.value, MAX_MEETING_ID);
      setMeetingId(formattedID);
    },
    [formatMeetingIDOrPassCode]
  );

  const onPassCodeChange = useCallback(
    (event: any) => {
      const formattedPassCode = formatMeetingIDOrPassCode(event.target.value, MAX_MEETING_PASSCODE);
      setPasscode(formattedPassCode);
    },
    [formatMeetingIDOrPassCode]
  );

  return (
    <>
      <DialogContent>
        <div>
          <TextField
            InputProps={{ className: classes.inputField }}
            disabled={false}
            autoFocus
            placeholder="Enter Meeting ID"
            variant="outlined"
            fullWidth
            size="small"
            value={meetingId}
            onChange={(event) => {
              onMeetingIDChange(event);
            }}
            className={classes.textField}
            inputProps={{ autoComplete: 'off' }}
          />
        </div>

        <div style={{ marginTop: '1.5em' }}>
          <TextField
            InputProps={{ className: classes.inputField }}
            disabled={false}
            placeholder="Enter Passcode"
            variant="outlined"
            fullWidth
            size="small"
            value={passcode}
            onChange={(event) => {
              onPassCodeChange(event);
            }}
            className={classes.textField}
            inputProps={{ autoComplete: 'off' }}
          />
        </div>

        <div style={{ marginTop: '1.5em' }}>
          <TextField
            InputProps={{ className: classes.inputField }}
            disabled={user ? true : false}
            placeholder="Enter Name"
            variant="outlined"
            fullWidth
            size="small"
            value={userName}
            inputProps={{ maxLength: 50, autoComplete: 'off' }}
            onChange={(event) => {
              setUserName(event.target.value.trim());
            }}
            className={classes.textField}
          />
        </div>

        <div style={{ marginTop: '1.5em' }}>
          <TextField
            InputProps={{ className: classes.inputField }}
            disabled={user ? true : false}
            placeholder="Enter Email"
            variant="outlined"
            fullWidth
            size="small"
            value={userEmail}
            inputProps={{ autoComplete: 'off' }}
            onChange={(event) => {
              setUserEmail(event.target.value.trim());
            }}
            className={classes.textField}
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.actionDialog}>
        {/* <div>
          <Button
            variant="contained"
            onClick={signIn}
            disabled={user ? true : false}
            className={`${classes.button} ${classes.googleButton}`}
          >
            Sign-In
          </Button>
        </div> */}
        <div>
          <CustomButton buttonName={'Cancel'} onClick={onClose} btnClass={classes.cancelButton} />
          <CustomButton
            buttonName={isBuilderUser ? 'Join' : 'Join as Guest'}
            onClick={onJoinClick}
            btnClass={classes.joinButton}
            isDisabled={
              !userName || !userEmail || meetingId.length !== MAX_MEETING_ID || passcode.length < MIN_MEETING_PASSCODE
            }
          />
        </div>
      </DialogActions>
    </>
  );
}
