import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IScheduleMeeting } from 'types';
import { DIALOG_BOXES, dialogActionClasses } from './customDialogConstants';
import DialogTitleComponent from './DialogTitleComponent';
import DialogContentComponent from './DialogContentComponent';
import DialogActionsComponent from './DialogActionsComponent';
import classes from './CustomDialog.module.scss';
import CustomButton from 'shared/CustomButton/CustomButton';
interface CustomDialogProps {
  open: boolean;
  onClose(): void;
  onLeaveMeeting?(): void | null;
  onLeave?(): void | null;
  title: string;
  meetingTime?: Date;
  meetingSeriesEnd?: string;
  onUnmute?(): void;
  onCloseMeeting?(): void;
  onContinueMeeting?(): void;
  dialogBoxName: string;
  meetingDetails?: IScheduleMeeting;
  onCancel?(): void | null;
  isCancelSeriesClick?: boolean;
  onSubmitFeedback?(): void;
}

export default function CustomDialog({
  open,
  onClose,
  onLeaveMeeting,
  onLeave,
  title,
  meetingTime,
  meetingSeriesEnd,
  onUnmute,
  onCloseMeeting,
  onContinueMeeting,
  dialogBoxName,
  meetingDetails,
  onCancel,
  isCancelSeriesClick,
  onSubmitFeedback,
}: PropsWithChildren<CustomDialogProps>) {
  const handleDialogClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={handleDialogClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{ className: classes.paper }}
    >
      <DialogTitle>
        <DialogTitleComponent title={title} onClose={onClose} dialogBoxName={dialogBoxName} />
      </DialogTitle>
      {dialogBoxName !== DIALOG_BOXES.REMOVED_BY_HOST && dialogBoxName !== DIALOG_BOXES.MEETING_DISCONNECT_TEXT ? (
        <>
          <DialogContent>
            <DialogContentComponent
              dialogBoxName={dialogBoxName}
              meetingDetails={meetingDetails}
              isCancelSeriesClick={isCancelSeriesClick}
              meetingTime={meetingTime}
              meetingSeriesEnd={meetingSeriesEnd}
            />
          </DialogContent>

          <DialogActions
            className={dialogActionClasses(dialogBoxName, classes.dialogActionClass, classes.actionDialog)}
          >
            <DialogActionsComponent
              dialogBoxName={dialogBoxName}
              onLeaveMeeting={onLeaveMeeting}
              onLeave={onLeave}
              onUnmute={onUnmute}
              onCloseMeeting={onCloseMeeting}
              onContinueMeeting={onContinueMeeting}
              onCancel={onCancel}
              onClose={onClose}
            />
          </DialogActions>
        </>
      ) : (
        <DialogActions className={classes.action}>
          <CustomButton btnClass={classes.backBtnClass} buttonName={'Back to Home'} onClick={onClose} />
          {/* <a target="_blank" href={FEEDBACK_FORM} rel="noreferrer"> */}
          <CustomButton
            btnClass={classes.btnClass}
            buttonName={'Submit Feedback'}
            onClick={() => {
              onSubmitFeedback && onSubmitFeedback();
            }}
          />
          {/* </a> */}
        </DialogActions>
      )}
    </Dialog>
  );
}
