import React, { useEffect, useState } from 'react';
import CustomButton from 'shared/CustomButton/CustomButton';
import ScheduleIcon from 'icons/ScheduleIcon';
import { useAppState } from 'state';
import { IScheduleMeeting } from 'types';
import { UpcomingMeetingRoute, VERIFIED_EMAILS } from 'utils/constants';
import OTPComponent from 'components/PreJoinComponents/OTPComponent/OTPComponent';
import { useNavigate } from 'react-router-dom';
import useGetSchdeulesMeetingData from 'hooks/useGetSchedulesMeetingsData/useGetSchedulesMeetingsData';
import { useLocalStorageState } from 'hooks/useLocalStorageState/useLocalStorageState';
import BuilderGIFComponent from '../BuilderGIFComponent/BuilderGIFComponent';
import classes from './UpcomingMeetingsComponent.module.scss';
import MobileMeetingCard from 'MobileComponents/MobileMeetingCard/MobileMeetingCard';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import moment from 'moment';
import useDelegateMeetingData from 'hooks/useDelegateMeetingsData/useDelegateMeetingsData';
require('moment/locale/en-gb');

export default function UpcomingMeetingsComponent() {
  const navigate = useNavigate();
  const [verifiedList] = useLocalStorageState<string[]>(VERIFIED_EMAILS, []);
  const { user, isGuestUser, isOTPVerified, setIsOTPVerified } = useAppState();
  const { scheduledMeetings, isLoading } = useGetSchdeulesMeetingData(30);
  const { delegateMeetings } = useDelegateMeetingData();
  const [radioValue, setRadioValue] = useState('today');
  const [filteredMeeting, setFilteredMeeting] = useState<IScheduleMeeting[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    const today = moment().startOf('day');
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    const meeting = activeTab === 0 ? scheduledMeetings : delegateMeetings;
    switch (radioValue) {
      case 'today':
        const filteredToday = meeting.filter((item) => moment(item.start_date).isSame(today, 'day'));
        setFilteredMeeting(filteredToday);
        break;
      case 'week':
        const filteredThisWeek = meeting.filter((item) =>
          moment(item.start_date).isBetween(startOfWeek, endOfWeek, undefined, '[]')
        );
        setFilteredMeeting(filteredThisWeek);
        break;
      case 'month':
        const filteredThisMonth = meeting.filter((item) =>
          moment(item.start_date).isBetween(startOfMonth, endOfMonth, undefined, '[]')
        );
        setFilteredMeeting(filteredThisMonth);
        break;
    }
  }, [radioValue, scheduledMeetings, activeTab, delegateMeetings]);

  //Need to check if OTP is verified earlier or not
  useEffect(() => {
    if (user && isGuestUser) {
      if (verifiedList) {
        setIsOTPVerified(verifiedList.includes(user.email));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isGuestUser]);

  const renderLoader = () => <BuilderGIFComponent heigth={15} width={25} />;

  const renderOTPComponent = () => <OTPComponent />;

  const renderMeetings = () => (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <div className={classes.day}>
        <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
          <span
            onClick={() => setActiveTab(0)}
            className={`${classes.tabClass} ${activeTab === 0 && classes.activeTab}`}
            style={{ marginRight: '2em' }}
          >
            My Meetings
          </span>
          <span
            onClick={() => setActiveTab(1)}
            className={`${classes.tabClass} ${activeTab === 1 && classes.activeTab}`}
          >
            Delegated Meetings
          </span>
        </div>
      </div>

      <RadioGroup className={classes.radioGrp} value={radioValue} onChange={handleChange}>
        <FormControlLabel value="today" control={<Radio />} label="Today" />
        <FormControlLabel value="week" control={<Radio />} label="This Week" />
        <FormControlLabel value="month" control={<Radio />} label="This Month" />
      </RadioGroup>

      <div className={classes.root}>
      {filteredMeeting.length === 0
        ? renderNoMeetings()
        : filteredMeeting.map((meetingDetails: IScheduleMeeting, index: number) => (
            <MobileMeetingCard
              activeTab={activeTab}
              key={`${meetingDetails.id}_${index}`}
              meetingDetails={meetingDetails}
              index={index}
            />
          ))}
      </div>
    </div>
  );

  const renderNoMeetings = () => <div className={classes.noMeetings}>No upcoming meetings</div>;

  const renderComponent = () => {
    if (isLoading) {
      return renderLoader();
    } else if (isGuestUser && !isOTPVerified) {
      return renderOTPComponent();
    } else if (scheduledMeetings.length > 0) {
      return renderMeetings();
    } else {
      return renderNoMeetings();
    }
  };

  const onClick = () => {
    navigate(UpcomingMeetingRoute);
  };

  return (
    <div className={`${classes.main}`}>
      <div className={classes.content}>
        <div className={`${classes.header}`}>
          <span className={classes.scheduleIcon}>
            <ScheduleIcon />
          </span>
          <span>Upcoming meetings</span>
        </div>
        {user ? renderComponent() : renderNoMeetings()}
      </div>
      <div className={`${classes.footer}`}>
        <CustomButton buttonName="View All Meetings" btnClass={classes.btnClass} onClick={onClick} />
      </div>
    </div>
  );
}
