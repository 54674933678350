import React from 'react';

export default function BigRecordingIcon({ isHovered }: { isHovered: boolean }) {
  const fillColor = isHovered ? '#6200EA' : 'none';
  const strokeColor = isHovered ? 'white' : '#B388FF';
  const rectStrokeColor = isHovered ? '#6200EA' : '#7C4DFF';
  const innerFillColor = isHovered ? 'white' : '#B388FF';

  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.999939" width="59" height="59" rx="29.5" fill={fillColor} stroke={rectStrokeColor} />
      <g clipPath="url(#clip0_3453_2044)">
        <rect x="19.5" y="19.9999" width="21" height="21" rx="10.5" stroke={strokeColor} />
        <rect x="24.5" y="24.9999" width="11" height="11" rx="5.5" fill={innerFillColor} />
      </g>
      <defs>
        <clipPath id="clip0_3453_2044">
          <rect width="22" height="22" fill="white" transform="translate(19 19.4999)" />
        </clipPath>
      </defs>
    </svg>
  );
}
