import React from 'react';

export default function BigPlusIcon({ isHovered }: { isHovered: boolean }) {
  const fillColor = isHovered ? '#6200EA' : 'none';
  const strokeColor = isHovered ? 'white' : '#B388FF';
  const rectStrokeColor = isHovered ? '#6200EA' : '#7C4DFF';

  return (
    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="59" height="59" rx="29.5" fill={fillColor} stroke={rectStrokeColor} />
      <g clipPath="url(#clip0_3453_2015)">
        <path
          d="M30.4995 25.9541V35.045"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M35.045 30.4995H25.9541"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M30.4995 40.4995C36.0224 40.4995 40.4995 36.0224 40.4995 30.4995C40.4995 24.9767 36.0224 20.4995 30.4995 20.4995C24.9767 20.4995 20.4995 24.9767 20.4995 30.4995C20.4995 36.0224 24.9767 40.4995 30.4995 40.4995Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_3453_2015">
          <rect width="21.8182" height="21.8182" fill="white" transform="translate(19.5904 19.5904)" />
        </clipPath>
      </defs>
    </svg>
  );
}
