import axios from 'axios';
import { User } from "oidc-client-ts"

export default class MeetAPIServiceBase {
  API_VERSION: string = "v1";
  MEET_BACKEND_URL = `${process.env.REACT_APP_MEET_BACKEND_URL}/${this.API_VERSION}`;
  OIDC_STORAGE_KEY = `oidc.user:${process.env.REACT_APP_IDP_HOST}:${process.env.REACT_APP_IDP_CLIENT_ID}`
  
  axiosInstance = axios.create({
    baseURL: this.MEET_BACKEND_URL,
    headers: {
      AppToken: process.env.REACT_APP_APP_TOKEN as string,
    },
  });


  /**
   * Retrieves the access token of the currently logged in
   * user if available, otherwise returns `null`. If however
   * an email address is provided, it will return this. This
   * is for legacy support of the old email address based API
   * authentication
   */
  apiToken(email: string | null | undefined): string | null {
    if (email) return email;

    const oidcStorage = localStorage.getItem(this.OIDC_STORAGE_KEY);
    if (!oidcStorage) {
      return null;
    }
    
    const user = User.fromStorageString(oidcStorage)
    return user?.access_token;
  }

}
