import { useCallback } from 'react';
import moment from 'moment';
import { useAppState } from 'state';

const useTimeValidation = () => {
  const { setAlert } = useAppState();

  const timeValidation = useCallback(
    (time: string) => {
      const timeMoment = moment(time, 'hh:mm', true);
      const isTimeValid = timeMoment.isValid();

      if (!isTimeValid) {
        setAlert({
          variant: 'error',
          message: 'Please Enter Valid Start Time',
        });
        return false;
      }

      return true;
    },
    [setAlert]
  );

  return timeValidation;
};

export default useTimeValidation;
