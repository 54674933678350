import React from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

export default function MobileHomeIcon() {
  const { activeTab } = useAppState();
  const isActive = activeTab === activeElement.Home;

  return isActive ? (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4196_5013)">
        <path
          d="M9 1.5L1.5 7.5V17.25H6.75V12H11.25V17.25H16.5V7.5L9 1.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_4196_5013">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1508 7.71024L9.99984 1.56024L1.84134 7.71024C1.67268 7.86588 1.57126 8.08109 1.55859 8.31024V17.7017C1.55983 18.1063 1.88751 18.434 2.29209 18.4352H7.18359V14.5C7.18359 12.9467 8.44279 11.6875 9.99609 11.6875C11.5494 11.6875 12.8086 12.9467 12.8086 14.5V18.4375H17.6993C18.1041 18.4363 18.4319 18.1087 18.4336 17.704V8.31024C18.4206 8.08117 18.3192 7.86607 18.1508 7.71024Z"
        stroke="#AFB2C0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
