import React, { PropsWithChildren } from 'react';
import { DIALOG_BOXES } from './customDialogConstants';
import CustomButton from 'shared/CustomButton/CustomButton';
import classes from './DialogActionsComponent.module.scss';

interface DialogActionsComponentProps {
  dialogBoxName: string;
  onUnmute?(): void;
  onCloseMeeting?(): void;
  onContinueMeeting?(): void;
  onCancel?(): void | null;
  onLeaveMeeting?(): void | null;
  onLeave?(): void | null;
  onClose(): void;
}

export default function DialogActionsComponent({
  dialogBoxName,
  onLeaveMeeting,
  onLeave,
  onUnmute,
  onCloseMeeting,
  onContinueMeeting,
  onCancel,
  onClose,
}: PropsWithChildren<DialogActionsComponentProps>) {
  const buttons = {
    [DIALOG_BOXES.DROP_EVERYONE]: [
      {
        buttonName: 'End For All',
        onClick: onCloseMeeting,
      },
      {
        buttonName: 'Keep It Open',
        onClick: onContinueMeeting,
      },
    ],
    [DIALOG_BOXES.HOST_ASKED_TO_UNMUTE]: [
      {
        buttonName: 'Unmute',
        onClick: () => {
          onUnmute && onUnmute();
          onClose();
        },
      },
      {
        buttonName: 'Stay Muted',
        onClick: onClose,
      },
    ],
    [DIALOG_BOXES.RECORDING]: [
      {
        buttonName: 'Leave',
        onClick: onLeaveMeeting,
      },
      {
        buttonName: 'Continue',
        onClick: onClose,
      },
    ],
    [DIALOG_BOXES.LEAVE_MEETING]: [
      {
        buttonName: 'Leave',
        onClick: onLeave,
      },
      {
        buttonName: 'Cancel',
        onClick: onClose,
      },
    ],
    [DIALOG_BOXES.VIRTUAL_BACKGROUND_NOT_SUPPORTED]: [
      {
        buttonName: 'Close',
        onClick: onClose,
      },
    ],
    [DIALOG_BOXES.MEETING_CANCELLATION]: [
      {
        buttonName: 'Close',
        onClick: onClose,
      },
      {
        buttonName: 'Yes',
        onClick: () => {
          onCancel && onCancel();
          onClose();
        },
      },
    ],
  };

  const currentButtons = buttons[dialogBoxName];

  return (
    <>
      {currentButtons?.map((button, index) => (
        <CustomButton
          btnClass={classes.btnClass}
          key={index}
          buttonName={button.buttonName}
          onClick={button.onClick ? button.onClick : () => {}}
        />
      ))}
    </>
  );
}
