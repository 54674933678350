import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { frequencyArray } from 'utils/constants';
import classes from './ScheduleMeetingFrequencyComponent.module.scss';

interface ScheduleMeetingFrequencyComponentProps {
  meeting: any;
  setMeeting: any;
  isFieldDisabled: boolean;
}

function ScheduleMeetingFrequencyComponent({
  isFieldDisabled,
  meeting,
  setMeeting,
}: ScheduleMeetingFrequencyComponentProps) {
  return (
    <Select
      disabled={isFieldDisabled}
      value={meeting.frequency}
      onChange={(e) =>
        setMeeting((prevMeetingData: any) => ({
          ...prevMeetingData,
          frequency: e.target.value as string,
        }))
      }
      disableUnderline
      className={classes.textField}
      variant="filled"
      fullWidth
    >
      {frequencyArray.map((value) => {
        return (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default ScheduleMeetingFrequencyComponent;
