import React from 'react';

function LeftArrow() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 17.5L9.60044 12.2591C9.53613 12.1904 9.5 12.0972 9.5 12C9.5 11.9028 9.53613 11.8096 9.60044 11.7409L14.5 6.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LeftArrow;
