import { TextField, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import AMPMButtonGroup from 'shared/AMPMButtonGroup/AMPMButtonGroup';
import { timeArray } from 'utils/constants';
import classes from './ScheduleMeetingStartTImeComponent.module.scss';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';

interface ScheduleMeetingStartTImeComponentProps {
  meeting: any;
  setMeeting: any;
}

function ScheduleMeetingStartTImeComponent({ meeting, setMeeting }: ScheduleMeetingStartTImeComponentProps) {
  const [anchorElStartTime, setAnchorElStartTime] = useState<null | HTMLElement>(null);
  const { isMobile } = useDeviceType();

  const handleTimeChange = (e: any) => {
    const formattedTime = formatTime(e.target.value);
    setMeeting((prevMeetingData: any) => ({
      ...prevMeetingData,
      startTime: formattedTime,
    }));
  };

  const formatTime = (value: any) => {
    // Return if input is falsy (e.g., user deletes the input)
    if (!value) return value;

    // Clean input by removing non-digit characters
    const time = value.replace(/[^\d]/g, '');

    // Check input length
    const timeLength = time.length;

    // Return unformatted input if it's less than 3 digits
    if (timeLength < 3) return time;

    // Format input by inserting a colon between hour and minute
    if (timeLength >= 3) {
      return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
    }
  };

  const handleClose = () => {
    setAnchorElStartTime(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorElStartTime}
        open={Boolean(anchorElStartTime)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: isMobile ? 'left' : 'center', vertical: 'bottom' }}
        MenuListProps={{ className: classes.menuList }}
        PaperProps={{ style: { marginLeft: isMobile ? '0px' : '-8px' } }}
      >
        {timeArray.map((value) => {
          return (
            <MenuItem
              key={value}
              onClick={() => {
                setMeeting((prevMeetingData: any) => ({
                  ...prevMeetingData,
                  startTime: value,
                }));
                handleClose();
              }}
              className={`${classes.menuItems} ${value === meeting.startTime && classes.buttonBackground}`}
            >
              {value}
            </MenuItem>
          );
        })}
      </Menu>

      <TextField
        className={classes.textField}
        onClick={(event) => setAnchorElStartTime(event.currentTarget)}
        disabled={false}
        placeholder="Start Time"
        variant="outlined"
        size="small"
        value={meeting.startTime}
        onChange={(e) => {
          handleTimeChange(e);
        }}
        InputProps={{
          className: classes.inputField,
          style: { padding: '0.059em 0em' },
          endAdornment: <AMPMButtonGroup meeting={meeting} setMeeting={setMeeting} />,
        }}
      />
    </>
  );
}

export default ScheduleMeetingStartTImeComponent;
