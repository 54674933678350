import React from 'react';
import classes from './DelegatorList.module.scss';
import { getInitials } from 'utils/constants';
import { IDelegate } from 'components/DelegationDialog/DelegationDialog';
import { useAppState } from 'state';
import { removeDelegate } from 'service/MeetAPIServiceV1';

function DelegatorList(props: { delagtorListName: IDelegate; setDelagtorListName: any; setIsRemoveClicked: any }) {
  const { delagtorListName, setDelagtorListName, setIsRemoveClicked } = props;
  const { user, setAlert } = useAppState();

  const onRemove = () => {
    removeDelegate(user?.email ?? '')
      .then((res) => {
        setIsRemoveClicked(true);
        setAlert({
          message: 'Delgate removed successfully',
          variant: 'success',
        });
        setDelagtorListName(undefined);
      })
      .catch((err) => {
        setAlert({
          message: 'Error while removing delegate',
          variant: 'error',
        });
        console.log(err);
      });
  };

  return (
    <div>
      <div className={classes.added}>Added People</div>
      <div className={classes.addedPeople}>
        <div className={classes.menuItem}>
          <div className={classes.initial}>{getInitials(delagtorListName.name)}</div>
          <div className={classes.participantDetail}>
            <div className={classes.name}>{delagtorListName.name}</div>
            <div>
              <span className={classes.email}>{delagtorListName.email}</span>
            </div>
          </div>
        </div>
        <div onClick={onRemove} className={classes.remove}>
          Remove
        </div>
      </div>
    </div>
  );
}

export default DelegatorList;
