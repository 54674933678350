import { useState, useEffect } from 'react';
import { getScheduleMeetingsData } from 'service/MeetAPIServiceV1';
import { useAppState } from 'state';
import { IScheduleMeeting } from 'types';
import { setStartDateAndMeetingDay } from 'utils/constants';

export default function useGetSchdeulesMeetingData(lookup_window: number) {
  const { user, isBuilderUser } = useAppState();
  const [scheduledMeetings, setScheduledMeetings] = useState<IScheduleMeeting[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [todaysDate, setTodaysDate] = useState(new Date());

  useEffect(() => {
    if (user) {
      const fetchMeetingData = async () => {
        setIsLoading(true);
        try {
          const res = await getScheduleMeetingsData(user.email, lookup_window);
          const meetingData = res.data.data as IScheduleMeeting[];

          meetingData.forEach((meetingDetails: IScheduleMeeting) => {
            setStartDateAndMeetingDay(meetingDetails);
            meetingDetails.joinButtonName =
              meetingDetails.host_name === 'You' ? 'Start' : isBuilderUser ? 'Join' : 'Join as guest';
          });

          //Need to sort array based on date & time
          meetingData.sort((a, b) => a.start_date.getTime() - b.start_date.getTime());
          setScheduledMeetings(meetingData);
          setTodaysDate(new Date());
        } catch (err) {
          console.log('fetch error for schedule meeting', err);
        } finally {
          setIsLoading(false);
        }
      };

      fetchMeetingData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookup_window, user]);

  return { scheduledMeetings, isLoading, todaysDate };
}
