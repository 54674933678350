import React from 'react';

export default function RecordingIcon(props: { isBottomBar?: boolean; isRecording?: boolean }) {
  return props.isBottomBar ? (
    <svg
      data-testid="recording"
      width="23"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="12" height="12" rx="7.5" fill="white" fillOpacity="0.2" stroke="white" />
      <rect x="5" y="5" width="6" height="6" rx="4" fill="white" />
    </svg>
  ) : (
    <svg
      data-testid="recording"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="13"
        height="13"
        rx="6.5"
        fill={props.isRecording ? '#FF1744' : 'gray'}
        fillOpacity="0.1"
        stroke={props.isRecording ? '#FF1744' : 'gray'}
      />
      <rect x="3" y="3" width="8" height="8" rx="4" fill={props.isRecording ? '#FF1744' : 'gray'} />
    </svg>
  );
}
