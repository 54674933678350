import React from 'react';
import { IScheduleMeeting } from 'types';
import MeetingCardContent from './MeetingCardContent';
import classes from './MeetingCardComponent.module.scss';

interface MeetingCardComponentProps {
  meetingDetails: IScheduleMeeting;
  isDialog?: boolean;
  activeTab: number;
}

export default function MeetingCardComponent({ meetingDetails, isDialog, activeTab }: MeetingCardComponentProps) {
  return (
    <>
      <div key={meetingDetails.id} className={`${classes.card} ${isDialog && classes.dialog}`}>
        <MeetingCardContent meetingDetails={meetingDetails} activeTab={activeTab} />
      </div>
    </>
  );
}
