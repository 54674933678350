import connectAudio from 'assets/audio/enter.mp3';
import disConnectAudio from 'assets/audio/exit.mp3';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some((device) => device.kind === 'videoinput'),
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: string) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name: name as PermissionName });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export const playSound = (isConnectAudio: boolean) => {
  const audio = new Audio(isConnectAudio ? connectAudio : disConnectAudio);
  audio.volume = 0.06;
  audio.autoplay = true;
  audio.play();
};

export const getEmailDomain = (email: string) => {
  const parts = email.split('@');
  if (parts.length < 2) return '';
  return parts[parts.length - 1].toLowerCase();
};

export const isBuilderAIUser = (email: string) => {
  const emailDomain = getEmailDomain(email);
  return emailDomain === 'builder.ai' || emailDomain === 'x.builder.ai';
};

export const validateEmail = (email: string): boolean => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};
