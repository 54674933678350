import React, { PropsWithChildren, useEffect, useState } from 'react';
import CustomAlert from 'shared/CustomAlert/CustomAlert';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import useRoomState from 'hooks/useRoomState/useRoomState';
import {
  chromeSupportLink,
  firefoxSupportLink,
  isChromeBrowser,
  isFirefoxBrowser,
  isSafariBrowser,
  safariSupportLink,
} from 'utils/constants';
import CustomErrorDialog from 'components/PreJoinScreens/CustomErrorDialog';
import { IError } from 'types';
import useChatContext from 'hooks/useChatContext/useChatContext';
import { useLogs } from 'hooks/useLogs/useLogs';

interface ErrorDialogProps {
  dismissError: Function;
  error: IError | null;
}

export default function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error?.error || {};
  const { room } = useVideoContext();
  const roomState = useRoomState();
  const { setIsChatWindowOpen } = useChatContext();
  const saveLogs = useLogs();

  const [isNeedToShowScreenShareDialog, setIsNeedToShowScreenShareDialog] = useState(false);

  useEffect(() => {
    if (code && (code >= 53000 || code <= 53669)) {
      dismissError();
    } else {
      if (message) {
        // Use regular expressions to extract the track ID
        const trackIdPattern = /\[(LocalVideoTrack|LocalAudioTrack) #\d+: ([a-f0-9-]+)\]/;
        const match = message.match(trackIdPattern);

        if (message === 'LocalParticipant disconnected' || match) {
          dismissError();
        }
      }
    }
  }, [code, message, dismissError]);

  useEffect(() => {
    if (error && room) {
      if (error.type === 'screenshare' && error?.error.name === 'NotAllowedError') {
        setIsNeedToShowScreenShareDialog(true);
      }

      if (error.type === undefined || error.type === 'disconnect') {
        saveLogs(error.error?.message, 'Other', error.type === 'disconnect');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const enhanceMessage = (message = '', code?: number) => {
    if (code === 20101) {
      return message + '. Please make sure you are using the correct credentials.';
    } else {
      return message;
    }
  };

  const enhancedMessage = enhanceMessage(message, code);
  const isNeedToDisplayLink =
    roomState === 'disconnected' &&
    (isChromeBrowser || isFirefoxBrowser || isSafariBrowser) &&
    (message === 'No microphone permission – so no one will be able to hear you!' ||
      message === 'No camera permission – so no one will be able to see you!');

  let link = chromeSupportLink;
  if (isFirefoxBrowser) {
    link = firefoxSupportLink;
  } else if (isSafariBrowser) {
    link = safariSupportLink;
  }

  return (
    <>
      <CustomErrorDialog
        open={isNeedToShowScreenShareDialog}
        onClose={() => {
          setIsNeedToShowScreenShareDialog(false);
          dismissError();
        }}
        title="ScreenSharing Error"
        isScreenSharingError={true}
      />

      <CustomAlert
        variant="error"
        open={error !== null && !isNeedToShowScreenShareDialog}
        onClose={() => {
          error?.type === 'ConversationError' && setIsChatWindowOpen(false);
          dismissError();
        }}
        message={enhancedMessage}
        isTwilioError={true}
        isNeedToDisplayLink={isNeedToDisplayLink}
        link={link}
      />
    </>
  );
}
