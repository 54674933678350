import React, { useState, useRef, useEffect, useCallback, ChangeEvent, MouseEvent } from 'react';

import { Chip, Box, InputBase, Popper, List, ListItem } from '@mui/material';
import classes from './MultiEmailComponent.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import { validateEmail } from 'utils';
import { fetchBuilderUsers } from 'service/MeetAPIServiceV1';

interface MultiEmailComponentProps {
  meeting?: any;
  setMeeting?: any;
  setInviteEmails?: any;
  setInvalidEmailExist: any;
}

function MultiEmailComponent({ meeting, setMeeting, setInviteEmails, setInvalidEmailExist }: MultiEmailComponentProps) {
  const [builderUserEmails, setBuilderUserEmails] = useState<string[]>([]);
  const [emailSuggestions, setEmailSuggestions] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>(meeting ? meeting.emails : []);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [errorEmails, setErrorEmails] = useState<string[]>([]);
  const { isMobile } = useDeviceType();
  const inputRef = useRef<HTMLInputElement>(null);

  const fetchEmails = useCallback(() => {
    fetchBuilderUsers()
      .then((res: any) => {
        const users = res.data.users;
        const emailList = users.map((user: any) => user.email);
        setBuilderUserEmails(emailList);
      })
      .catch((err) => {
        console.log('fetch builder user error', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  useEffect(() => {
    setInvalidEmailExist(errorEmails.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEmails]);

  useEffect(() => {
    if (setMeeting) {
      setMeeting((prevMeetingData: any) => ({
        ...prevMeetingData,
        emails: emails,
      }));
    }

    if (setInviteEmails) {
      setInviteEmails(emails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, isMobile]);

  const handleDelete = (chipToDelete: string) => () => {
    setEmails((emails) => emails.filter((email) => email !== chipToDelete));
    setErrorEmails((errorEmails) => errorEmails.filter((email) => email !== chipToDelete));
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentEmail(value.trim());
    if (value.length >= 3) {
      setEmailSuggestions(builderUserEmails.filter((email) => email.includes(value.toLowerCase())));
    } else {
      setEmailSuggestions([]);
    }
  };

  const handleEmailSelect = (email: string) => {
    setEmails((prevEmails) => [...prevEmails, email]);
    if (!validateEmail(email)) {
      setErrorEmails((prevErrorEmails) => [...prevErrorEmails, email]);
    }
    setCurrentEmail('');
    setEmailSuggestions([]);
  };

  const handleSuggestionClick = (event: MouseEvent<HTMLLIElement>) => {
    const suggestion = event.currentTarget.textContent;
    if (suggestion) {
      handleEmailSelect(suggestion);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      const suggestion = emailSuggestions[0];
      if (suggestion) {
        handleEmailSelect(suggestion);
      } else if (currentEmail !== '') {
        handleEmailSelect(currentEmail);
      }
    }
  };

  return (
    <>
      <Box className={classes.box}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {emails.map((email) => (
            <Chip
              key={email}
              label={email}
              onDelete={handleDelete(email)}
              className={`${classes.chip} ${errorEmails.includes(email) && classes.errorChip}`}
              size="small"
              deleteIcon={<CloseIcon className={classes.closeButton} style={{ color: '#FFF', fontSize: '12px' }} />}
            />
          ))}
          <InputBase
            placeholder="Participants"
            value={currentEmail}
            onChange={handleOnChange}
            inputRef={inputRef}
            className={classes.inputBase}
            id="schedule-meet"
            onKeyDown={handleKeyDown} // Add the onKeyDown event handler
          />
        </Box>
        <Popper
          placement="bottom-start"
          open={!!emailSuggestions.length}
          anchorEl={inputRef.current}
          className={classes.popper}
        >
          <List>
            {emailSuggestions.map((suggestion) => (
              <ListItem key={suggestion} onClick={handleSuggestionClick} className={classes.listItem}>
                {suggestion}
              </ListItem>
            ))}
          </List>
        </Popper>
      </Box>
      {errorEmails.length > 0 && <p className={classes.errorText}>{'*Please enter a valid email'}</p>}
    </>
  );
}

export default MultiEmailComponent;
