import React, { PropsWithChildren } from 'react';
import RecordingIcon from 'icons/RecordingIcon';
import { useAppState } from 'state';
import { DIALOG_BOXES, showCloseButton } from './customDialogConstants';
import classes from './DialogTitleComponent.module.scss';

interface DialogTitleComponentProps {
  title: string;
  dialogBoxName: string;
  onClose(): void;
}

export default function DialogTitleComponent({
  title,
  dialogBoxName,
  onClose,
}: PropsWithChildren<DialogTitleComponentProps>) {
  const { is360 } = useAppState();

  return (
    <div className={classes.dialogTitle}>
      <div></div>
      <div className={classes.text}>
        {dialogBoxName === DIALOG_BOXES.RECORDING && (
          <span className={classes.recordingIcon}>
            <RecordingIcon isRecording={true} />
          </span>
        )}
        <span className={classes.title}>{title}</span>
      </div>
      <div data-testid="close" onClick={onClose} className={classes.close}>
        {showCloseButton(dialogBoxName, is360) && 'x'}
      </div>
    </div>
  );
}
