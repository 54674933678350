import React from 'react';

export default function BigCalendarIcon({ isHovered }: { isHovered: boolean }) {
  const fillColor = isHovered ? '#6200EA' : 'none';
  const strokeColor = isHovered ? 'white' : '#B388FF';
  const rectStrokeColor = isHovered ? '#6200EA' : '#7C4DFF';

  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.999939" width="59" height="59" rx="29.5" fill={fillColor} stroke={rectStrokeColor} />
      <g clipPath="url(#clip0_3453_2028)">
        <path
          d="M30 35.9999V30.4999"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M32.75 33.2499H27.25"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M38.25 22.2499H21.75C20.7375 22.2499 19.9167 23.0708 19.9167 24.0833V37.8333C19.9167 38.8458 20.7375 39.6666 21.75 39.6666H38.25C39.2625 39.6666 40.0834 38.8458 40.0834 37.8333V24.0833C40.0834 23.0708 39.2625 22.2499 38.25 22.2499Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M24.5 20.4166V23.1666"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M35.5 20.4166V23.1666"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M19.9167 26.8333H40.0834"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_3453_2028">
          <rect width="22" height="22" fill="white" transform="translate(19 19.4999)" />
        </clipPath>
      </defs>
    </svg>
  );
}
