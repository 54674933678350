import React from 'react';
import classes from './MobileRecordingCard.module.scss';
import { Tooltip } from '@mui/material';
import { IRecordingsData } from 'types';
import useTruncateText from 'hooks/useTruncateText/useTruncateText';
import { useAppState } from 'state';
import {
  convertDateIntoTime,
  convertSecondsIntoHourAndMinuteForRecordingDuration,
  getMobileDate,
} from 'utils/constants';
import RecordingAttachments from 'components/PreJoinComponents/RecordingLinksComponent/RecordingAttachments';

interface IMobileMeetingCardProps {
  recordingData: IRecordingsData;
  index: number;
}

function MobileRecordingCard({ recordingData, index }: IMobileMeetingCardProps) {
  const { user } = useAppState();
  const [recordingName, isNeedToAddTooltip] = useTruncateText(recordingData.name ?? '', 30);

  return (
    <div className={classes.card} style={{ marginTop: index === 0 ? '0em' : '0.5em' }}>
      <div className={classes.meetingNameMain}>
        <Tooltip arrow title={isNeedToAddTooltip ? recordingData.name : ''}>
          <span className={classes.title}>{recordingName || `${user?.name}'s meeting`}</span>
        </Tooltip>
      </div>

      <div className={classes.meetingTime}>
        <span className={classes.meetingTimeMargin}>{getMobileDate(recordingData.start_date)}</span>
        <span className={classes.meetingTimeMargin}>|</span>
        <span>{convertDateIntoTime(recordingData.start_date)}</span>
      </div>

      <div className={classes.hostContainer}>
        <div style={{ fontSize: '12px' }}>
          <div>
            <span className={classes.hostlabel}>Duration: </span>
            <span>{convertSecondsIntoHourAndMinuteForRecordingDuration(recordingData.duration)}</span>
          </div>

          <div style={{ marginTop: '0.5em' }}>
            <span className={classes.hostlabel}>Size: </span>
            <span>{recordingData.size}</span>
          </div>
        </div>

        <div className={classes.hostContainer}>
          <RecordingAttachments recordingData={recordingData} />
        </div>
      </div>
    </div>
  );
}

export default MobileRecordingCard;
