import { useCallback } from 'react';
import { getRecurringMeetingDataFromID, getScheduleMeetingDataFromID } from 'service/MeetAPIServiceV1';
import { IScheduleMeeting } from 'types';
import { setStartDateAndMeetingDay } from 'utils/constants';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';

const useMeetingData = () => {
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const fetchMeetingData = useCallback(
    async (isRecurringMeeting, id, email, occurrence_id = null) => {
      const apiFunction = isRecurringMeeting ? getRecurringMeetingDataFromID : getScheduleMeetingDataFromID;

      try {
        let res;
        if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
          res = await apiFunction(id as string, email as string, occurrence_id);
        } else {
          res = await apiFunction(id as string, email as string);
        }
        const meetingDetails = res.data as IScheduleMeeting;
        setStartDateAndMeetingDay(meetingDetails);
        return meetingDetails;
      } catch (err) {
        // setDialog({
        //   dialogBoxName: DIALOG_BOXES.NO_MEETINGS_FOUND,
        //   title: 'Alert',
        // });
        return null;
      }
    },
    []
  );

  return { fetchMeetingData };
};

export default useMeetingData;
