import React from 'react';
import { FormControlLabel, Radio, RadioGroup ,Checkbox } from '@mui/material';

interface ScheduleMeetingRadioGroupProps {
  meeting: any;
  setMeeting: any;
  isFieldDisabled: boolean;
}

function ScheduleMeetingRadioGroup({ meeting, setMeeting, isFieldDisabled }: ScheduleMeetingRadioGroupProps) {
  return (
      <FormControlLabel
       label="Recurring"
       id="schedule-meet"
        control={
          <Checkbox
             value={meeting.meetingType}
             sx={{paddingBottom:'2px'}}
              checked = {meeting.meetingType === 'recurring'}
              onChange={(e) => 
                setMeeting((prevMeetingData: any) => ({
                  ...prevMeetingData,
                  meetingType: e.target.checked?'recurring':'single',
                }))
              }
           />      
        } 
      /> 
  );
}

export default ScheduleMeetingRadioGroup;
