import React from 'react';
import { useAppState } from 'state';
import CustomAlert from './CustomAlert';

export default function CustomAlertWrapper() {
  const { alert, setAlert } = useAppState();

  return (
    alert && (
      <CustomAlert
        open={true}
        message={alert.message}
        variant={alert.variant}
        onClose={() => {
          setAlert(null);
        }}
        isTwilioError={alert.isTwilioError}
        invalidEmailError={alert.invalidEmailError}
        isPreferredBrowserWarning={alert.isPreferredBrowserWarning}
      />
    )
  );
}
