import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useLogs } from 'hooks/useLogs/useLogs';

export default function useLocalVideoToggle() {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;
  const [isPublishing, setPublishing] = useState(false);
  const saveLogs = useLogs();

  const toggleVideoEnabled = useCallback((isCameraHidden: boolean | LocalVideoTrack = videoTrack) => {
    if (!isPublishing) {
      if (isCameraHidden) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setPublishing(true);
        getLocalVideoTrack()
          .catch((err) => {
            saveLogs(err.toString(), 'Video');

            //If we need to show red error banner then UnComment this code
            // let msg = 'Browser can’t access your camera!';
            // if (err.name === 'NotAllowedError') {
            //   msg = 'No camera permission – so no one will be able to see you!';
            // } else if (err.name === 'NotReadableError') {
            //   msg =
            //     'Can you check your operating system settings? It’s blocked the browser from accessing your camera.';
            // }
            // onError(new Error(msg), 'video');
          })
          .finally(() => setPublishing(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, removeLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled] as const;
}
