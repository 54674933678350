import React from 'react';
import gif from 'assets/Images/page-loader.gif';
import classes from './BuilderGIFComponent.module.scss';

interface BuilderGIFComponentProps {
  heigth: number;
  width: number;
  isJoinMeetingLoader?: boolean;
}

function BuilderGIFComponent({ heigth, width, isJoinMeetingLoader }: BuilderGIFComponentProps) {
  return (
    <div className={classes.loading}>
      <img height={`${heigth}%`} width={`${width}%`} alt="loading_gif" src={gif} />
      {isJoinMeetingLoader && <span className={classes.roomName}>Getting everything ready. Meanwhile, take a few deep breaths. It's a beautiful day 😇</span>}
      {/* Joining Meeting... */}
    </div>
  );
}

export default BuilderGIFComponent;
