import { createTheme } from '@mui/material';

const lightTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          'html, body, #root': {
            height: '100%',
            '& ::-webkit-scrollbar': {
              width: '0.4em',
              height: '0.4em',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: 'lightgray',
              borderRadius: '10px',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
          color: 'white',
          fontFamily: `'Rubik', sans-serif`,
          fontWeight: 'bold',
        },
        arrow: {
          color: 'black',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          color: 'black',
          border: '2px solid #DFE0E6',
          position: 'relative',
          background: 'white',
          overflowY: 'auto',
          borderRadius: '4px',
          maxHeight: '300px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: `white !important`,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#83889E',
        },
        colorPrimary: {
          '&$checked': {
            color: '#7C4DFF',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#83889E',
          padding: '0.5em 0em',
        },
        colorPrimary: {
          '&$checked': {
            color: '#7C4DFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          color: '#00da6f',
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: '#00da6f',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
          border: '1px solid #DFE0E6',
          background: `white !important`,
          fontSize: '14px',
          borderRadius: '4px',
          fontFamily: `'Rubik', sans-serif`,
          color: 'black !important',
        },
        input: {
          padding: '0.75em',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '14px',
          fontFamily: `'Rubik', sans-serif`,
          color: 'black',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: `#DFE0E6 !important`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: `'Rubik', sans-serif`,
          '&.Mui-selected': {
            backgroundColor: '#7c4dff !important',
          },
          '&:hover': {
            backgroundColor: '#7c4dff',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: `'Rubik', sans-serif`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#4caf50 !important',          
        }
      },
    }
  },
});

export default lightTheme;
