import { useMemo } from 'react';

function useSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error) {
  const getSnackbarContent = useMemo(() => {
    return () => {
      let message = '';

      switch (true) {
        // This error is emitted when the user or the user's system has denied permission to use the media devices
        case error?.name === 'NotAllowedError' || error?.message === 'NotAllowedError':
          if (error!.message === 'Permission denied by system') {
            // Chrome only
            message =
              'Can you check your operating system settings? It’s blocked the browser from accessing your microphone or camera.';
          } else {
            message = 'Update your camera and microphone permissions – or no one will be able to see or hear you!';
          }

          break;

        // This error is emitted when input devices are not connected or disabled in the OS settings
        case error?.name === 'NotFoundError':
          message = 'Browser can’t access your microphone or camera!';
          break;

        case error?.name === 'NotReadableError':
          message =
            'Can you check your operating system settings? It’s blocked the browser from accessing your microphone or camera.';
          break;

        // Other getUserMedia errors are less likely to happen in this app. Here we will display
        // the system's error message directly to the user.
        case Boolean(error):
          message = `${error!.message}`;
          break;

        case !hasAudio && !hasVideo:
          message = 'Update your camera and microphone permissions – or no one will be able to see or hear you!';
          break;

        case !hasVideo:
          message = 'No camera permission – so no one will be able to see you!';
          break;

        case !hasAudio:
          message = 'No microphone permission – so no one will be able to hear you!';
      }

      return {
        message,
      };
    };
  }, [hasAudio, hasVideo, error]);

  return getSnackbarContent();
}

export default useSnackbarContent;
