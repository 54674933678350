import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import JoinScreenDialogContent from './JoinMeetingDialogContent';
import { useNavigate } from 'react-router-dom';
import classes from './JoinMeetingDialogComponent.module.scss';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';

export default function JoinMeetingDialogComponent() {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason !== 'backdropClick') {
      navigate(-1);
    }
  };

  return (
    <Dialog
      style={{ zIndex: 0 }}
      disableEscapeKeyDown
      open
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{ className: classes.paper }}
      disableEnforceFocus
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          {!isMobile && <div></div>}

          <div className={classes.text}>
            <span className={classes.title}>Join Meeting</span>
          </div>
          <div data-testid="button" onClick={() => navigate('/')} className={classes.close}>
            x
          </div>
        </div>
      </DialogTitle>

      <JoinScreenDialogContent onClose={() => navigate(-1)} />
    </Dialog>
  );
}
