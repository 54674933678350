export const DIALOG_BOXES = {
  RECORDING: 'recording_dialog',
  WAITING_SCREEN: 'waiting_screen_dialog',
  LEAVE_MEETING: 'leave_meeting_dialog',
  BROWSER_SUPPORT_ALERT: 'browser_support_alert_dialog',
  VIRTUAL_BACKGROUND_NOT_SUPPORTED: 'virtual_background_not_supported_dialog',
  SHOW_BUILDER_AI_REPRESENTATIVE: 'show_builder_ai_representative_dialog',
  HOST_ASKED_TO_UNMUTE: 'host_asked_to_unmute_dialog',
  REMOVED_BY_HOST: 'removed_by_host_dialog',
  DROP_EVERYONE: 'drop_everyone_dialog',
  MEETING_CANCELLED: 'meeting_cancelled_dialog',
  MEETING_ENDED: 'meeting_ended_dialog',
  MEETING_CANCELLATION: 'meeting_cancellation_dialog',
  MEETING_LINK_INVALID: 'meeting_link_invalid_dialog',
  END_MEETING: 'end_meeting_dialog',
  MEETING_DETAILS: 'meeting_details_dialog',
  MEETING_DISCONNECT_TEXT: 'meeting_disconnect_text_dialog',
  NO_MEETINGS_FOUND: 'no_meetings_found',
};

export const DIALOG_MESSAGES = {
  RECORDING: 'This meeting is being recorded. Are you happy to continue?',
  LEAVE_MEETING: 'It’s still in progress. Are you sure you want to leave now?',
  BROWSER_SUPPORT: 'Try Google Chrome or Microsoft Edge… Or your background won’t work.',
  BROWSER_NOT_SUPPORTED: 'Please open this application in one of the supported browsers supported browsers',
  SHOW_BUILDER_AI_REPRESENTATIVE: 'Not long now... Your Builder.ai expert will join this call soon',
  HOST_ASKED_TO_UNMUTE: 'We can’t hear you! Can you turn on your audio?',
  REMOVED_BY_HOST: 'Meeting ended. Click on the meeting link to join again.',
  DROP_EVERYONE: 'Just you leaving, or do you want to end the meeting for everyone?',
  MEETING_CANCELLED_FROM_API: 'This meeting has been cancelled',
  MEETING_CANCELLED: {
    SINGLE: 'Are you sure you want to cancel the meeting?',
    MULTIPLE: 'Are you sure you want to cancel all the meetings?',
  },
  MEETING_ENDED: {
    SINGLE: 'This meeting has ended',
    RECURRING: (last_date?: string) => `This meeting series has concluded, and there are no further scheduled occurrences beyond ${last_date}`
  },
  MEETING_LINK_INVALID: 'Please check meeting link and join again',
  END_MEETING: (time: string) =>
    `The meeting had been originally scheduled 
    for the ${time}. 
    It appears you are attempting to access a meeting that was designated for a past date`,
  WAITING: (time: string) => `It’s not started yet.
    Your meeting starts at ${time}`,
  MEETING_DISCONNECT_TEXT: `Thanks for using Builder Meet.
    You can close the tab or use the meeting link to join again`,
  NO_MEETINGS_FOUND: 'Invalid meeting. Please verify and try again.',
  DEFAULT: '',
};

export const DIALOG_ACTION_CLASSES = {
  isEndMeetingDialog: 'dialogActionClass',
  isMeetingLinkValid: 'dialogActionClass',
  isRemovedByHost: 'dialogActionClass',
  isWaitingScreen: 'dialogActionClass',
  browserNotSupportedDialog: 'dialogActionClass',
  isNeedToShowBuilderAIRepresentativeScreen: 'dialogActionClass',
  isMeetingCancelled: 'dialogActionClass',
  viewMeetingDialog: 'dialogActionClass',
  isNeedToShowEndScreen: 'dialogActionClass',
  DEFAULT: 'actionDialog',
};

export const messageDialog = (dialogBoxName: string, date: string, isCancelSeriesClick?: boolean, seriesEnd?: string) => {
  switch (dialogBoxName) {
    case DIALOG_BOXES.RECORDING:
      return DIALOG_MESSAGES.RECORDING;
    case DIALOG_BOXES.WAITING_SCREEN:
      return DIALOG_MESSAGES.WAITING(date);
    case DIALOG_BOXES.LEAVE_MEETING:
      return DIALOG_MESSAGES.LEAVE_MEETING;
    case DIALOG_BOXES.BROWSER_SUPPORT_ALERT:
      return DIALOG_MESSAGES.BROWSER_NOT_SUPPORTED;
    case DIALOG_BOXES.VIRTUAL_BACKGROUND_NOT_SUPPORTED:
      return DIALOG_MESSAGES.BROWSER_SUPPORT;
    case DIALOG_BOXES.SHOW_BUILDER_AI_REPRESENTATIVE:
      return DIALOG_MESSAGES.SHOW_BUILDER_AI_REPRESENTATIVE;
    case DIALOG_BOXES.HOST_ASKED_TO_UNMUTE:
      return DIALOG_MESSAGES.HOST_ASKED_TO_UNMUTE;
    case DIALOG_BOXES.REMOVED_BY_HOST:
      return DIALOG_MESSAGES.REMOVED_BY_HOST;
    case DIALOG_BOXES.DROP_EVERYONE:
      return DIALOG_MESSAGES.DROP_EVERYONE;
    case DIALOG_BOXES.MEETING_CANCELLED:
      return DIALOG_MESSAGES.MEETING_CANCELLED_FROM_API;
    case DIALOG_BOXES.MEETING_ENDED:
      return isCancelSeriesClick
        ? DIALOG_MESSAGES.MEETING_ENDED.RECURRING(seriesEnd)
        : DIALOG_MESSAGES.MEETING_ENDED.SINGLE;
    case DIALOG_BOXES.MEETING_CANCELLATION:
      return isCancelSeriesClick
        ? DIALOG_MESSAGES.MEETING_CANCELLED.MULTIPLE
        : DIALOG_MESSAGES.MEETING_CANCELLED.SINGLE; 
    case DIALOG_BOXES.MEETING_LINK_INVALID:
      return DIALOG_MESSAGES.MEETING_LINK_INVALID;
    case DIALOG_BOXES.END_MEETING:
      return DIALOG_MESSAGES.END_MEETING(date);
    case DIALOG_BOXES.MEETING_DISCONNECT_TEXT:
      return DIALOG_MESSAGES.MEETING_DISCONNECT_TEXT;
    case DIALOG_BOXES.NO_MEETINGS_FOUND:
      return DIALOG_MESSAGES.NO_MEETINGS_FOUND;
    default:
      return DIALOG_MESSAGES.DEFAULT;
  }
};

export const dialogActionClasses = (dialogBoxName: string, dialogActionClass: string, actionDialog: string) => {
  switch (dialogBoxName) {
    case DIALOG_BOXES.MEETING_LINK_INVALID:
    case DIALOG_BOXES.WAITING_SCREEN:
    case DIALOG_BOXES.BROWSER_SUPPORT_ALERT:
    case DIALOG_BOXES.SHOW_BUILDER_AI_REPRESENTATIVE:
    case DIALOG_BOXES.MEETING_CANCELLED:
    case DIALOG_BOXES.MEETING_ENDED:
    case DIALOG_BOXES.MEETING_DETAILS:
    case DIALOG_BOXES.END_MEETING:
    case DIALOG_BOXES.NO_MEETINGS_FOUND:
      return dialogActionClass;
    default:
      return actionDialog;
  }
};

export const isNeedToAddClass = (dialogBoxName: string) =>
  [
    DIALOG_BOXES.MEETING_DISCONNECT_TEXT,
    DIALOG_BOXES.REMOVED_BY_HOST,
    DIALOG_BOXES.WAITING_SCREEN,
    DIALOG_BOXES.BROWSER_SUPPORT_ALERT,
    DIALOG_BOXES.SHOW_BUILDER_AI_REPRESENTATIVE,
    DIALOG_BOXES.MEETING_CANCELLED,
    DIALOG_BOXES.MEETING_ENDED,
    DIALOG_BOXES.END_MEETING,
    DIALOG_BOXES.HOST_ASKED_TO_UNMUTE,
    DIALOG_BOXES.MEETING_LINK_INVALID,
    DIALOG_BOXES.MEETING_DETAILS,
    DIALOG_BOXES.NO_MEETINGS_FOUND,
  ].includes(dialogBoxName);

export const showCloseButton = (dialogBoxName: string, is360: boolean) => {
  const closeDialogBoxes = [
    DIALOG_BOXES.LEAVE_MEETING,
    DIALOG_BOXES.VIRTUAL_BACKGROUND_NOT_SUPPORTED,
    DIALOG_BOXES.DROP_EVERYONE,
    DIALOG_BOXES.MEETING_CANCELLATION,
    DIALOG_BOXES.MEETING_DETAILS,
    DIALOG_BOXES.MEETING_DISCONNECT_TEXT,
    DIALOG_BOXES.REMOVED_BY_HOST,
    DIALOG_BOXES.WAITING_SCREEN,
    DIALOG_BOXES.END_MEETING,
    DIALOG_BOXES.MEETING_LINK_INVALID,
    DIALOG_BOXES.MEETING_CANCELLED,
    DIALOG_BOXES.MEETING_ENDED,
  ];

  if (
    is360 &&
    (dialogBoxName === DIALOG_BOXES.MEETING_DISCONNECT_TEXT || dialogBoxName === DIALOG_BOXES.REMOVED_BY_HOST)
  ) {
    return false;
  }

  return closeDialogBoxes.includes(dialogBoxName);
};
