import React from 'react';

function SearchIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1169 15.6687C15.5292 14.2185 17.1198 10.2767 15.6697 6.8644C14.2195 3.4521 10.2776 1.8615 6.86534 3.3117C3.45304 4.76189 1.86244 8.70371 3.31263 12.116C4.76282 15.5283 8.70465 17.1189 12.1169 15.6687Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2378 14.2368L19.4445 19.4442"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchIcon;
