import { useLocalStorageState } from 'hooks/useLocalStorageState/useLocalStorageState';
import { useCallback } from 'react';
import { createNewFeatureFlag, fetchFeatureFlags } from 'service/MeetAPIServiceV1';
import { useAppState } from 'state';
import { FEATURE_FLAGS } from 'utils/constants';

/**
 * FeatureToggle type is a static type used when parsing the feature flags from the API,
 *  and contains all defined flags to facilitate accessing them from any compoenent.
 */
export type FeatureToggles = {
  [key: string]: boolean;
};

export type FeatureToggle = {
  name: string;
};

/**
 * useFeatureFlags is a custom hook where it contains a callback hook for loading the feature flags from the API,
 * validating them and formatting them as FeatureToggles type.
 */
export function useFeatureFlags(): any {
  const { featureFlags, setFeatureFlags } = useAppState();
  const [cachedFeatureFlags, setCachedFeatureFlags] = useLocalStorageState<FeatureToggles>(FEATURE_FLAGS, {});

  const featureFlagsCallback = useCallback(async () => {
    try {
      const res = await fetchFeatureFlags();

      if (res.data && res.data.features && res.data.features.length > 0) {
        const formattedFlags = parseFlagsCollection(res.data);
        setFeatureFlags(formattedFlags);
        setCachedFeatureFlags(formattedFlags);
      } else {
        setFeatureFlags(cachedFeatureFlags);
      }
    } catch (err) {
      console.log('fetch error for feature flags', err);
      setFeatureFlags(cachedFeatureFlags);
    }
  }, [setFeatureFlags, cachedFeatureFlags, setCachedFeatureFlags]);

  /**
   * isFeatureFlag takes a flagName, and if the flagName exists in the featureFlags object, 
   *  it will return its value, otherwise it will create it
   */
  const isFeatureFlagEnabled = useCallback(
    (flagName: string): boolean => {
      if (flagName in featureFlags) {
        return featureFlags[flagName];
      }

      createNewFeatureFlag({ name: flagName });
      return false;
    },
    [featureFlags]
  );

  return { featureFlagsCallback, isFeatureFlagEnabled };
}

/**
 * This function to parse the flags collection coming from the API response to match the FeatureToggles type
 */
const parseFlagsCollection = (flags: any) => {
  const formattedFlags: FeatureToggles = {};
  flags.features.map(
    (flag: { key: string; state: string }) => (formattedFlags[flag.key as keyof FeatureToggles] = flag.state === 'on')
  );
  return formattedFlags;
};

export default useFeatureFlags;
