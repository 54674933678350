import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Paper } from '@mui/material';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { sortByZone } from 'utils/constants';
import classes from './ScheduleMeetingAutoCompleteComponent.module.scss';

interface ScheduleMeetingAutoCompleteComponentProps {
  meeting: any;
  setMeeting: any;
}

function ScheduleMeetingAutoCompleteComponent({ meeting, setMeeting }: ScheduleMeetingAutoCompleteComponentProps) {
  const getTimeZoneList = momentTimeZone.tz
    .names()
    .map((t) => `(GMT${moment.tz(t).format('Z')}) ${t}`)
    .sort(sortByZone);

  const timeZoneArray = getTimeZoneList.map((t) => {
    const splitTimeZoneString = t.split(')');
    const timeZone = splitTimeZoneString[1];
    const offSet = splitTimeZoneString[0].split('T')[1];
    return `${timeZone} ${offSet}`; //Format Timezone e.g. Asia/Kolkata +5:30
  });

  return (
    <Autocomplete
      disablePortal
      id="timezone-combo-box"
      options={timeZoneArray}
      PaperComponent={({ children }) => <Paper className={classes.darkOptions}>{children}</Paper>}
      defaultValue={timeZoneArray.filter((x) => x.includes(meeting.selectedTimeZone))[0]}
      disableClearable
      onChange={(e, v) => {
        setMeeting((prevMeetingData: any) => ({
          ...prevMeetingData,
          selectedTimeZone: v.split(' ')[1], //We only need timezone... e.g Asia/Kolkata
        }));
      }}
      renderInput={(params) => (
        <TextField
          placeholder="Search TimeZone"
          autoCapitalize="false"
          variant="outlined"
          className={classes.autoComplete}
          {...params}
        />
      )}
    />
  );
}

export default ScheduleMeetingAutoCompleteComponent;
