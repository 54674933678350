import React, { useEffect } from 'react';
import classes from './RecordingMetaDataView.module.scss';
import {
  convertDateIntoTime,
  convertSecondsIntoHourAndMinuteForRecordingDuration,
  getMeetingDate,
} from 'utils/constants';
import { useRecordingStore } from 'store/recordingStore';

function RecordingMetaDataView() {
  const { recordingMetaData, setRecordingMetaData } = useRecordingStore((state) => ({
    recordingMetaData: state.recordingMetaData,
    setRecordingMetaData: state.setRecordingMetaData,
  }));

  useEffect(() => {
    // This function is the cleanup function that will run when the component is unmounted
    return () => {
      setRecordingMetaData(null);
    };
  }, [setRecordingMetaData]);

  if (!recordingMetaData) {
    return null;
  }

  const { name, time, duration, whiteboard_link, recording_link, isRecordingClick } = recordingMetaData;

  return (
    <div className={classes.main}>
      <div className={classes.meetingDetails}>
        <div className={classes.header}>
          <span>TIME</span>
          <span className={classes.value}>
            {getMeetingDate(time)} {convertDateIntoTime(time)}
          </span>
        </div>
        <div className={classes.header}>
          <span>NAME</span>
          <span className={classes.value}>{name}</span>
        </div>
        <div className={classes.header}>
          <span>DURATION</span>
          <span className={classes.value}>{convertSecondsIntoHourAndMinuteForRecordingDuration(duration)}</span>
        </div>
      </div>

      <div style={{ display: 'flex', height: '100%', marginBottom: '1em' }}>
        {isRecordingClick ? (
          <video className={classes.videoStyle} src={recording_link} controls />
        ) : (
          <iframe className={classes.iframeStyle} title="WhiteBoard" src={whiteboard_link} />
        )}
      </div>
    </div>
  );
}

export default RecordingMetaDataView;
