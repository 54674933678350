import React, { lazy, Suspense } from 'react';
import useRoomState from 'hooks/useRoomState/useRoomState';
import SideBar from 'components/SideBar/SideBar';
import useHeight from 'hooks/useHeight/useHeight';
import { useAppState } from 'state';
import useDarkMode from 'hooks/useDarkMode/useDarkMode';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';
import usePreferredBrowserWarning from 'hooks/usePreferredBrowserWarning/usePreferredBrowserWarning';
import useMeetingEnded from 'hooks/useMeetingEnded/useMeetingEnded';
import CustomAlertWrapper from 'shared/CustomAlert/CustomAlertWrapper';
import CustomDialogtWrapper from 'shared/CustomDialog/CustomDialogWrapper';
import BuilderGIFComponent from 'components/PreJoinComponents/BuilderGIFComponent/BuilderGIFComponent';
import classes from './VideoApp.module.scss';
import PermissionDialog from 'components/PermissionDialog/PermissionDialog';
import { useSearchParams } from 'react-router-dom';

const PreJoinScreensComponent = lazy(
  () => import('components/PreJoinComponents/PreJoinScreenComponent/PreJoinScreensComponent')
);
const Dashboard = lazy(() => import('components/Dashboard/Dashboard'));

export default function VideoApp() {
  const roomState = useRoomState();
  const height = useHeight();
  const { isFetchingUser, permissionStatus, setPermissionStatus } = useAppState();
  const { isMobile, isDesktop } = useDeviceType();
  const [searchParams] = useSearchParams();

  useDarkMode(); // To Set Light/Dark Mode
  useMeetingEnded(); // Will be called whrn user discoonects from the meeting or removed by Host
  usePreferredBrowserWarning(); // Used to show Preferred Browser Warning

  return (
    <>
      {permissionStatus && (
        <PermissionDialog open={Boolean(permissionStatus)} onClose={() => setPermissionStatus(null)} permissionStatus={permissionStatus}/>
      )}

      <CustomAlertWrapper />
      <CustomDialogtWrapper />
      <div
        className={`${classes.container} ${isMobile && roomState === 'disconnected' && classes.mobileContainer} ${
          isMobile && height
        }`}
      >
        { searchParams.get("source") !== 'enterprise' &&
        <SideBar roomState={roomState} mobileClasses={!isDesktop && classes.mobileSidebar}/> }
        <div
          className={`${classes.innerContainer} ${
            (roomState === 'disconnected' || (isMobile && roomState === 'connected')) && classes.joinScreen
          }`}
        >
          {isFetchingUser ? (
            <BuilderGIFComponent heigth={20} width={10} />
          ) : (
            <Suspense fallback={null}>
              {roomState === 'disconnected' ? <PreJoinScreensComponent /> : <Dashboard />}
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
}
