export enum meetingType {
  New = 'New',
  Join = 'Join',
  Schedule = 'Schedule',
  Recording = 'Recording',
}

export enum activeElement {
  Home = 'Home',
  Upcoming = 'Upcoming',
  Video = 'Video',
  Plus = 'Plus',
  Calender = 'Calender',
  Recording = 'Recording',
  UserProfile = 'UserProfile',
  Feedback = 'Feedback',
  MobileProfile = 'MobileProfile'
}

export enum meetingDays {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Upcoming = 'Upcoming',
}

export enum meetingSource {
  Standalone = 'standalone',
  Studio = 'studio',
  Calender = 'calendar',
}

export enum adminOptions {
  Mute = 'Mute',
  AskToUnmute = 'AskToUnmute',
  TurnOffVideo = 'TurnOffVideo',
  RemoveParticipant = 'RemoveParticipant',
  ChangeHost = 'ChangeHost',
}

export enum RoomViews {
  SpeakerView = 'SpeakerView',
  GalleryView = 'GalleryView',
  ScreenShareView = 'ScreenShareView',
  WhiteboardView = 'WhiteboardView',
}

export enum MeetingPermissionStatus {
  MicrophoneDenied = 'MicrophoneDenied',
  CameraDenied = 'CameraDenied',
  MicrophoneAndCameraDenied = 'MicrophoneAndCameraDenied',
  ScreenSharingDenied = 'ScreenSharingDenied',
}

export enum WhiteboardAccessStatus {
  ViewOnly = 'ViewOnly',
  EditOnly = 'EditOnly',
  FullAccess = 'FullAccess'
}
