import React from 'react';

function DelegateListIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.875 9.12305H25.375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.875 16.623H25.375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.875 24.123H25.375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.625 7.37305C6.625 6.95883 6.96079 6.62305 7.375 6.62305H9.625C10.0392 6.62305 10.375 6.95883 10.375 7.37305V9.62305C10.375 10.0373 10.0392 10.373 9.625 10.373H7.375C6.96079 10.373 6.625 10.0373 6.625 9.62305V7.37305Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.625 14.873C6.625 14.4588 6.96079 14.123 7.375 14.123H9.625C10.0392 14.123 10.375 14.4588 10.375 14.873V17.123C10.375 17.5373 10.0392 17.873 9.625 17.873H7.375C6.96079 17.873 6.625 17.5373 6.625 17.123V14.873Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.625 22.373C6.625 21.9588 6.96079 21.623 7.375 21.623H9.625C10.0392 21.623 10.375 21.9588 10.375 22.373V24.623C10.375 25.0373 10.0392 25.373 9.625 25.373H7.375C6.96079 25.373 6.625 25.0373 6.625 24.623V22.373Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DelegateListIcon;
