import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions } from '@mui/material';
import classes from './PermissionDialog.module.scss';
import CustomButton from 'shared/CustomButton/CustomButton';
import { MeetingPermissionStatus } from 'utils/enums';
import useDeviceType from 'hooks/useDeviceType/useDeviceType';

interface PermissionDialogProps {
  open: boolean;
  onClose(): void;
  permissionStatus: MeetingPermissionStatus;
}

const PermissionDialog: React.FC<PermissionDialogProps> = ({ open, onClose, permissionStatus }) => {
  const { isMobile } = useDeviceType();
  const [permissionTexts, setPermissionTexts] = useState({ title1: '', title2: '' });

  useEffect(() => {
    switch (permissionStatus) {
      case MeetingPermissionStatus.MicrophoneAndCameraDenied:
        setPermissionTexts({
          title1: 'Your microphone and camera are blocked',
          title2: 'This application requires access to your microphone and camera. Please enable access.',
        });
        break;
      case MeetingPermissionStatus.CameraDenied:
        setPermissionTexts({
          title1: 'Your camera is blocked',
          title2: 'This application requires access to your camera. Please enable access.',
        });
        break;
      case MeetingPermissionStatus.MicrophoneDenied:
        setPermissionTexts({
          title1: 'Your microphone is blocked',
          title2: 'This application requires access to your microphone. Please enable access.',
        });
        break;
      case MeetingPermissionStatus.ScreenSharingDenied:
        setPermissionTexts({
          title1: 'Your screensharing permission is blocked',
          title2: 'This application requires access to share screen. Please enable access.',
        });
        break;
    }
  }, [permissionStatus]);

  const handleClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{ className: `${classes.paper} ${isMobile && classes.mobilePaper}` }}
    >
      <DialogTitle></DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <div className={`${classes.title1} ${isMobile && classes.title1Mobile}`}>{permissionTexts.title1}</div>
        <div className={`${classes.title2} ${isMobile && classes.title2Mobile}`}>
          <b>
            <p>How to grant your audio/video permissions</p>
          </b>
          <ul>
            <li>
              <b>Check your system permissions</b>
              <ul>
                <b>
                  <li style={{ margin: '0.5em 0em' }}>If you are on a Windows desktop</li>
                </b>
                <ul>
                  <li>
                    Go to <b>Start</b> &gt; <b>Settings</b>
                  </li>
                  <li>
                    Select <b>Privacy & Security</b>. Under "Apps permissions" section, go to Camera and allow access.
                  </li>
                  <li>Under "Apps permissions" section, go to Microphone and allow access.</li>
                </ul>
                <b>
                  <li style={{ margin: '0.5em 0em' }}>If you are on a Mac desktop</li>
                </b>
                <ul>
                  <li>
                    Go to <b>System preferences</b> &gt; <b>Security</b> &amp; <b>Privacy</b> &gt; <b>Camera</b>.
                  </li>
                  <li>
                    You will see a list of allowed apps that can access your camera. Add your respective browser app
                    (Chrome/Safari) to this list.
                  </li>
                  <li>Join the meeting again by clicking on the meeting link or just refreshing the browser.</li>
                  <li>
                    Go to <b>System preferences</b> &gt; <b>Security</b> &amp; <b>Privacy</b> &gt; <b>Microphone</b>.
                  </li>
                  <li>
                    You will see a list of allowed apps that can access your camera. Add your respective browser app
                    (Chrome/Safari) to this list.
                  </li>
                  <li>Join the meeting again by clicking on the meeting link or just refreshing the browser.</li>
                </ul>
                <b>
                  <li style={{ margin: '0.5em 0em' }}>If you are on an Android phone</li>
                </b>
                <ul>
                  <li>
                    Go to <b>Settings</b> &gt; <b>Apps</b> &gt; <b>App Management</b> &gt; <b>Chrome</b> &gt;{' '}
                    <b>Permissions</b> &gt; <b>Camera</b> &gt; <b>allow permissions</b>
                  </li>
                  <li>
                    Go to <b>Settings</b> &gt; <b>Apps</b> &gt; <b>App Management</b> &gt; <b>Chrome</b> &gt;{' '}
                    <b>Permissions</b> &gt; <b>Microphone</b> &gt; <b>allow permissions</b>
                  </li>
                </ul>
                <b>
                  <li style={{ margin: '0.5em 0em' }}>If you are on an iPhone</li>
                </b>
                <ul>
                  <li>
                    Open <b>Settings</b> &gt; <b>Privacy</b> &amp; <b>Security</b> &gt; <b>Camera</b> &gt;{' '}
                    <b>allow the Chrome/Safari app to access camera</b>
                  </li>
                  <li>
                    Open <b>Settings</b> &gt; <b>Privacy</b> &amp; <b>Security</b> &gt; <b>Microphone</b> &gt;{' '}
                    <b>allow the Chrome/Safari app to access microphone</b>
                  </li>
                </ul>
              </ul>
            </li>
            <br />
            <li>
              <b> If your system permissions are granted, check your browser permissions</b>
              <ul>
                <b>
                  <li style={{ margin: '0.5em 0em' }}>If you are on Chrome browser</li>
                </b>
                <ul>
                  <li>
                    Select the 'lock' icon next to the URL at the top of Chrome tab and allow camera/microphone or
                    speaker permissions.
                  </li>
                </ul>
                <b>
                  <li style={{ margin: '0.5em 0em' }}>If you are on Safari browser</li>
                </b>
                <ul>
                  <li>
                    Just refresh your browser tab/meeting link again and you will see the “Join meeting” pop-up. Once
                    you click on <b>“Join as Guest”</b>, you’ll see a Safari pop-up asking for your camera and
                    microphone permissions. Allow these permissions and you’re good to go.
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
      </DialogContent>

      <DialogActions className={`${classes.actionDialog} ${isMobile && classes.actionDialogMobile}`}>
        <CustomButton buttonName="Cancel" onClick={onClose} btnClass={classes.button} />
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDialog;
