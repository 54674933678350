import React from 'react';
import classes from './SubmitFeedbackComponent.module.scss';
import { activeFieldEnum } from './SubmitFeedbackComponent';

const IssuesArray = [
  { key: 'JoinMeeting', label: `Couldn't join meeting` },
  { key: 'MicIssue', label: `Couldn't enable mic` },
  { key: 'CameraIssue', label: `Couldn't enable camera` },
  { key: 'DifficultUI', label: `Difficult UI` },
  { key: 'InternetIssue', label: `Internet Issue` },
  { key: 'ScreenSharingIssue', label: `Screen Sharing Issues` },
  { key: 'MissingRecordings', label: `Missing Recordings` },
  { key: 'CouldNotHearParticipants', label: `Couldn't hear participants` },
  { key: 'ParticipantsCouldNotHearMe', label: `Participants couldn't hear me` },
  { key: 'SpeakerIssues', label: `Speaker issues` },
  { key: 'NoIssues', label: `No issues` },
  { key: 'Others', label: `Other` },
];

interface IExperianceChipsProps {
  selectedIssues: string[];
  setSelectedIssues: React.Dispatch<React.SetStateAction<string[]>>;
  setActiveField: React.Dispatch<React.SetStateAction<activeFieldEnum>>;
  setLastSelectedChip: React.Dispatch<React.SetStateAction<string>>;
}

function ExperianceChips({
  selectedIssues,
  setSelectedIssues,
  setActiveField,
  setLastSelectedChip,
}: IExperianceChipsProps) {
  const handleAddIssue = (key: string) => {
    if (key === 'Others') {
      setActiveField(activeFieldEnum.Comments);
    }
    setLastSelectedChip(key);
    setSelectedIssues((prevIssues) => [...prevIssues, key]);
  };

  const handleRemoveIssue = (key: string) => {
    setLastSelectedChip(key);
    setSelectedIssues((prevIssues) => prevIssues.filter((issue) => issue !== key));
  };

  return (
    <div>
      {IssuesArray.map((issue) => {
        const isSelected = selectedIssues.includes(issue.key);
        return (
          <div key={issue.key} style={{ display: 'inline-block', margin: '4px' }}>
            {isSelected ? (
              <span className={`${classes.issueLabel} ${classes.activeIssue}`}>
                {issue.label}
                <span style={{ marginLeft: '1em', cursor: 'pointer' }} onClick={() => handleRemoveIssue(issue.key)}>
                  x
                </span>
              </span>
            ) : (
              <span
                className={classes.issueLabel}
                style={{ cursor: 'pointer' }}
                onClick={() => handleAddIssue(issue.key)}
              >
                {issue.label}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ExperianceChips;
