import React from 'react';

export default function BigMeetingIcon({ isHovered }: { isHovered: boolean }) {
  const color = isHovered ? '#00D659' : 'none';
  const strokeColor = isHovered ? 'white' : '#00D659';

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.499939" width="59" height="59" rx="29.5" fill={color} stroke={strokeColor} />
      <g clipPath="url(#clip0_3453_1945)">
        <path
          d="M33.6366 28.1811L40.0002 25.4538V34.5448L33.6366 31.8175"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M31.8184 22.7266H21.8184C20.8142 22.7266 20.0002 23.5406 20.0002 24.5447V35.4538C20.0002 36.458 20.8142 37.272 21.8184 37.272H31.8184C32.8225 37.272 33.6365 36.458 33.6365 35.4538V24.5447C33.6365 23.5406 32.8225 22.7266 31.8184 22.7266Z"
          stroke={strokeColor}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_3453_1945">
          <rect width="21.8182" height="21.8182" fill="white" transform="translate(19.0911 19.0902)" />
        </clipPath>
      </defs>
    </svg>
  );
}
