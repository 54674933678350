import { create } from 'zustand';

type WhiteboardAccess = {
  hasWhiteBoardAccess: boolean;
  setWhiteBoardAccess: (type: boolean) => void;
};

type WhiteboardDetails = {
  wb_unique_code: string;
  bmeet_id: string;
  link: string | null;
  setWhiteboardDetails: (id: string, link: string | null) => void;
};

type WhiteboardParticipants = {
  participant_list: string[];
  addParticipants: (participants: string[]) => void; // renamed and now accepts an array of strings
};

type WhiteboardGuestEmail = {
  guestEmail: string;
  setGuestEmail: (guestEmail: string) => void;
};

type ResetAction = {
  resetAll: () => void;
};

type WhiteboardStore = WhiteboardAccess & WhiteboardDetails & WhiteboardParticipants & WhiteboardGuestEmail & ResetAction;

const initialState = {
  hasWhiteBoardAccess: false,
  wb_unique_code: '',
  bmeet_id: '',
  link: null,
  participant_list: [],
  guestEmail: '',
};

export const useWhiteboardStore = create<WhiteboardStore>((set) => ({
  ...initialState,

  setWhiteBoardAccess: (type) => set({ hasWhiteBoardAccess: type }),
  setWhiteboardDetails: (id, link) => set({bmeet_id: id, link: link }),
  setGuestEmail: (email) => set({ guestEmail: email }),
  addParticipants: (participants) =>
    set((state) => ({
      participant_list: [...state.participant_list, ...participants], // Spread both arrays to merge
    })),
  resetAll: () => set({ ...initialState }),
}));
