import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ScheduleScreenDialogContent from './ScheduleMeetingDialogContent';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ScheduleMeetingRoute, convertStringIntoDate } from 'utils/constants';
import classes from './ScheduleMeetingDialogComponent.module.scss';
import { useAppState } from 'state';
import useMeetingData from 'hooks/useMeetingData/useMeetingData';
import { IScheduleMeeting } from 'types';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';

export default function ScheduleScreenDialog() {
  const { user, setAlert } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { fetchMeetingData } = useMeetingData();
  const [meetingData, setMeetingData] = useState<IScheduleMeeting | null>(null);
  const [isEditSeriesClick, setIsEditSeriesClick] = useState<Boolean | undefined>(undefined);
  const [isFetchingMeeting, setIsFetchingMeeting] = useState(false);
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    //We need to trigger API call when user is available it takes 2-3 seconds
    //Also we don't need to call this API if route is ScheduleMeetingRoute & state is passed
    async function fetchData() {
      if (user && location.pathname !== ScheduleMeetingRoute) {
        try {
          setIsFetchingMeeting(true);
          let meetingData;
          if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
            meetingData = await fetchMeetingData(false, id, user.email, searchParams.get('occurrence_id'));
          } else {
            const isRecurringMeeting = location.pathname.includes('recurring-meetings');
            meetingData = await fetchMeetingData(isRecurringMeeting, id, user.email);
          }

          //We'll get future_events in case of recurring meeting which we need to set Date
          if (meetingData && meetingData.frequency === 'recurring' && meetingData.future_events) {
            meetingData.future_events.forEach((meeting) => {
              meeting.start_date = convertStringIntoDate(meeting.start_time);
            });
          }

          setMeetingData(meetingData);
          if (location.state) {
            const passedData = location.state;
            if (passedData) {
              setIsEditSeriesClick(passedData.isEditSeriesClick);
            }
          }
        } catch (err) {
          console.log('error', err);
        } finally {
          setIsFetchingMeeting(false);
        }
      }
    }

    fetchData();
  }, [fetchMeetingData, id, location, navigate, user]);

  const handleClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason !== 'backdropClick') {
      navigate('/');
    }
  };

  const dialogTitleContent = (
    <div className={classes.dialogTitle}>
      <div></div>
      <div className={classes.text}>
        <span className={classes.title}>{meetingData ? 'Update Schedule Meeting' : 'Schedule Meeting'}</span>
      </div>
      <div data-testid="button" onClick={() => navigate('/')} className={classes.close}>
        x
      </div>
    </div>
  );

  if (!meetingData && user && location.pathname !== ScheduleMeetingRoute && !location.state) {
    return null;
  }

  return (
    <>
      {!isFetchingMeeting && (
        <Dialog
          style={{ zIndex: 0 }}
          disableEscapeKeyDown={true}
          open={true}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xs"
          PaperProps={{ className: classes.paper }}
          disableEnforceFocus
        >
          <DialogTitle>{dialogTitleContent}</DialogTitle>

          <ScheduleScreenDialogContent
            onClose={() => navigate(-1)}
            meetingDetails={meetingData}
            isEditSeriesClick={isEditSeriesClick}
          />
        </Dialog>
      )}
    </>
  );
}
