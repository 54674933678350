import { Callback } from 'types';
import EventEmitter from 'events';
import { isMobile } from 'utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { useCallback, useState } from 'react';
import { useAppState } from 'state';

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const { setRemoteMessage } = useAppState();

  const connect = useCallback(
    (token: string) => {
      return Video.connect(token, { ...options, tracks: localTracks }).then(
        (newRoom) => {
          setRoom(newRoom);

          const disconnect = () => {
            newRoom.disconnect();
          };

          const alert = (e: any) => {
            e.returnValue = 'Are You Sure?';
          };

          // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(16);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', alert);
            window.removeEventListener('unload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', alert);
              window.removeEventListener('pagehide', disconnect);
            }
          });

          newRoom.localParticipant.videoTracks.forEach((publication) =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          );

          // Subscribe to existing remote participants
          newRoom.participants.forEach((participant) => {
            participant.on('trackSubscribed', (track) => {
              if (track.kind === 'data') {
                track.on('message', (data: any) => {
                  setRemoteMessage(JSON.parse(data));
                });
              }
            });
          });

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', alert);
          window.addEventListener('unload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', alert);
            window.addEventListener('pagehide', disconnect);
          }
        },
        (error) => {
          onError(error);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localTracks, onError]
  );

  return { room, connect };
}
