import { useMemo } from 'react';

const useTruncateText = (text: string, maxLength: number) => {
  const [truncatedText, isTooltipNeeded] = useMemo(() => {
    const tooltipNeeded = text.length > maxLength;
    const finalText = tooltipNeeded ? `${text.substring(0, maxLength - 3)}...` : text;
    return [finalText, tooltipNeeded];
  }, [text, maxLength]);

  return [truncatedText, isTooltipNeeded];
};

export default useTruncateText;
