import { useCallback } from 'react';
import moment from 'moment-timezone';
import { convertDateIntoUTC } from 'utils/constants';

const useRecurringDateTime = () => {
  const setRecurringDateTimes = useCallback(
    (startDate: Date, endDate: Date, selectedTimeZone: string, frequency: string) => {
      const start = moment(startDate).parseZone().tz(selectedTimeZone, true).toDate();
      const end = moment(endDate).parseZone().tz(selectedTimeZone, true).toDate();
      const utcStartTime = convertDateIntoUTC(start);
      const utcEndTime = convertDateIntoUTC(end);

      const dateArrays = [];

      switch (frequency) {
        case 'Daily':
          for (let date = utcStartTime; date <= utcEndTime; date.setDate(date.getDate() + 1)) {
            dateArrays.push(moment(date).format('DD-MM-YYYY hh:mm A'));
          }
          break;
        case 'WorkDay':
          for (let date = utcStartTime; date <= utcEndTime; date.setDate(date.getDate() + 1)) {
            const day = date.getDay();
            const isWeekEnd = day === 6 || day === 0;
            if (!isWeekEnd) {
              dateArrays.push(moment(date).format('DD-MM-YYYY hh:mm A'));
            }
          }
          break;
        case 'Weekly':
          for (let date = utcStartTime; date <= utcEndTime; date.setDate(date.getDate() + 7)) {
            dateArrays.push(moment(date).format('DD-MM-YYYY hh:mm A'));
          }
          break;
        case 'Monthly':
          for (let date = utcStartTime; date <= utcEndTime; date.setMonth(date.getMonth() + 1)) {
            dateArrays.push(moment(date).format('DD-MM-YYYY hh:mm A'));
          }
          break;

        case 'Fortnightly':
          for (let date = utcStartTime; date <= utcEndTime; date.setDate(date.getDate() + 15)) {
            dateArrays.push(moment(date).format('DD-MM-YYYY hh:mm A'));
          }
          break;
        default:
          return [];
      }

      return dateArrays;
    },
    []
  );

  return { setRecurringDateTimes };
};

export default useRecurringDateTime;
