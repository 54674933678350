import React from 'react';
import { TextField } from '@mui/material';
import { useAppState } from 'state';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classes from './MobileRecordingSearchContainer.module.scss';
import { IRecordingsData } from 'types';
import SearchIcon from 'icons/SearchIcon';

interface MobileRecordingSearchContainerProps {
  getRecordings: (email: string, page: number, meetingName?: string | null, date?: string | null) => void;
  searchValue: string | null;
  setSearchValue: (searchValue: string | null) => void;
  searchDate: Date | null;
  setSearchDate: (searchDate: Date | null) => void;
  setCurrentPage: (currentPage: number) => void;
  setRecordingsData: (recordingsData: IRecordingsData[]) => void;
}

function MobileRecordingSearchContainer({
  getRecordings,
  searchValue,
  setSearchValue,
  searchDate,
  setSearchDate,
  setCurrentPage,
  setRecordingsData,
}: MobileRecordingSearchContainerProps) {
  const { user } = useAppState();

  const searchRecordings = () => {
    const date = searchDate && moment(searchDate).format('DD-MM-yyyy');
    setCurrentPage(1); //Reset Current Page
    setRecordingsData([]); // Reset Recordings Data
    if (user) {
      getRecordings(user?.email, 1, searchValue, date);
    }
  };

  return (
    <div className={classes.searchContainer}>
      <div style={{ width: '40%' }}>
        {/* <span className={classes.meetingTitle}>Search By Name: </span> */}
        <TextField
          InputProps={{ className: classes.inputField }}
          placeholder={`Search By Name`}
          autoCapitalize="false"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          inputProps={{ maxLength: 25, autoComplete: 'off' }}
        />
      </div>

      <div style={{ width: '40%' }}>
        {' '}
        {/* <span className={classes.meetingTime}>Search By Date: </span> */}
        <DatePicker
          className={`${classes.date}`}
          dateFormat="dd-MMM-yyyy"
          placeholderText="Search By Date"
          selected={searchDate}
          maxDate={new Date()}
          onChange={(date: Date) => {
            setSearchDate(date);
          }}
        />
      </div>
      <div>
        {' '}
        {/* <span style={{ visibility: 'collapse' }}>Label </span> */}
        <span
          onClick={() => {
            (searchDate || searchValue) && searchRecordings();
          }}
          className={`${classes.searchIcon} ${!(searchDate || searchValue) && classes.searchDisable}`}
        >
          <SearchIcon />
        </span>
      </div>
    </div>
  );
}

export default MobileRecordingSearchContainer;
