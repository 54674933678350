import React, { useCallback, useState } from 'react';
import { meetingType, activeElement } from 'utils/enums';
import { useAppState } from 'state';
import { useNavigate } from 'react-router-dom';
import useStartMeeting from 'hooks/useStartMeeting/useStartMeeting';
import UpcomingMeetingsComponent from 'components/PreJoinComponents/UpcomingMeetingsComponent/UpcomingMeetingsComponent';
import classes from './WelcomeScreenComponent.module.scss';
import BigMeetingIcon from 'icons/BigMeetingIcon';
import BigPlusIcon from 'icons/BigPlusIcon';
import BigCalendarIcon from 'icons/BigCalendarIcon';
import BigRecordingIcon from 'icons/BigRecordingIcon';
import { JoinMeetingRoute, RecordingRoute, ScheduleMeetingRoute } from 'utils/constants';

const types = Object.values(meetingType);

const getIcon = (type: meetingType, isHovered: boolean) => {
  switch (type) {
    case meetingType.New:
      return <BigMeetingIcon isHovered={isHovered} />;
    case meetingType.Join:
      return <BigPlusIcon isHovered={isHovered} />;
    case meetingType.Schedule:
      return <BigCalendarIcon isHovered={isHovered} />;
    case meetingType.Recording:
      return <BigRecordingIcon isHovered={isHovered} />;
    default:
      return null;
  }
};

const getMeetingName = (type: meetingType, isUserPresent: boolean) => {
  switch (type) {
    case meetingType.New:
      return 'New Meeting';
    case meetingType.Join:
      return isUserPresent ? 'Join Meeting' : 'Join as Guest';
    case meetingType.Schedule:
      return 'Schedule Meeting';
    case meetingType.Recording:
      return 'View Recordings';
    default:
      return '';
  }
};

export default function WelcomeScreenComponent() {
  const { setActiveTab, user } = useAppState();
  const startMeeting = useStartMeeting();
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState<meetingType | null>(null);

  const handleMouseEnter = useCallback((type: meetingType) => {
    setHoveredCard(type);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredCard(null);
  }, []);

  const buttonClick = useCallback(
    (type: meetingType) => {
      switch (type) {
        case meetingType.New:
          startMeeting();
          setActiveTab(activeElement.Video);
          break;
        case meetingType.Join:
          navigate(JoinMeetingRoute);
          setActiveTab(activeElement.Plus);
          break;
        case meetingType.Schedule:
          navigate(ScheduleMeetingRoute);
          setActiveTab(activeElement.Calender);
          break;
        case meetingType.Recording:
          navigate(RecordingRoute);
          setActiveTab(activeElement.Recording);
          break;
        default:
          break;
      }
    },
    [startMeeting, navigate, setActiveTab]
  );

  const visibleTypes = user ? types : [meetingType.Join];

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div className={classes.main}>
        <span className={classes.welcomeMessage}>{`Welcome, ${user ? user.name : 'Guest'}!`}</span>

        <div className={classes.cardMain}>
          {visibleTypes.map((type, index) =>
            index % 2 === 0 ? (
              <div className={classes.cardContainer} key={`container-${index}`}>
                <div
                  onMouseEnter={() => handleMouseEnter(type)}
                  onMouseLeave={handleMouseLeave}
                  className={classes.card}
                  key={type}
                >
                  <span onClick={() => buttonClick(type)} className={classes.icon}>
                    {getIcon(type, hoveredCard === type)}
                  </span>
                  <span>{getMeetingName(type, Boolean(user))}</span>
                </div>
                {visibleTypes[index + 1] && (
                  <div
                    onMouseEnter={() => handleMouseEnter(visibleTypes[index + 1])}
                    onMouseLeave={handleMouseLeave}
                    className={classes.card}
                    key={visibleTypes[index + 1]}
                  >
                    <span onClick={() => buttonClick(visibleTypes[index + 1])} className={classes.icon}>
                      {getIcon(visibleTypes[index + 1], hoveredCard === visibleTypes[index + 1])}
                    </span>
                    <span>{getMeetingName(visibleTypes[index + 1], Boolean(user))}</span>
                  </div>
                )}
              </div>
            ) : null
          )}
        </div>
      </div>
      <UpcomingMeetingsComponent />
    </div>
  );
}
