import React, { useEffect, useState } from 'react';
import { useAppState } from 'state';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { VideoProvider } from 'components/VideoProvider';
import { ChatProvider } from 'components/ChatProvider';
import UnsupportedBrowserWarning from 'components/PreJoinComponents/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import BuilderMeetRoutes from 'Routes/Routes';
import darkTheme from 'themes/darkTheme';
import lightTheme from 'themes/lightTheme';
import useConnectionOptions from 'hooks/useConnectionOptions/useConnectionOptions';
import './App.scss';
import useSetUserFromToken from 'hooks/useGoogle/useSetUserFromToken';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import { useFaro } from 'hooks/useFaro/useFaro';

export default function App() {
  const auth = useAuth();
  const { error, setError, isDarkTheme } = useAppState();
  const connectionOptions = useConnectionOptions();
  const setUserFromToken = useSetUserFromToken();
  const { featureFlagsCallback } = useFeatureFlags();
  const initFaroCallback = useFaro();

  useEffect(() => {
    initFaroCallback();
  });

  useEffect(() => {
    //This is used to set attribute for Dark Theme for scss
    document.documentElement.setAttribute('data-theme', isDarkTheme ? 'dark' : 'light');
  }, [isDarkTheme]);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      setUserFromToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated]);

  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  useEffect(() => {
    const signedInEvent = localStorage.getItem('signedInEvent');

    if (!hasAuthParams() &&
        !auth.isAuthenticated && !auth.isLoading &&
        !hasTriedSignin && signedInEvent === 'true'
    ) {
        auth.signinSilent();
        setHasTriedSignin(true);
    }
}, [auth, hasTriedSignin]);


  useEffect(() => {
    featureFlagsCallback();
  }, []);

  return (
    <div id={isDarkTheme ? 'darkTheme' : 'lightTheme'}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <CssBaseline />
        <UnsupportedBrowserWarning>
          <VideoProvider options={connectionOptions} onError={(err, type) => setError({ error: err, type: type })}>
            <ChatProvider>
              <ErrorDialog dismissError={() => setError(null)} error={error} />
              <BuilderMeetRoutes />
            </ChatProvider>
          </VideoProvider>
        </UnsupportedBrowserWarning>
      </ThemeProvider>
    </div>
  );
}
