import { TableCell, TableRow, Tooltip } from '@mui/material';
import React from 'react';
import { IRecordingsData } from 'types';
import { useAppState } from 'state';
import useTruncateText from 'hooks/useTruncateText/useTruncateText';
import {
  convertDateIntoTime,
  convertSecondsIntoHourAndMinuteForRecordingDuration,
  getMeetingDate,
} from 'utils/constants';
import classes from './RecordingDataTable.module.scss';
import RecordingAttachments from './RecordingAttachments';

interface RecordingDataTableProps {
  recordingData: IRecordingsData;
  index: number;
}

function RecordingDataTable({ recordingData, index }: RecordingDataTableProps) {
  const { user } = useAppState();
  const [recordingName, isNeedToAddTooltip] = useTruncateText(recordingData.name ?? '', 30);

  return (
    <TableRow>
      <TableCell className={`${classes.head}`} align="center">
        {index + 1}
      </TableCell>
      <TableCell className={`${classes.head}`} align="center">
        {getMeetingDate(recordingData.start_date)} {convertDateIntoTime(recordingData.start_date)}
      </TableCell>
      <TableCell className={`${classes.head}`} align="center">
        <Tooltip arrow title={isNeedToAddTooltip ? recordingData.name : ''} placement="top">
          <span>{recordingName || `${user?.name}'s meeting`}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={`${classes.head}`} align="center">
        {convertSecondsIntoHourAndMinuteForRecordingDuration(recordingData.duration)}
      </TableCell>
      <TableCell className={`${classes.head}`} align="center">
        {recordingData.size}
      </TableCell>
      <TableCell className={`${classes.head}`} align="center">
        <RecordingAttachments recordingData={recordingData} />       
      </TableCell>
    </TableRow>
  );
}

export default RecordingDataTable;
