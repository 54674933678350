import { trace, context } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import {
  ConsoleInstrumentation,
  ErrorsInstrumentation,
  LogLevel,
  SessionInstrumentation,
  WebVitalsInstrumentation,
  faro,
  getWebInstrumentations,
  initializeFaro,
} from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { useCallback } from 'react';

const VERSION = '1.0.0';
const NAME = process.env.REACT_APP_FARO_APP_NAME;
const COLLECTOR_URL = process.env.REACT_APP_FARO_URL;

export function useFaro(): any {
  const initFaro = () => {
    const instrumentationOptions = {
      propagateTraceHeaderCorsUrls: [process.env.REACT_APP_OTEL_BACKEND_APP || ''],
    };

    initializeFaro({
      instrumentations: [
        new ErrorsInstrumentation(),
        new WebVitalsInstrumentation(),
        new ConsoleInstrumentation({
          disabledLevels: [LogLevel.TRACE, LogLevel.ERROR],
        }),
        new SessionInstrumentation(),
        ...getWebInstrumentations(),
        new TracingInstrumentation({ instrumentationOptions }),
      ],
      url: process.env.REACT_APP_FARO_URL,
      app: {
        name: process.env.REACT_APP_FARO_APP_NAME,
        version: '1.0.0',
        environment: 'production',
      },
      isolate: true,
    });

    const resource = Resource.default().merge(
      new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: NAME,
        [SemanticResourceAttributes.SERVICE_VERSION]: VERSION,
      })
    );

    const provider = new WebTracerProvider({ resource });

    provider.register({
      propagator: new W3CTraceContextPropagator(),
      contextManager: new ZoneContextManager(),
    });

    const ignoreUrls = [COLLECTOR_URL || ''];

    registerInstrumentations({
      instrumentations: [
        new DocumentLoadInstrumentation(),
        new FetchInstrumentation({ ignoreUrls }),
        new XMLHttpRequestInstrumentation({ ignoreUrls }),
      ],
    });

    faro.api.initOTEL(trace, context);
  };

  const initFaroCallback = useCallback(() => {
    initFaro();
  }, []);

  return initFaroCallback;
}
