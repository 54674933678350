import React from 'react';

function RightArrowIcon() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 1.5L6.39956 6.74089C6.46387 6.80958 6.5 6.90279 6.5 7C6.5 7.09721 6.46387 7.19042 6.39956 7.25911L1.5 12.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RightArrowIcon;
