import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppState } from 'state';
import { validateEmail } from 'utils';
import { EMAIL, ENTERPRISE_PARAMS, SOURCE, USERNAME } from 'utils/constants';

export default function useEnterpriseIntegration() {
  const { meetingDetails, setUserName, setGuestEmail } = useAppState();

  const [searchParams] = useSearchParams();
  const enterprise_sourse = searchParams.get(ENTERPRISE_PARAMS);
  const sourse = searchParams.get(SOURCE);
  const isSourceEnterpriseSurface = enterprise_sourse === 'true';
  const [isEnterpriseUser, setIsEnterpriseUser] = useState(false);

  useEffect(() => {
    if (searchParams.get(SOURCE) === 'enterprise') {
      const username = searchParams.get(USERNAME);
      const email = searchParams.get(EMAIL);
      if (username && email && validateEmail(email)) {
        setUserName(username);
        setGuestEmail(email);
        setIsEnterpriseUser(true);
      }
    }
  }, [isEnterpriseUser]);

  const enterpriseDisconect = () => {
    console.log('isSourceEnterprise', isSourceEnterpriseSurface);
    if (isSourceEnterpriseSurface) {
      if (window.parent !== null) {
        console.log(
          {
            type: 'meeting-closed',
            schedule_meeting_id: meetingDetails?.bMeet_id,
          },
          '*'
        );
        const trustedDomains = [
          "https://pmdashboard.engineer.ai",
          "https://alpha-enterprise.builder.ai",
          "https://enterprise.builder.ai",
          "https://api-enterprise.builder.ai",
          "https://staging-enterprise.builder.ai"
        ];
        trustedDomains.forEach(domain =>
          window.parent.postMessage(
            {
              type: 'meeting-closed',
              schedule_meeting_id: meetingDetails.bMeet_id,
            },
            domain
          )
        );
      }
    }

    if (sourse === 'enterprise' && window.parent !== null) {
      window.parent.postMessage(
        {
          type: 'meeting-closed',
          schedule_meeting_id: meetingDetails.bMeet_id,
        },
        `${process.env.REACT_APP_ENTERPRISE_DOMAIN!}`
      );
    }
  };


  return { isEnterpriseUser, enterpriseDisconect };
}
