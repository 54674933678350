import React from 'react';

function OpenReordingIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3C1.5 2.17157 2.17157 1.5 3 1.5H15C15.8284 1.5 16.5 2.17157 16.5 3V15C16.5 15.8284 15.8284 16.5 15 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V3Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82533 11.44C7.64844 11.5282 7.43849 11.5186 7.2704 11.4146C7.10231 11.3106 7.00001 11.127 7 10.9293V7.07064C7.00001 6.87298 7.10231 6.68939 7.2704 6.58538C7.43849 6.48138 7.64844 6.47176 7.82533 6.55998L11.6847 8.48998C11.878 8.58646 12.0001 8.78393 12.0001 8.99998C12.0001 9.21602 11.878 9.41349 11.6847 9.50998L7.82533 11.44Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OpenReordingIcon;
