import React from 'react';

export default function UserIcon() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="17" fill="#7C4DFF" />
      <g clipPath="url(#clip0)">
        <path
          d="M11.5 22.5C11.5 19.7385 13.7385 17.5 16.5 17.5H17.5C20.2615 17.5 22.5 19.7385 22.5 22.5"
          stroke="white"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M17 17.5C18.6569 17.5 20 16.1569 20 14.5C20 12.8431 18.6569 11.5 17 11.5C15.3431 11.5 14 12.8431 14 14.5C14 16.1569 15.3431 17.5 17 17.5Z"
          stroke="white"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  );
}
