import React from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

export default function CalendarIcon() {
  const { isDarkTheme, activeTab } = useAppState();
  const isActive = activeTab === activeElement.Calender;
  const stroke = isActive ? '#F8F9FA' : '#AFB2C0';
  return isDarkTheme ? (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_460)">
        <path
          d="M7 10.5001V7.00006"
          stroke={stroke}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M8.75 8.75006H5.25"
          stroke={stroke}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12.2499 1.75003H1.74992C1.10559 1.75003 0.583252 2.27236 0.583252 2.9167V11.6667C0.583252 12.311 1.10559 12.8334 1.74992 12.8334H12.2499C12.8943 12.8334 13.4166 12.311 13.4166 11.6667V2.9167C13.4166 2.27236 12.8943 1.75003 12.2499 1.75003Z"
          stroke={stroke}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M3.5 0.583374V2.33337"
          stroke={stroke}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M10.5 0.583374V2.33337"
          stroke={stroke}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M0.583252 4.66669H13.4166"
          stroke={stroke}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_460">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_88)">
        <path
          d="M7 10.5001V7.00006"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M8.75 8.75006H5.25"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12.25 1.75003H1.75004C1.10571 1.75003 0.583374 2.27236 0.583374 2.9167V11.6667C0.583374 12.311 1.10571 12.8334 1.75004 12.8334H12.25C12.8944 12.8334 13.4167 12.311 13.4167 11.6667V2.9167C13.4167 2.27236 12.8944 1.75003 12.25 1.75003Z"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M3.5 0.583374V2.33337"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M10.5 0.583374V2.33337"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M0.583374 4.66669H13.4167"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_88">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
