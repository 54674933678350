import React, { PropsWithChildren, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomButton from 'shared/CustomButton/CustomButton';
import { InputLabel, TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import { useAppState } from 'state';
import moment from 'moment';
import { IScheduleMeeting } from 'types';
import { convertDateIntoUTC, frequencyArray, durationArray } from 'utils/constants';
import 'themes/lightBg.scss';
import 'themes/darkBg.scss';
import CkEditorComponent from 'shared/CKEditorComponent/CkEditorComponent';
import useScheduleMeetingAPI from 'hooks/useScheduleMeetingAPI/useScheduleMeetingAPI';
import useRecurringDateTime from 'hooks/useRecurringDateTime/useRecurringDateTime';
import useTimeValidation from 'hooks/useTimeValidation/useTimeValidation';
import MultiEmailComponent from 'shared/MultiEmailComponent/MultiEmailComponent';
import ScheduleMeetingStartTImeComponent from './ScheduleMeetingStartTImeComponent';
import ScheduleMeetingDialogDurationComponent from './ScheduleMeetingDialogDurationComponent';
import ScheduleMeetingRadioGroup from './ScheduleMeetingRadioGroup';
import ScheduleMeetingFrequencyComponent from './ScheduleMeetingFrequencyComponent';
import ScheduleMeetingAutoCompleteComponent from './ScheduleMeetingAutoCompleteComponent';
import classes from './ScheduleMeetingDialogContent.module.scss';
import ScheduleMeetingHostDelegateDropDown from './ScheduleMeetingHostDelegateDropDown';
import useGoogle from 'hooks/useGoogle/useGoogle';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';

interface IDelegatorDetail {
  name: string;
  email: string;
  id: number;
}

export interface IFutureEvent {
  id: number;
  occurance_datetime: string;
}

interface ScheduleScreenDialogContentProps {
  onClose(): void;
  meetingDetails: IScheduleMeeting | null;
  isEditSeriesClick?: Boolean;
}

export interface IMeetingType {
  subject: string;
  description: string;
  meetingType: string;
  emails: string[];
  startDate: Date;
  endDate: Date | null;
  frequency: string;
  startTime: string;
  selectedTimeZone: string;
  isAMSelected: boolean;
  duration: number;
  delegator_detail: IDelegatorDetail;
}

export default function ScheduleScreenDialogContent({
  onClose,
  meetingDetails,
  isEditSeriesClick,
}: PropsWithChildren<ScheduleScreenDialogContentProps>) {
  const [invalidEmailExist, setInvalidEmailExist] = useState(false);
  const { createScheduleMeeting, updateMeeting, btnDisabled } = useScheduleMeetingAPI();
  const { setRecurringDateTimes } = useRecurringDateTime();
  const timeValidation = useTimeValidation();
  const { isFeatureFlagEnabled } = useFeatureFlags();

  const { user, isDarkTheme, setAlert } = useAppState();
  const {signIn, tokenVerified} = useGoogle();

  const [meeting, setMeeting] = useState<IMeetingType>({
    subject: meetingDetails ? meetingDetails.name : '',
    description: meetingDetails ? meetingDetails.description : '',
    meetingType: meetingDetails ? meetingDetails.frequency : 'single',
    emails: meetingDetails ? meetingDetails.participants_list.filter((p) => p) : [],
    startDate: meetingDetails ? meetingDetails.start_date : new Date(),
    endDate: null,
    frequency: frequencyArray[0],
    startTime: meetingDetails ? moment(meetingDetails.start_date).format('hh:mm') : moment(new Date()).format('hh:mm'),
    selectedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isAMSelected: meetingDetails
      ? moment(meetingDetails.start_date).format('A') === 'AM'
      : moment(new Date()).format('A') === 'AM',
    duration: meetingDetails
      ? durationArray.filter((x) => x.duration === meetingDetails.duration)[0]?.duration
      : durationArray[0].duration,
    delegator_detail: {
      name: meetingDetails ? (meetingDetails.user.email === user?.email ? 'You' : meetingDetails.user.name) : 'You',
      email: meetingDetails ? (meetingDetails.user.email === user?.email ? '' : meetingDetails.user.email) : '',
      id: meetingDetails ? (meetingDetails.user.email === user?.email ? -1 : meetingDetails.user.id) : -1,
    },
  });

  const onSchedule = () => {
    const isStartTimeValid = timeValidation(meeting.startTime);
    if (!isStartTimeValid) {
      return;
    }

    setTime();

    const start = moment(meeting.startDate).parseZone().tz(meeting.selectedTimeZone, true).toDate();
    const utcTime = convertDateIntoUTC(start);

    if (start < new Date()) {
      setAlert({
        variant: 'error',
        message: 'Start time should be greater then current time',
      });
      return;
    }

    if (!meeting.subject) {
      setAlert({
        variant: 'error',
        message: 'Please enter subject of the meeting!',
      });
      return;
    }

    if (meetingDetails) {
      editScheduleMeeting(utcTime);
      return;
    }

    if (meeting.meetingType.toLocaleLowerCase() === 'recurring' && !meeting.endDate) {
      setAlert({
        variant: 'error',
        message: 'Please enter end date of the meeting!',
      });
      return;
    }

    let data = {
      email: meeting.delegator_detail.id === -1 ? user?.email : meeting.delegator_detail.email,
      name: meeting.subject,
      description: meeting.description,
      duration: meeting.duration,
      requested_participants: meeting.emails,
      type: meeting.meetingType.toLowerCase(),
      source: 'standalone',
      ...(meeting.delegator_detail.id !== -1 && {
        managed_by: meeting.delegator_detail.email === user?.email ? null : user?.id,
      })
    };

    if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
      Object.assign(data, {
        start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
        start_time_tz: meeting.selectedTimeZone,
        recurrence_end_time: meeting.endDate,
        end_time_tz: meeting.selectedTimeZone,
        recurrence: meeting.meetingType === 'recurring' ? meeting.frequency : null,
      })
    } else {
      const recurringDateTimes =
        meeting.meetingType === 'recurring'
          ? setRecurringDateTimes(
              meeting.startDate,
              meeting.endDate ?? new Date(),
              meeting.selectedTimeZone,
              meeting.frequency
            )
          : [];

      Object.assign(data, {
        scheduled_start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
        recurring_datetime: recurringDateTimes,
      });
    }

    createScheduleMeeting(data);
  };

  const editScheduleMeeting = (utcTime: Date) => {
    let futureEvents: IFutureEvent[] = [];

    if (isEditSeriesClick && meetingDetails && !isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
      futureEvents = setDateAndTimeForFutureInstance();
      futureEvents.push({ id: meetingDetails.id, occurance_datetime: moment(utcTime).format('DD-MM-YYYY hh:mm A') });
    }

    let data;
    if (isFeatureFlagEnabled("full_calendar_integration_85ztm5z7h")) {
      // The occurrence ID for this meeting is its timestamp
      const occurrenceId = meetingDetails?.occurrence_id;
      data =
        meeting.meetingType === 'recurring'
          ? {
            meeting_uuid: meetingDetails?.meeting_uuid,
            ...(meetingDetails?.managed_by !== null && {
              managed_by: meetingDetails?.managed_by,
            }),
            name: meeting.subject,
            description: meeting.description,
            start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
            duration: meeting.duration,
            series_id: meetingDetails?.series_id,
            participants_list: meeting.emails,
            ...(isEditSeriesClick
              // Editing all future events
              ? {
                occurrence_from: occurrenceId
              }
              // Editing a single occurrence, so add in the ID
              : {
                occurrence_id: occurrenceId
              }
            )
          }
          : {
              meeting_uuid: meetingDetails?.meeting_uuid,
              name: meeting.subject,
              description: meeting.description,
              start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
              duration: meeting.duration,
              status: 'scheduled',
              participants_list: meeting.emails,
              ...(meetingDetails?.managed_by !== null && {
                managed_by: meetingDetails?.managed_by,
              }),
            }
    } else {
      data =
        meeting.meetingType === 'recurring'
          ? {
              meeting_uuid: meetingDetails?.meeting_uuid,
              ...(meetingDetails?.managed_by !== null && {
                managed_by: meetingDetails?.managed_by,
              }),
              recurring_meetings_attributes: !isEditSeriesClick
                ? [
                    {
                      id: meetingDetails?.id,
                      name: meeting.subject,
                      description: meeting.description,
                      occurance_datetime: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
                      status: 'scheduled',
                      participants_list: meeting.emails,
                      duration: meeting.duration,
                      publish_to_google: true,
                      ...(meetingDetails?.managed_by !== null && {
                        managed_by: meetingDetails?.managed_by,
                      }),
                    },
                  ]
                : futureEvents.map((event) => ({
                    id: event.id,
                    name: meeting.subject,
                    description: meeting.description,
                    occurance_datetime: event.occurance_datetime,
                    status: 'scheduled',
                    participants_list: meeting.emails,
                    duration: meeting.duration,
                    publish_to_google: true,
                    ...(meetingDetails?.managed_by !== null && {
                      managed_by: meetingDetails?.managed_by,
                    }),
                  })),
            }
          : {
              meeting_uuid: meetingDetails?.meeting_uuid,
              name: meeting.subject,
              description: meeting.description,
              start_time: moment(utcTime).format('DD-MM-YYYY hh:mm A'),
              duration: meeting.duration,
              status: 'scheduled',
              participants_list: meeting.emails,
              ...(meetingDetails?.managed_by !== null && {
                managed_by: meetingDetails?.managed_by,
              }),
            };
    }

    updateMeeting(data);
  };

  // It is used to set Hours & Minute in Start Date
  const setTime = () => {
    const timeMoment = moment(meeting.startTime, 'hh:mm A').tz(moment.tz.guess());
    const hour = timeMoment.hour();
    const minute = timeMoment.minute();
    const hours = meeting.isAMSelected ? (hour === 12 ? 0 : hour) : hour !== 12 ? hour + 12 : hour;

    meeting.startDate.setHours(hours, minute, 0);
  };

  const setDateAndTimeForFutureInstance = () => {
    const futureEvents: IFutureEvent[] = [];

    if (meetingDetails?.future_events) {
      meetingDetails?.future_events.forEach((x) => {
        const timeMoment = moment(meeting.startTime, 'hh:mm A').tz(moment.tz.guess());
        const hour = timeMoment.hour();
        const minute = timeMoment.minute();
        const hours = meeting.isAMSelected ? (hour === 12 ? 0 : hour) : hour !== 12 ? hour + 12 : hour;
        if (x.start_date) {
          x.start_date?.setHours(hours, minute, 0);
          const start = moment(x.start_date).parseZone().tz(meeting.selectedTimeZone, true).toDate();
          const utcTime = convertDateIntoUTC(start);
          const momentTIme = moment(utcTime).format('DD-MM-YYYY hh:mm A');
          const array = { id: x.id, occurance_datetime: momentTIme };
          futureEvents.push(array);
        }
      });
    }
    return futureEvents;
  };
  
  return (
    <div id={isDarkTheme ? 'darkTheme' : 'lightTheme'}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.head}>
         
          <div>
            <TextField
              InputProps={{ className: classes.inputField , id : "schedule-meet" }}
              disabled={false}
              placeholder="Purpose"
              variant="outlined"
              fullWidth
              size="small"
              value={meeting.subject}
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                if (!meeting.subject) {
                  const value = event.target.value.trim();
                  setMeeting((prevMeetingData: any) => ({
                    ...prevMeetingData,
                    subject: value,
                  }));
                } else {
                  setMeeting((prevMeetingData: any) => ({
                    ...prevMeetingData,
                    subject: event.target.value,
                  }));
                }
              }}
              className={classes.textField}
            />
          </div>

          <div className={classes.fieldMargin} style={{ display: 'flex' }}>
            <div className={classes.initialField}>
              <InputLabel className={classes.label} id="schedule-meet">Start Date *</InputLabel>
              <DatePicker
                disabled={isEditSeriesClick === true}
                dateFormat="dd-MMM-yyyy"
                selected={meeting.startDate}
                onChange={(date: Date) => {
                  setMeeting((prevMeetingData) => ({
                    ...prevMeetingData,
                    startDate: date,
                    endDate: meeting.endDate && meeting.endDate < date ? null : meeting.endDate,
                  }));
                }}
                minDate={new Date()}
                maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                className={`${classes.date} ${classes.textField} ${classes.startDate}`}
              />
            </div>

            <div className={classes.initialField}>
              <InputLabel className={classes.label} id="schedule-meet">Start Time *</InputLabel>
              <ScheduleMeetingStartTImeComponent meeting={meeting} setMeeting={setMeeting} />
            </div>

            <div className={classes.initialField} style={{ marginLeft: '0.5em' }}>
              <InputLabel className={classes.label} id="schedule-meet">Duration (in minutes)</InputLabel>
              <ScheduleMeetingDialogDurationComponent meeting={meeting} setMeeting={setMeeting} />
            </div>
          </div>

          <div className={classes.fieldMargin}>
            <InputLabel className={classes.label} id="schedule-meet">Timezone *</InputLabel>
            <ScheduleMeetingAutoCompleteComponent meeting={meeting} setMeeting={setMeeting} />
          </div>

          <div className={classes.fieldMargin}>
            <MultiEmailComponent
              meeting={meeting}
              setMeeting={setMeeting}
              setInvalidEmailExist={setInvalidEmailExist}
            />
          </div>

          {!meetingDetails && (
            <div className={`${classes.fieldMargin} ${classes.frequencyField}`}>
              <div style={{ display: 'flex', width: '40%', flexDirection: 'column' }}>
                <div className={classes.frequency}>
                  <ScheduleMeetingRadioGroup
                    meeting={meeting}
                    setMeeting={setMeeting}
                    isFieldDisabled={Boolean(meetingDetails)}
                  />
                </div>
              </div>

              {meeting.meetingType === 'recurring' && (
                <div className={classes.recurringCOntainer}>
                  <div className={classes.frequencyStyle}>
                    <InputLabel style={{ visibility: 'collapse' }} className={classes.label}>
                      Hidden Label
                    </InputLabel>
                    <ScheduleMeetingFrequencyComponent
                      isFieldDisabled={Boolean(meetingDetails)}
                      meeting={meeting}
                      setMeeting={setMeeting}
                    />
                  </div>

                  <div>
                    <InputLabel className={classes.label} id="schedule-meet" style={{ marginBottom: '0.69em' }}>
                      End Date *
                    </InputLabel>
                    <DatePicker
                      placeholderText="Select Date"
                      disabled={Boolean(meetingDetails)}
                      dateFormat="dd-MMM-yyyy"
                      selected={meeting.endDate}
                      onChange={(date: Date) => {
                        setMeeting((prevMeetingData: any) => ({
                          ...prevMeetingData,
                          endDate: date ? new Date(date.setHours(23, 59, 59)) : null,
                        }));
                      }}
                      minDate={meeting.startDate}
                      className={`${classes.date} ${classes.endDate}`}
                      id="schedule-meet"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className={classes.fieldMargin}>
            <InputLabel className={classes.label} style={{ marginBottom: '0.5em' }} id="schedule-meet">
              Description (optional)
            </InputLabel>
            <CkEditorComponent meeting={meeting} setMeeting={setMeeting} />
          </div>

          <div className={classes.fieldMargin}>
            <InputLabel className={classes.label} id="schedule-meet">Creating Meeting For *</InputLabel>
            <ScheduleMeetingHostDelegateDropDown
              meeting={meeting}
              setMeeting={setMeeting}
              meetingDetails={meetingDetails}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions className={classes.actionDialog}>
        <div>
          {!tokenVerified && (
            <CustomButton buttonName="Authorize Calendar Access" onClick={signIn} btnClass={classes.cancelButton} />
          )}
        </div>
        <div>
          {/* <CustomButton buttonName="Cancel" onClick={onClose} btnClass={classes.cancelButton} /> */}
          <CustomButton
            buttonName={meetingDetails ? 'Update Meeting' : 'Schedule'}
            onClick={onSchedule}
            btnClass={classes.joinButton}
            isDisabled={btnDisabled || invalidEmailExist || !tokenVerified}
          />
        </div>
      </DialogActions>
    </div>
  );
}
