import React from 'react';
import { useAppState } from 'state';
import { activeElement } from 'utils/enums';

export default function HomeIcon() {
  const { isDarkTheme, activeTab } = useAppState();
  const isActive = activeTab === activeElement.Home;

  return isDarkTheme ? (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_440)">
        <path
          d="M7.00008 1.16666L1.16675 5.83332V13.4167H5.25008V9.33332H8.75008V13.4167H12.8334V5.83332L7.00008 1.16666Z"
          stroke={isActive ? '#F8F9FA' : '#AFB2C0'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_440">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1926_64)">
        <path
          d="M6.99996 1.16666L1.16663 5.83332V13.4167H5.24996V9.33332H8.74996V13.4167H12.8333V5.83332L6.99996 1.16666Z"
          stroke={isActive ? '#651FFF' : '#83889E'}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1926_64">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
