import { useEffect } from 'react';
import { useAppState } from 'state';

export default function useDarkMode() {
  const { setIsDarkTheme } = useAppState();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.matchMedia) {
      // Add listener to update styles
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => setIsDarkTheme(true)); //e.matches

      // Remove listener
      return () => {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
